import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import PrimeVue from "primevue/config";
/* ADD STORE IN PROJECT USING VUEX */
import store from "@/store/store";

/* JQUERY SETUP IN VUE APP */
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery;

/* BOOSTRAP AND CSS UTILS */
import 'popper.js'
//import 'bootstrap'
import './assets/css/app.scss'
import './assets/css/style.css'
import './assets/scss/index.scss';
/* MULTILANGUAGE APP CONFIG */
import { createI18n } from 'vue-i18n'
import messages from './lang'
export const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages
})

/* CALENDAR COMPOENETS */
// import Calendar from '@pigsking/vue-mark-calendar'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#0abbc4',
    cancelButtonColor: '#272f43',
};

import VueSocialSharing from 'vue-social-sharing'

import VSwatches from 'vue3-swatches'
import 'vue3-swatches/dist/style.css'

createApp(App).use(router).use(i18n).use(VueSocialSharing).use(VueSweetalert2, options).use(VSwatches).use(store).use(PrimeVue).mount('#app')

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}
