<template>
  <div class="cfa-root">
    <c-f-a-nav  :alert="alertString">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
            <h1 class="cfa-section-title">{{ $t('title_phishing_audit') }}</h1>
        </div>
      </template>
      <template v-slot:content>
        <div v-if="!waitingAPIResponse" class="cfa-page">
            <div v-if="subscriptionData?.is_activated == true && subscriptionData != null">
                <div class="row d-flex flex-wrap">
                    <div class="col-12 col-md-6">
                        <div class="bg-white d-flex py-3 gBlock">
                            <div class="p-3 gFolder-container">
                                <img width="30" src="@/assets/img/png/phishing.png" alt="dashboard state image"/>
                            </div>
                            <div>
                                <!-- <p>{{ subscriptionData?.remain_credit }}/{{ total_credit_mail }}</p> -->
                                <p>{{ subscriptionData?.remain_credit_phishing || 0 }}</p>
                                <p>{{ $t('phishing_credit_title') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="bg-white gBlock d-flex py-3">
                            <div class="p-3 gFolder-container">
                                <img width="30" src="@/assets/img/png/sms.png" alt="dashboard state image"/>
                            </div>
                            <div>
                                <!-- <p>{{ credit_sms }}/{{ total_credit_sms }}</p> -->
                                <p>{{ subscriptionData?.remain_credit_smshing || 0 }}</p>
                                <p>{{ $t('smishing_credit_title') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab">
                    <div class="tab-header" style="margin-bottom: 1em;">
                        <ul class="tab-ul">
                            <li :class="{ 'active': currentTab === 'campaign_activate' }" @click="selectTab('campaign_activate')" class="d-md-flex d-none badge rounded-pill cfa-tab-btn tab_font"> {{ $t('active_campaign') }}</li>
                            <li :class="{ 'active': currentTab === 'campaign_activate' }" @click="selectTab('campaign_activate')" class="d-md-none d-flex badge rounded-pill cfa-tab-btn tab_font"> {{ $t('campaign_alt') }}</li>
                            <li :class="{ 'active': currentTab === 'campaign_archived' }" @click="selectTab('campaign_archived')" class="d-md-flex d-none badge rounded-pill cfa-tab-btn tab_font"> {{ $t('campaign_archive') }}</li>
                            <!-- <li :class="{ 'active': currentTab === 'groups' }" @click="selectTab('groups')" class="d-md-flex d-none badge rounded-pill cfa-tab-btn tab_font"> {{ $t('groups_cables') }}</li> -->
                            <li :class="{ 'active': currentTab === 'reports' }" @click="selectTab('reports')" class="d-md-flex d-none badge rounded-pill cfa-tab-btn tab_font"> {{ $t('reports') }}</li>
                            <li :class="{ 'active': currentTab === 'groups' }" @click="selectTab('groups')" class="d-md-none d-flex badge rounded-pill cfa-tab-btn tab_font"> {{ $t('groupes') }}</li>
                        </ul>
                        <div class="d-flex align-items-center justify-content-end p-1 etawrat">
                        <div v-if="subscriptionData?.remain_credit_phishing > 0">
                            <button v-if="currentTab == 'campaign_activate' || currentTab == 'campaign_archived' || currentTab == 'reports'" @click="newCampaign" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.5em;">
                                <span class="d-md-flex d-none">{{ $t('new') }}&nbsp;</span>
                                <span>{{ $t('campaign') }}</span>&nbsp;<i class="fa fa-plus white-color">&nbsp;</i>
                            </button>
                        </div>
                        <div v-if="subscriptionData?.remain_credit_phishing <= 0">
                            <button disabled v-if="currentTab == 'campaign_activate' || currentTab == 'campaign_archived' || currentTab == 'reports'" @click="newCampaign" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.5em;">
                                <span class="d-md-flex d-none">{{ $t('new') }}&nbsp;</span>
                                <span>{{ $t('campaign') }}</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                            </button>
                        </div>
                            <button v-if="currentTab == 'groups'" @click="newGroup" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.5em;">
                                <span class="d-md-flex d-none">{{ $t('new_alt') }}&nbsp;</span>
                                <span>{{ $t('groupe') }}</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                            </button>
                        </div>
                    </div>
                    <div class="tab-body" style="border: none;">
                        <div v-if="currentTab === 'campaign_activate'">
                            <Table :hide-control="(campaignsAll.length == 0) ? true :  false" :i="4" :columns="campaignsAllColumns" :data="campaignsAll" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" :last-cell-width="0">
                                <template v-slot:defaultImage>
                                    <img src="@/assets/img/gif/campagne.gif" alt="empty content" style="width: 11em;"/>
                                    <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_campaign') }}</span>
                                </template>
                            </Table>
                        </div>
                        <div v-if="currentTab === 'campaign_archived'">
                            <Table :hide-control="(campaignsAll.length === 0)" :i="4" :columns="campaignsAllColumns" :data="campaignsAllOk" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" :last-cell-width="0" width="0">
                                <template v-slot:defaultImage>
                                    <img src="@/assets/img/gif/archives.gif" alt="empty content" style="width: 11em;"/>
                                    <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_campaign') }}</span>
                                </template>
                            </Table>
                        </div>
                        <div v-if="currentTab === 'groups'">
                            <Table :hide-control="(groupAll.length === 0)" :i="4" :columns="groupAllColumns" :data="groupAll" :columns-action="groupAllToListen" @action="getCallToActionForGroup" :customized-default-image="true" :last-cell-width="0">
                                <template v-slot:defaultImage>
                                    <img src="@/assets/img/gif/groups.gif" alt="empty content" style="width: 9em;"/>
                                    <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_group') }}</span>
                                </template>
                            </Table>
                        </div>
                        <div v-if="currentTab === 'reports'">
                            <!--<Table :hide-control="(campaignsAll.length === 0)" :i="4" :columns="reportsAllColumns" :data="reportsAll" :columns-action="reportsAllColumnsToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" :last-cell-width="0" width="0">
                                <template v-slot:defaultImage>
                                    <img src="@/assets/img/gif/archives.gif" alt="empty content" style="width: 11em;"/>
                                    <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_connection') }}</span>
                                </template>
                            </Table>-->
                            <Table :hide-control="(campaignsDownload.length === 0)" :i="4" :columns="campaignsAllColumnsReports" :data="campaignsDownload" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" :last-cell-width="0" width="0">
                                <template v-slot:defaultImage>
                                    <img src="@/assets/img/gif/archives.gif" alt="empty content" style="width: 11em;"/>
                                    <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_connection') }}</span>
                                </template>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="card-body p-0 mt-2 mb-0">
                    <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <div>
                                {{ $t('audit_phishing_para') }}
                            </div>
                            <div class="">
                                <a href="/facturation/pricing"><button class="btn cfa-btn-primary">{{ $t('subscribe') }}</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="waitingAPIResponse" class="cfa-page">
            <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                <!-- <div class="preloader_center d-flex flex-column">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                </div> -->
                <div class="loader-pulse">
                    <div class="logo-placeholder">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <span class="loader-text">{{ $t('loading') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal zone-->
        <!-- deletion -->
        <scalable-modal @callback="confirmDeletion1" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
            <template v-slot:content>
                <p>{{ $t('delete_consent') }}<span style="color: red;">{{ $t('delete_consent_red') }}</span></p>
                <div class="col-12 pl-0">
                    <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>{{ $t('title_confirmation') }}</strong></p>
                        <input class="form-control" type="text" autocomplete="new-password" v-model="name_check">
                    </div>
                </div>
            </template>
        </scalable-modal>
        <scalable-modal @callback="confirmDeletion2" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-campaign-deletion-modal'" @close="openDeletionCampaignBox=false" :open="openDeletionCampaignBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
              <template v-slot:content>
                  <p>{{ $t('delete_consent_compaign') }}<span style="color: red;">{{ $t('delete_consent_red') }}</span></p>
                  <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                          <p class="mb-2"><strong>{{ $t('title_compaign_confirmation') }}</strong></p>
                          <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
                      </div>
                  </div>
              </template>
          </scalable-modal>
        <!-- create -->
        <scalable-modal @callback="confirmNewGroup" :break="exitNewGroupModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-group-create" @close="openNewGroup=false" :open="openNewGroup"  :advancedControl="true" validationButtonText="Enregistrer les modifications" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
          <template v-slot:content>
              <!-- blo1 -->
              <div v-if="!waitingAPIResponse2" class="row w-100">
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>{{ $t('group_name') }}</strong></p>
                      <div class="d-flex flex-row">
                          <input class="form-control w-100" type="text" v-model="updateGroupPayload.name">
                      </div>
                  </div>
              </div>

              <div v-if="!waitingAPIResponse2" class="w-100">
                  <div class="form-group py-2">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                          <p class="mb-2"><strong>>{{ $t('add_users') }}</strong></p>
                          <button @click="selectAll" class="btn cfa-btn-primary">{{ $t('select_all') }}</button>
                      </div>
                      <div v-for="(user) in userList" :key="user.id" class="d-flex align-items-center user-row row-user" :style="(user.role.name==='manager') ? 'background-color: #cef1f3;' : ''">
                          <input v-if="user.email_verified_at != null" type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="selectedUser">
                          <input v-else type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="selectedUser" disabled readonly>
                          <label :title="user.email" :for="'permission-' + user.id" class="ms-2 pe-2 text-uppercase w-100 d-flex align-items-center justify-content-between">
                              <span>{{ user.firstname||'INVITÉ' }} {{ user.lastname }}</span>
                              <img :src="user.image_url||defaultImage" style="width: 2em;"/>
                          </label>
                      </div>
                  </div>
              </div>

              <div v-if="waitingAPIResponse2" class="cfa-page">
                  <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
                      <!-- <div class="preloader_center d-flex flex-column">
                          <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                          <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                      </div> -->
                      <div class="loader-pulse">
                        <div class="logo-placeholder">
                            <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                            <span class="loader-text">{{ $t('loading') }}</span>
                        </div>
                    </div>
                  </div>
              </div>
          </template>
        </scalable-modal>
        <!-- update -->
        <scalable-modal @callback="confirmUpdateGroup" :break="exitGroupUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-group-update" @close="openUpdateGroup=false" :open="openUpdateGroup"  :advancedControl="true" validationButtonText="Enregistrer les modifications" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
          <template v-slot:content>
              <!-- blo1 -->
              <div v-if="!updateGroupStatusRequest" class="row w-100">
                  <div class="form-group py-2 px-0">
                      <p class="mb-2"><strong>{{ $t('group_name') }}</strong></p>
                      <div class="d-flex flex-row">
                          <input class="form-control w-100" type="text" v-model="updateGroupData.name">
                      </div>
                  </div>
              </div>

              <div v-if="!updateGroupStatusRequest" class="w-100">
                  <div class="form-group py-2">
                      <p class="mb-2"><strong>{{ $t('group_users') }}</strong></p>
                      <div v-for="(user) in userList" :key="user.id" class="d-flex align-items-center user-row row-user" :style="(user.role.name==='manager') ? 'background-color: #cef1f3;' : ''">
                          <input v-if="user.email_verified_at != null" type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="flattenedIds">
                          <input v-else type="checkbox" :id="'permission-' + user.id" :value="user.id" disabled readonly>
                          <label :title="user.email" :for="'permission-' + user.id" class="ms-2 pe-2 text-uppercase w-100 d-flex align-items-center justify-content-between">
                              <span>{{ user.firstname||'INVITÉ' }} {{ user.lastname }}</span>
                              <img :src="user.image_url||defaultImage" style="width: 2em;"/>
                          </label>
                      </div>
                  </div>
              </div>

              <div v-if="updateGroupStatusRequest" class="cfa-page">
                  <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
                      <!-- <div class="preloader_center d-flex flex-column">
                          <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                          <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                      </div> -->
                      <div class="loader-pulse">
                        <div class="logo-placeholder">
                            <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                            <span class="loader-text">{{ $t('loading') }}</span>
                        </div>
                    </div>
                  </div>
              </div>
          </template>
        </scalable-modal>
        <!-- create campaign -->
        <scalable-modal :disable-control="true" @callback="confirmCreate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-create" @close="newCampaignForm=false" :open="newCampaignForm"  :advancedControl="true" validationButtonText="Nouvelle campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'" class="campaign-create-modal" >
          <template v-slot:content>
            <!--navigable-tabs | removed from the wizard-->
              <Wizard
                  squared-tabs
                  card-background
                  
                  scrollable-tabs
                  :nextButton="wizNextButtonOptions"
                  :backButton="prevButtonOptions"
                  :doneButton="wizDoneButtonOptions"
                  :custom-tabs="[
                      {
                        title: 'Initialisation',
                      },
                      {
                        title: 'Choix de la cible',
                      },
                      {
                        title: 'Template',
                      },
                      {
                        title: 'Plannification',
                      }

                  ]"
                  :startIndex="wizStartInd"
                  :beforeChange="onTabBeforeChange"
                  @change="onChangeCurrentTab"
                  @complete:wizard="wizardCompleted"
              >
                  <transition-group name="slide-fade--">
                    
                    <div v-if="currentTabIndex === 0" class="stepper-body">
                        <div class="row">
                            <small class="error-text"><span>*</span> Les champs sont requis</small>
                        </div>
                        <div class="row w-100 m-inherit">
                            <div class="form-group py-2 px-0 ">
                                <p class="mb-2"><strong>{{ $t('Type') }} <span class="error-text">*</span></strong></p>
                                <div class="d-flex flex-row down-select">
                                    <select class="form-control" v-model="newCampaignType" id="selectType">
                                        <option value="default" selected disabled>{{ $t('select_campaign') }}</option>
                                        <option value="SMSHING">{{ $t('SMSHING') }}</option>
                                        <option value="PHISHING">{{ $t('PHISHING') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="tip-zone d-flex justify-content-center align-items-center oups">
                            <div class="text-center">
                                <img class="mb-2" src="@/assets/img/gif/e-mail.gif" alt="" style="width: 5em;"/>
                                <div v-if="newCampaignType != null && newCampaignType != 'default'">
                                    <p style="font-family: 'Montserrat-bold', 'Montserrat', 'Raleway';">{{ newCampaignType }}</p>
                                    <span>{{ (newCampaignType == 'SMSHING') ? '(Dans ce mode de sensibilisation vos utilisateurs cibles recevons des contenus par sms)' : '(Dans ce mode il sera envoyer aux utilisateurs des mails contenants des liens malveillants.)' }}</span>
                                </div>
                                <p v-else>
                                    <span>({{ $t('campaign_type') }})</span>
                                </p>
                            </div>
                        </div>
                        <div class="row w-100 m-inherit">
                            <div class="form-group py-2 px-0 ">
                                <p class="mb-2"><strong>{{ $t('Name') }} <span class="error-text">*</span></strong></p>
                                <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="newCampaignName">
                                </div>
                            </div>
                        </div>
                        <div class="row w-100 m-inherit mt-3">
                            <div class="row" style="display:flex;justify-content:flex-end;">
                                <p class="mb-2"><strong>{{$t('micro_awareness_campaign_target')}} <span class="error-text">*</span></strong></p>
                                <div class="col-md-6 d-flex align-items-center account-type-block" >
                                <input type="radio" id="one" value="GROUP" class="input-select-adj" v-model="newCampaignTarget" />
                                <label for="GROUP" class="ml-2"  style="margin-top:5px;margin-left:10px;" >{{$t('group')}}</label>
                                </div>
                                <div class="col-md-6 d-flex align-items-center account-type-block" >
                                <input type="radio" id="one" value="SERVICE" class="input-select-adj" v-model="newCampaignTarget" />
                                <label for="SERVICE" class="ml-2"  style="margin-top:5px;margin-left:10px;" >{{$t('departement')}}</label>
                                </div>
                            </div>
                            
                        </div>
                        <div class="p-4 cfa-bg-light-primary mt-2" style="min-height: 2em; border-radius: 5px;">
                            <i class="fa fa-info"></i> {{ $t('unique_name') }}
                        </div>
                    </div>
                    <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div class="tip-zone d-flex justify-content-center align-items-center oups" style="height: 250px; overflow-y:scroll;">
                            <div v-if="!groupeApiWaiting" class="text-center">
                                <div v-if="groupApiWaitingData.length===0 && serviceWaitingData.length == 0">
                                    <img class="mb-2" src="@/assets/img/gif/connexion.gif" alt="" style="width: 5em;"/>
                                    <p>
                                        <span>({{ $t('user_group') }})</span>
                                    </p>
                                </div>
                                <div v-else style="width: 100%; display: flex; justify-content: center;flex-wrap: wrap;">
                                    <div class="" style="margin: .5em;">
                                        <img v-if="groupApiWaitingData?.user_detail?.image_url" class="mb-2" :src="groupApiWaitingData?.user_detail?.image_url" alt="" style="width: 5em; height: 5em; background: #d4ebec;"/>
                                        <img v-else class="mb-2" src="@/assets/img/gif/connexion.gif" alt="" style="width: 5em; height: 5em; background: #d4ebec;"/>
                                        <div>{{ groupApiWaitingData?.user_detail?.firstname }}</div>
                                    </div>
                                    <div v-if="groupApiWaitingData?.users?.length > 15" class="" style="margin: .5em;">
                                        <img class="mb-2" src="/images/image.png" alt="" style="width: 5em; height: 5em; background: #d4ebec;"/>
                                        <div>{{ groupApiWaitingData.users.length - 15 }} {{ $t('remaining') }}</div>
                                    </div>
                                </div>
                                <!-- <p v-if="groupApiWaitingData.length!==0" style="font-family: 'Montserrat-bold', 'Montserrat', 'Raleway';">
                                    (CES UTILISATEURS SERONT LA CIBLE DE CETTE CAMPAGNE)
                                </p> -->
                                <p v-if="groupApiWaitingData.length !== 0 && newCampaignTarget =='GROUP'" style="font-family: 'Montserrat-bold', 'Montserrat', 'Raleway';">
                                    ( {{ $t('target_campaign') }})
                                    <ul>
                                        <p v-for="(user, index) in groupApiWaitingData.user_detail" :key="user.id">
                                            <span style="text-decoration: none; color: inherit; cursor: auto;">{{ index + 1 }}. {{ user.firstname }} {{ user.lastname }}</span>
                                        </p>
                                    </ul>
                                </p>
                                <p v-if="serviceWaitingData.length !== 0 && newCampaignTarget =='SERVICE'" style="font-family: 'Montserrat-bold', 'Montserrat', 'Raleway';">
                                    ( {{ $t('target_campaign') }})
                                    <ul>
                                        <p v-for="(user, index) in serviceWaitingData" :key="user.id">
                                            <span style="text-decoration: none; color: inherit; cursor: auto;">{{ index + 1 }}. {{ user.firstname }} {{ user.lastname }}</span>
                                        </p>
                                    </ul>
                                </p>
                            </div>
                            <div v-if="groupeApiWaiting" class="text-center">
                                <img class="mb-2" src="@/assets/img/gif/load2.gif" alt="" style="width: 5em;"/>
                                <p>
                                    <span>({{ $t('field_below_alt') }})</span>
                                </p>
                            </div>
                        </div>
                        <div v-if="newCampaignTarget =='GROUP'" class="row w-100 m-inherit">
                            <div class="form-group py-2 px-0 ">
                                <p class="mb-2"><strong>{{ $t('target_groups') }} <span class="error-text">*</span></strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="newCampaignGroupe">
                                        <option :value="null" selected disabled>{{ $t('target_group') }}</option>
                                        <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="newCampaignTarget =='SERVICE'" class="row w-100 m-inherit">
                            <div class="form-group py-2 px-0 ">
                                <p class="mb-2"><strong>{{ $t('target_services') }} <span class="error-text">*</span></strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="newCampaignService">
                                        <option :value="null" selected disabled>{{ $t('target_service') }}</option>
                                        <option v-for="group in allServices" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="p-4 cfa-bg-light-primary mt-2" style="min-height: 2em; border-radius: 5px;">
                            <i class="fa fa-info"></i> {{ $t('target_groups_tab') }}
                        </div>
                    </div>
                    
                    <div v-else-if="currentTabIndex === 2" class="stepper-body">
                        <div v-if="waitingAPITemplate2 == true" class="cfa-page">
                            <div class="d-flex justify-content-center align-items-center oups" style="min-height: 55vh">
                                <!-- <div class="preloader_center d-flex flex-column">
                                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                    <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                                </div> -->
                                <div class="loader-pulse">
                                    <div class="logo-placeholder">
                                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                        <span class="loader-text">{{ $t('loading') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="top-div-container px-0 position-relative">
                            <div class="top-div my-4 px-0">
                                <div class="top-div-content">
                                    <select name="template" class="form-control" v-model="selectedTemplateId">
                                        <option :value="null" selected>{{ $t('select_template') }} <span class="error-text">*</span></option>
                                        <option v-for="template in tempData" :value="template.template_id" v-bind:key="template.template_id">{{ template?.details?.name || template.name || "" }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="top-div my-4 px-0">
                                <div class="top-div-content">
                                    <input type="text" disabled class="form-control" name="subject" :placeholder="$t('subject')"  v-model="this.selectedTemplateDetails.subject" />
                                </div>
                            </div>
                            <div class="middle-div my-4 px-0">
                                <div class="content" >
                                    <iframe style="width: 100%; height: 340px;" :srcdoc="this.selectedTemplateDetails.html"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="currentTabIndex === 3" class="stepper-body">
                        <div class="row w-100 m-inherit mt-2">
                            <div class="form-group py-2 px-0">
                                <p class="mb-2"><strong>{{ $t('start_date') }} <span class="error-text" style="  font-size: 0.9em;"><span class="error-text">*</span> (un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                <div class="d-flex flex-row">
                                    <VueDatePicker placeholder="Choisissez la date de début de la campagne" v-model="newCampaignDateStart" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                </div>
                            </div>
                        </div>
                        <div class="p-4 cfa-bg-light-primary mt-2" style="min-height: 2em; border-radius: 5px;">
                            <i class="fa fa-info"></i>{{ $t('target_list') }} 
                        </div>

                        <div class="row w-100 m-inherit mt-4">
                            <div class="form-group py-2 px-0">
                                <p class="mb-2"><strong>Date de fin <span class="error-text">*</span> <span class="error-text">(un minimum d'une journee apres le debut de la campagne)</span></strong></p>
                                <div class="d-flex flex-row">
                                    <VueDatePicker placeholder="Choisissez la date de fin de la campagne" v-model="newCampaignDateEnd" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDateEnd" :disabled="!newCampaignDateStart"></VueDatePicker>
                                </div>
                            </div>
                        </div>
                        <div class="p-4 cfa-bg-light-primary mt-2" style="min-height: 2em; border-radius: 5px;">
                            <i class="fa fa-info"></i> {{ $t('target_list') }} 
                        </div>
                    </div>
                    <!-- <div v-else-if="currentTabIndex === 4" class="stepper-body">
                        <div v-if="waitingAPITemplate" class="cfa-page">
                            <div class="d-flex justify-content-center align-items-center oups" style="min-height: 55vh">
                                <div class="preloader_center d-flex flex-column">
                                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                    <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="container px-0 position-relative">
                            <div class="left-button position-absolute" style="left: .6em; z-index: 1000;" @click="prevLanding">
                                <i class="fa fa-angle-double-left"></i>
                            </div>
                            <div class="middle-div mx-0 px-0">
                                <div class="content" >
                                    <iframe style="width: 100%; height: 440px;" :srcdoc="currentTemplate1.landing_page_details.html"></iframe>
                                </div>
                            </div>
                            <div class="right-button position-absolute"  style="right: .6em; z-index: 1000;" @click="nextLanding">
                                <i class="fa fa-angle-double-right"></i>
                            </div>
                        </div>
                    </div> -->
                  </transition-group>
              </Wizard>
          </template>
        </scalable-modal>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <screen-loader v-if="isLoading"/>

  </div>

</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import Table from "@/components/shared/table/Table.vue";
import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
import 'form-wizard-vue3/dist/form-wizard-vue3.css'
import Wizard from 'form-wizard-vue3'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {getAPI} from "@/axios-api";
import { addHours } from 'date-fns';
// import SsrCarousel from 'vue-ssr-carousel'
// import ssrCarouselCss from 'vue-ssr-carousel/index.css'
//import store from "@/store/store";
//import { mapActions } from "vuex";
import ScreenLoader from "@/components/shared/ScreenLoader.vue";

export default {
    name: 'PhishingView',
    components: {
        ScalableModal,
        Table,
        CFAFooter,
        CFANav,
        Wizard,
        VueDatePicker,
        // SsrCarousel,
        // ssrCarouselCss,
        ScreenLoader
    },
    data () {
        return {
            minDate: new Date("11/10/2024"),
            //minDateEnd: new Date(),
            waitingAPIResponse: true,
            waitingAPIResponse2: true,
            /*isConnected: (localStorage.phish_token != null) ? true : false,*/
            isConnected: true,
            alertString: null,
            currentTab: localStorage.tab_phish_active||'campaign_activate',
            newCampaignTarget:'',
            campaignsData: [],
            campaignsAll: [],
            campaignsAllOk: [],
            campaignsDownload: [],
            campaignsAllColumns: ['Intitutlé', 'Statut', 'Type', 'Date création', 'Actions'],
            campaignsAllColumnsReports: ['Intitutlé', 'Statut', 'Type', 'Actions'],
            campaignsAllToListen: ['Actions'],

            groupAll: [],
            groupAllColumns: ['n°', 'Nom', 'Utilisateurs', 'Denière modification', 'Actions'],
            groupAllToListen: ['Actions'],
            tempData  :[],
            reportsAll: [],
            reportsAllColumns: ['n°', 'Campagne', 'Utilisateurs', 'Actions'],
            reportsAllToListen: ['Actions'],

            userGroupAll: [],
            userGroupAllColumns: ['Nom', 'Prénoms', 'Email', 'Position', 'Actions'],
            userGroupAllToListen: ['Actions'],

            openDeletionBox: false,
            tempDeleteID: null,
            tempDeleteName: null,
            updateGroupPayload: null,
            openUpdateGroup: false,

            name_check: null,
            callbackPayload: null,
            exitOrgModalDel: null,
            exitOrgUpdateModal: null,
            newFirstname: null,
            newLastname: null,
            newEmail: null,
            newPosition: null,
            newCampaignService:'',

            openDeletionCampaignBox: false,
            newCampaignForm: false,

            newCampaignName: null,
            newCampaignType: "default",
            newCampaignDateStart: null,
            newCampaignDateEnd: null,
            newCampaignGroupe: null,
            newCampaignEmailTemplate: null,
            newCampaignLandingTemplate: null,

            currentTabIndex: 0,
            userList: [],
            selectedUser: [],
            credit_mail: '•••',
            credit_sms: '•••',
            total_credit_mail: '•••',
            total_credit_sms: '•••',
            openNewGroup: false,
            exitNewGroupModal: null,
            updateGroupData: {},
            updateGroupStatusRequest: true,
            flattenedIds: [],
            exitGroupUpdateModal: null,
            groupAllData: [],
            groupeApiWaiting: false,
            groupApiWaitingData: [],
            serviceWaitingData: [],
            groupeApiTemplateWaiting: false,
            groupApiTemplateWaitingData: [],
            defaultImage: " /images/defaultImage.png",
            waitingAPITemplate: true,
            waitingAPITemplate2: false,
            allServices:[],
            templateData: [],
            currentTemplateIndex: 0,
            currentTemplate: null,
            org:'',
            templateData1: [],
            currentTemplateIndex1: 0,
            currentTemplate1: null,

            templateChoosed: null,
            templateSelected: null,
            pageChoosed: "",
            privacyCheck: null,
            campaignWaiting: [],

            subscriptionData: [],
            selectedTemplateId: null, // Initially no template is selected
            selectedTemplateDetails: {
                subject: '',
                html: ''
            },
            // wizard form
            wizNextBtn: {
                text: 'Suivant'
            },
            wizStartInd: 0,
            isSubmit: false,
            isLoading: true
        }
    },
    methods: {
        //...mapActions(["updatePageLoad"]),
        async retrieveUserSubscription () {
            this.waitingAPIResponse = true
            await getAPI.get(`subscriptions/find-subscription`).then(response => {
              if(response.status == 200) {
                this.subscriptionData = response.data.data

                if(this.subscriptionData != null){
                console.log("Infos: ", this.subscriptionData)
                }
                
                this.remain_days = this.subscriptionData.remain_days

                const created_at = new Date(this.subscriptionData.created_at)
                created_at.setDate(created_at.getDate() + 30);
                
                const expirationDate = created_at.toISOString();

                this.expiration_date = expirationDate
              }
              else {
                console.log("Subslength: ", this.subscriptionData.length)
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPIResponse = false
              }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
        },
        updatePrivacyCheck() {
            this.privacyCheck = !this.privacyCheck;
            
            console.log("State: ", this.privacyCheck);
            console.log("Coosed: ", this.templateChoosed);
            //setTimeout(() => {
             //   ""
            //}, 0);
        },
        selectAll() {
            this.selectedUser = this.userList.map(user => user.id);
        },
        selectTab (section) {
            localStorage.setItem('tab_phish_active', section)
            this.currentTab = section
        },
        convertDate(dateString, dateOnly=false) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString();
            const formattedTime = date.toLocaleTimeString();
            if(!dateOnly) {
                return `<b>${formattedDate} à ${formattedTime}</b>`;
            }
            return `<b>${formattedDate}</b>`;
        },
        async getAllGroup () {
            this.waitingAPIResponse = true
            await getAPI.get(`groupes`)
                .then(response => {
                    if(response.status == 200) {
                        let groups = response.data.data;
                        this.groupAllData = response.data.data;
                        this.groupAll = []
                        for(let i=0; i<groups.length; i++) {
                            let row = groups[i];

                            let users_count = `
                                <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                            `;
                            let btn = `<div class="d-flex align-items-center">`;
                            btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"></i></button>`;
                            btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                            btn += `</div>`;

                            this.groupAll.push({'n°': (i+1), 'Nom': `<span class="selectable" style="color: #132B4D">${row.name}</span>`, 'Utilisateurs':users_count, 'Denière modification':this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id})
                        }
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        async getAllServices () {
            this.waitingAPIResponse2 = true
            this.org = localStorage.getItem('organisation')
            // let org = localStorage.getItem('organisation')
            await getAPI.get(`services/?organisation=${this.org}`)
                .then(response => {
                    if(response.status == 200) {
                        this.allServices = response.data.data;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse2 = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse2 = false
                })
        },
        getMembers () {
            this.waitingAPIResponse2 = true
             this.org = localStorage.getItem('organisation')
            getAPI.get(`accounts/users/organisation/${this.org}`)
                .then(response => {
                    if(response.status == 200) {
                        this.userList = response.data.data;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse2 = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse2 = false
                })
        },
        templateSelect (newTemplateId) {
            this.currentTemplate = this.tempData.find(template => template.template_id === newTemplateId);
            if (this.currentTemplate) {
                this.selectedTemplateDetails.subject = this.currentTemplate?.details?.subject || this.currentTemplate.subject;
                this.selectedTemplateDetails.html = this.currentTemplate?.details?.html || this.currentTemplate.content;
                console.log('html template', this.selectedTemplateDetails.html);
            } else {
                this.selectedTemplateDetails.subject = '';
                this.selectedTemplateDetails.html = '';
            }
            console.log("Template choisi: ", this.currentTemplate);
        },
        pageSelect () {
            this.pageChoosed = this.currentTemplate1.id;
            console.log("Page choisi: ", this.pageChoosed);
        },
       
        async getTemplate() {
            this.waitingAPITemplate2 = true;
            
            await getAPI.get(`phishing/declaration-page-template/`)
                .then(response => {
                    console.log(response)
                    if(response.status === 200) {
                        this.templateData = response.data.data;

                        // Clear tempData before pushing new data (optional depending on your case)
                        this.tempData = [];

                        // Ensure proper looping, avoiding out of bounds
                        for (let i = 0; i < this.templateData.length; i++) {
                            // Push template details safely
                            if(this.templateData[i] && this.templateData[i].name_template) {
                                this.tempData.push({
                                    details : this.templateData[i]?.template_details,
                                    template_id: this.templateData[i].template_id,
                                    landing_page_id: this.templateData[i].landing_page_id,
                                    name: this.templateData[i].name_template,
                                    subject: this.templateData[i].subject_template,
                                    content: this.templateData[i].content_template,
                                });
                            }
                        }

                        console.log("Etat: ", this.tempData);
                    } else {
                        console.error('Failed to fetch data from API.');
                    }
                    
                    setTimeout(() => {
                        this.waitingAPITemplate2 = false;
                    }, 2000);
                })
                .catch(error => {
                    console.error('Error fetching template data: ', error);
                });
        },

        async getLanding () {
            this.waitingAPITemplate = true
            await getAPI.get(`phishing/landing-pages/`)
                .then(response => {
                    if(response.status == 200) {
                        this.templateData1 = response.data.data;
                        console.log('landing page', this.templateData1);
                        this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
                        console.log(this.currentTemplate1)
                        this.pageChoosed = this.currentTemplate1.id;
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        updateGroup (pos) {
            this.updateGroupPayload = pos
            this.openNewGroup = true
        },
        newGroup () {
            this.userGroupAll = []
            this.updateGroup({name: ''});
            this.getMembers();
        },
        confirmNewGroup () {
            console.log(this.selectedUser)
            console.log(this.updateGroupPayload.name)
            if(this.updateGroupPayload.name != '' && this.selectedUser.length > 0) {
                getAPI.post(`groupes/`, {
                    name: this.updateGroupPayload.name,
                    organisation: localStorage.organisation,
                    users: this.selectedUser
                })
                .then(response => {
                    if(response.status == 201) {
                        this.getAllGroup()
                        //let msg = 'Groupe créé avec succès!'
                        //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        this.exitNewGroupModal = Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.exitNewGroupModal = Date.now()
                })
                .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
            }
            else {
                this.exitNewGroupModal = Date.now()
                //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
        },
        getCallToActionForGroup (payload) {
            if(payload.action_type === 'delete') {
                this.openDeletionBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            }
            if(payload.action_type === 'edit') {
                this.openUpdateGroup = true;
                this.updateGroupStatusRequest = true;
                this.tempDeleteID = payload.id;

                this.getMembers();
                getAPI.get(`groupes/${payload.id}/`,)
                    .then(response => {
                        if(response.status == 200) {
                            this.updateGroupData = response.data;
                            //this.flattenedIds = this.updateGroupData.users.map(({ id }) => id);
                            //console.log(this.updateGroupData, this.flattenedIds)
                            this.updateGroupStatusRequest = false;
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
            }
        },
        confirmDeletion1 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
            }
            else{
                getAPI.delete(`groupes/${this.tempDeleteID}`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Groupe supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllGroup()
                            }, 1500)
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitGroupUpdateModal = Date.now()
                        }
                    })
                    .catch(() => {
                        this.exitGroupUpdateModal = Date.now()
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
        },
        confirmUpdateGroup () {
            if(this.updateGroupData.name.length <= 0) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
            }
            else {
                const arrayOfIds = Object.values(this.flattenedIds);
                console.log("Ids: ", arrayOfIds)
                getAPI.patch(`groupes/${this.tempDeleteID}/`, {
                    name: this.updateGroupData.name,
                    users: arrayOfIds,
                })
                    .then(response => {
                        if(response.status == 200) {
                            this.callbackPayload = 'Groupe mis à jour avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllGroup()
                                this.exitGroupUpdateModal = Date.now()

                            }, 1500)
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitGroupUpdateModal = Date.now()
                        }
                    })
                    .catch(() => {
                        this.exitGroupUpdateModal = Date.now()
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
        },
        async getAllCampaign () {
            this.waitingAPIResponse = true;
            await getAPI.get(`other/campaign`)
            .then(response => {
                console.log(response)
                if(response.status == 200) {
                    let groups = response.data.data.phishing;
                    let updatedGroups = groups.map(group => {
                        return { 
                            ...group, 
                            type: 'Phishing'  
                        };
                    });
                    let gp = response.data.data.smshing;
                    let updatedGps = gp.map(group => {
                        return { 
                            ...group, 
                            type: 'Smshing'  
                        };
                    });
                    const allCamp = updatedGroups.concat(updatedGps)
                    allCamp.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    console.log(allCamp)
                    this.campaignsData = allCamp
                    this.campaignsAll = [];
                    this.campaignsAllOk = []
                    this.campaignsDownload = []
                    const processData = (data) => {
                        for(let i = 0; i < data.length; i++) {
                            let row = data[i];

                            let btn = `<div class="d-flex align-items-center">`;
                            btn += `<a href="/campagne-de-${row.type.toLowerCase()}/details/${row.id}"><button btn-id="${row.id}" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart white-color"></i></button></a>`;
                            btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash white-color"></i></button>`;
                            btn += `</div>`;
                            let campaign = {
                                'n°': (this.campaignsAll.length + 1),
                                'Intitutlé': `<span class="selectable">${row.name}</span>`,
                            'Statut':`${row.status == "created" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">EN ATTENTE DE VALIDATION</span></div>` : (row.status == "canceled" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span></div>` : (row.status == "Completed" ? `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status">TERMINE</span></div>`: `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status">EN COURS</span></div>`))}`,
                                'Type': `<div>${row.type.toUpperCase()}</div>`,
                                'Date création': `<div>${this.convertDate(row.created_at, true)}</div>`,
                                'Actions': btn,
                                name: row.name,
                                id: row.id
                            }

                            if (row.status == "Completed") {
                                this.campaignsAllOk.push(campaign)
                                let btnFinish = `<div class="d-flex align-items-center">
                                        <button btn-id="${row.id}" btn-action="download" class="ms-2 btn btn-primary btn-action"><i class="fa fa-download white-color"></i></button>
                                    </div>`
                                let campaignReport = {
                                'n°': (this.campaignsAll.length + 1),
                                'Intitutlé': `<span class="selectable">${row.name}</span>`,
                            'Statut':`${row.status == "created" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">EN ATTENTE DE VALIDATION</span></div>` : (row.status == "canceled" ? `<div><span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span></div>` : (row.status == "Completed" ? `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status">TERMINE</span></div>`: `<div><span class="badge rounded-pill badge-success cfa-bg-danger mw-status">EN COURS</span></div>`))}`,
                                'Type': `<div>${row.type.toUpperCase()}</div>`,
                                'Date création': `<div>${this.convertDate(row.created_at, true)}</div>`,
                                'Actions': btnFinish,
                                name: row.name,
                                id: row.id
                                }
                                //campaignReport['Actions'] = btnFinish
                                this.campaignsDownload.push(campaignReport)
                            } else {
                                this.campaignsAll.push(campaign);
                            }
                        }
                    }

                    // Process both groups and gp
                    //processData(groups, 'phishing');
                    //processData(gp, 'smshing');
                    processData(allCamp);
                    //this.$store.commit('loadPage')
                    this.isLoading = false
                } else {
                    // Handle errors if necessary
                    this.isLoading = false
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false;
                }, 2000);
            })
            .catch(error => {
                if (error.response) {
                                //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.isLoading = false
                
                this.waitingAPIResponse = false;
            });

            // this.waitingAPIResponse = true
            // getAPI.get(`other/campaign `)
            //     .then(response => {
            //         if(response.status == 200) {
            //             let groups = response.data.data.phishing;
            //             let gp = response.data.data.smshing;
            //             this.campaignsAll = []
            //             for(let i=0; i<groups.length; i++) {
            //                 let row = groups[i];

            //                 let btn = `<div class="d-flex align-items-center">`;
            //                 btn += `<a href="/campagne-de-phishing/details/${row.id}"><button btn-id="row.id" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"></i></button></a>`;
            //                 btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
            //                 btn += `</div>`;

            //                 this.campaignsAll.push({
            //                     'n°': (i+1),
            //                     'Intitutlé': `<span class="selectable">${row.name}</span>`,
            //                     'Statut':`${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span>` : `<span class="badge rounded-pill badge-success cfa-bg-danger mw-status">IN PROGRESS</span>`)}`,
            //                     'Type': 'PHISHING',
            //                     'Date création': this.convertDate(row.created_at, true),
            //                     'Actions': btn,
            //                     name: row.name,
            //                     id: row.id
            //                 });
            //             }
            //         }
            //         else {
            //             //let msg = 'Oups ! something went wrong.'
            //             //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            //         }
            //         setTimeout(() => {
            //             this.waitingAPIResponse = false
            //         }, 2000);
            //     })
            //     .catch(error => {
            //         if (error.response) {
            //             //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
            //         }
            //         else if (error.request) {
            //             //let msg = 'The request was made but no response was received. Please check your network.'
            //             //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            //         }
            //         else {
            //             //let msg = 'Oups ! something went wrong.'
            //             //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            //         }
            //         this.waitingAPIResponse = false
            //     })
            /*
            this.campaignsAll = [
                {
                    'n°':'1',
                    'Intitutlé':'SMShing at StartupGrind - wave1',
                    'Statut':"<span class=\"badge rounded-pill badge-danger cfa-bg-primary mw-status \">EN COURS</span>",
                    'Type': 'PHISHING',
                    'Date création': '2013-02-12, 12:23',
                    'Actions':'<div class="d-flex align-items-center">' +
                        '<a href="/campagne-de-phishing/details/dsxxxsxs"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"> DASHBOARD&nbsp;</i></button></a>' +
                        '<button btn-id="row.id" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>' +
                        '</div>'
                },
                {
                    'n°':'1',
                    'Intitutlé':'SMShing at StartupGrind - wave1',
                    'Statut':"<span class=\"badge rounded-pill badge-warning cfa-bg-primary- mw-status \">EN ATTENTE</span>",
                    'Type': 'SMSHING',
                    'Date création': '2013-02-12, 12:23',
                    'Actions':'<div class="d-flex align-items-center">' +
                        '<a href="/campagne-de-phishing/details/dsxxxsxs"><button btn-id="12E3" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"> DASHBOARD&nbsp;</i></button></a>' +
                        '<button btn-id="12E3" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>' +
                        '</div>'
                }
            ]*/
        },
        newCampaign () {
            this.newCampaignForm=true;
        },
        // prevTemplate() {
        //     if (this.currentTemplateIndex > 0) {
        //         this.currentTemplateIndex--;
        //         this.currentTemplate = this.templateData[this.currentTemplateIndex];
        //     }
        // },
        // nextTemplate() {
        //     if (this.currentTemplateIndex < this.templateData.length - 1) {
        //         this.currentTemplateIndex++;
        //         this.currentTemplate = this.templateData[this.currentTemplateIndex];
        //     }
        // },
        prevLanding() {
            if (this.currentTemplateIndex1 > 0) {
                this.currentTemplateIndex1--;
                this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
            }
        },
        nextLanding() {
            if (this.currentTemplateIndex1 < this.templateData1.length - 1) {
                this.currentTemplateIndex1++;
                this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
            }
        },
        downloadCampaignReport (campaignId) {
            console.log(campaignId)
            const campaign = this.campaignsData.find(camp => camp.id == campaignId)
            console.log(campaign)
            if (campaign.type == "Phishing") {
                getAPI.get(`phishing/report/${campaign.id}/`, { responseType: 'arraybuffer' }).then( response=> {
                    console.log(response)
                    if (response.status == 200) {
                        if (response.data) {
                            
                            const blob = new Blob([response.data], { type: 'application/pdf' });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${campaign.name}_rapport.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(url);
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            } else {
                getAPI.get(`smshing/campaigns/report/${campaign.id}/`, { responseType: 'arraybuffer' }).then( response=> {
                    console.log(response)
                    if (response.status == 200) {
                        if (response.data) {
                            
                            const blob = new Blob([response.data], { type: 'application/pdf' });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${campaign.name}_rapport.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(url);
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
        getCallToActionForCampaigns (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionCampaignBox = true;
                this.tempDeleteID = payload.id;
                console.log("payload: ", payload)
                this.tempDeleteName = payload.name;
            }
            
            if(payload.action_type == 'download') {
                //alert("downlaod")
                this.downloadCampaignReport(payload.id)
            }
        },
        getCallToActionForUserGroup (payload) {
          if(payload.action_type == 'delete') {
              this.openDeletionBox = true;
              this.tempDeleteID = payload.id;
              this.tempDeleteName = payload.name;
          }
        },
        confirmDeletion2 () {
          if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
              this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
              this.openDeletionBox = false
          }
          else{
              getAPI.delete(`phishing/campaign/${this.tempDeleteID}/`)
                  .then(response => {
                      if(response.status == 204) {
                          this.callbackPayload = 'Groupe supprimé avec succès' + '::' + Date.now();
                          setTimeout(() => {
                              this.getAllGroup()
                          }, 1500)
                          this.openDeletionBox = false
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          //this.exitOrgModalDel = Date.now()
                          this.openDeletionBox = false
                      }
                  })
                  .catch(() => {
                      //this.exitOrgModalDel = Date.now()
                      this.openDeletionBox = false
                      this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                  })
          }
          this.openDeletionBox = false
        },
        confirmCreate () {
          delete this.updateDataPayload.logo
          if(this.updateDataPayload.name != '' && this.updateDataPayload.numSiret != '' && this.updateDataPayload.activity != '' && this.updateDataPayload.adress != '' && this.updateDataPayload.phone != '' && this.updateDataPayload.email != '' && this.updateDataPayload.website != '') {
              getAPI.put(`organisations/${this.updateDataPayload.id}/`, this.updateDataPayload)
                  .then(response => {
                      if(response.status == 200) {
                          this.getAllGroup()
                          //let msg = 'Groupe MAJ avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          this.exitOrgUpdateModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now()
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now()
                  })
          }
          else {
              //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitOrgUpdateModal = Date.now()
          }
        },

        // for stepper logic
        onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
            this.onTabWizarValidate(this.currentTabIndex)
        },
        onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
            
            
        },
        wizardCompleted() {
            this.isSubmit = true
            if(this.newCampaignType === 'PHISHING') {
                const groups = [];
                
                if(this.newCampaignTarget =='GROUP'){
                    groups.push(this.newCampaignGroupe);
                    let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                console.log(this.currentTemplate)
                let payload = {
                    name: this.newCampaignName,
                    gophish_landing_page_id: this.currentTemplate.landing_page_id,
                    gophish_template_id: this.currentTemplate.template_id,
                    smtp: "",
                    //service:services,
                    groups: groups,
                    launch_date: newCampaignDateStartF,
                    end_date: this.newCampaignDateEnd,
                    url: "",
                    organisation: localStorage.organisation,
                }
                console.log(payload)
                //return
                // eslint-disable-next-line
                getAPI.post(`phishing/campaign/`, payload)
                    .then(response => {
                        if(response.status === 201) {
                            this.getAllGroup()
                            this.getAllCampaign()
                            //let msg = 'Campagne crééé avec succès!'
                            this.campaignWaiting = response.data;
                            console.log("Camp: ", this.campaignWaiting)
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        }
                        else {
                            
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.$swal({
                            title: 'Campagne créée avec succès',
                            text: 'Campagne créée avec succès. En attente de validation.',
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: "D'accord !"
                        });
                        this.isSubmit = false
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.isSubmit = false
                    });

                }else{
                    let services='';
                  services=this.newCampaignService;
                  let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                let payload = {
                    name: this.newCampaignName,
                    gophish_landing_page_id: this.currentTemplate.landing_page_id,
                    gophish_template_id: this.currentTemplate.template_id,
                    smtp: "",
                    service:services,
                    //groupes: groups,
                    launch_date: newCampaignDateStartF,
                    end_date: this.newCampaignDateEnd,
                    url: "",
                    organisation: localStorage.organisation,
                }
                //console.log(payload)
                //return
                 //eslint-disable-next-line
                getAPI.post(`phishing/campaign/`, payload )
                    .then(response => {
                        if(response.status === 201) {
                            this.getAllGroup()
                            this.getAllCampaign()
                            //let msg = 'Campagne crééé avec succès!'
                            this.campaignWaiting = response.data;
                            console.log("Camp: ", this.campaignWaiting)
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        }
                        else {
                            
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.$swal({
                            title: 'Campagne créée avec succès',
                            text: 'Campagne créée avec succès. En attente de validation.',
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: "D'accord !"
                        });
                        this.isSubmit = false

                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.isSubmit = false

                    });
                }
                
                
            
            }
            else {
                //const groups = [];
                const groups = [];
                
                if(this.newCampaignTarget =='GROUP'){
                    groups.push(this.newCampaignGroupe);
                    //groups.push(this.newCampaignGroupe);
                let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                let payload = {
                    name: this.newCampaignName,
                    gophish_landing_page_id: this.currentTemplate.landing_page_id,
                    gophish_template_id: this.currentTemplate.template_id,
                    smtp: "",
                    //service:services,
                    groups: groups,
                    //end_date: this.newCampaignDateEnd,
                    launch_date: newCampaignDateStartF,
                    closing_date: this.newCampaignDateEnd,
                    url: "",
                    organisation: localStorage.organisation,
                }
                //console.log(payload)
                //return
                getAPI.post(`smshing/campaign/`, payload)
                    .then(response => {
                        console.log('response', response.data);
                        if(response.status === 201) {
                            this.getAllGroup()
                            this.getAllCampaign()
                            let msg = 'Campagne crééé avec succès!'
                            this.campaignWaiting = response.data;
                            console.log("Camp: ", this.campaignWaiting)
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        }
                        else {
                            
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.$swal({
                            title: 'Campagne créée avec succès',
                            text: 'Campagne créée avec succès. En attente de validation.',
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: "D'accord !"
                        });
                        this.isSubmit = false
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.isSubmit = false
                    });

                }else{
                    let services='';
                  services=this.newCampaignService;
                  //groups.push(this.newCampaignGroupe);
                let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                getAPI.post(`smshing/campaign/`, {
                    name: this.newCampaignName,
                    gophish_landing_page_id: this.currentTemplate.landing_page_id,
                    gophish_template_id: this.currentTemplate.template_id,
                    smtp: "",
                    service:services,
                    //groupes: groups,
                    //end_date: this.newCampaignDateEnd,
                    launch_date: newCampaignDateStartF,
                    closing_date: this.newCampaignDateEnd,
                    url: "",
                    organisation: localStorage.organisation,
                })
                    .then(response => {
                        console.log('response', response.data);
                        if(response.status === 201) {
                            this.getAllGroup()
                            this.getAllCampaign()
                            let msg = 'Campagne crééé avec succès!'
                            this.campaignWaiting = response.data;
                            console.log("Camp: ", this.campaignWaiting)
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        }
                        else {
                            
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.$swal({
                            title: 'Campagne créée avec succès',
                            text: 'Campagne créée avec succès. En attente de validation.',
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonText: "D'accord !"
                        });
                        this.isSubmit = false
                    })
                    .catch(error => {
                        if (error.response) {
                            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            let msg = 'The request was made but no response was received. Please check your network.'
                            this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now();
                        this.newCampaignForm = false;
                        this.isSubmit = false
                    });
                }
               
            }
        },
        onTabWizarValidate(stepIndex) {
            if (stepIndex == 0) {
                if (this.newCampaignType == "default" || !this.newCampaignName || this.newCampaignName.trim() == "" || !this.newCampaignTarget || this.newCampaignTarget.trim() == "") {
                    return true
                }
                return false
            } else if (stepIndex == 1) {
                if (this.newCampaignTarget =='GROUP' && (!this.newCampaignGroupe || this.newCampaignGroupe.trim() == "")) {
                    return true
                } else if (this.newCampaignTarget == 'SERVICE' && (!this.newCampaignService || this.newCampaignService.trim() == "")) {
                    return true
                } else {
                    return false
                }
            } else if (stepIndex == 2) {
                if (!this.selectedTemplateId) {
                    return true
                }
                return false
            } else if (stepIndex == 3) {
                if (!this.newCampaignDateStart || !this.newCampaignDateEnd) {
                    return true
                }
                return false
            } else {
                return false
            }
        },
        onStepButton() {
            return this.onTabWizarValidate(this.currentTabIndex)
            
        }
    },
    async mounted () {
        //console.log("Templates: ", this.templateData)
        //this.getTemplate();
        await this.getAllServices();
        await this.getAllGroup();
        await this.retrieveUserSubscription();
        await this.getTemplate();
        await this.getLanding();
        await this.getAllCampaign();
        //console.log(this.newCampaignDateStart)
    },
    computed: {
        nextButtonOptions() {
            return (this.currentTabIndex === 33)
                ? {
                    text: 'Créer la campagne',
                    icon: 'check',
                    hideIcon: true, // default false but selected for sample
                    hideText: false, // default false but selected for sample
                    disabled: false,
                }
                : ((this.currentTabIndex === 0 && (this.newCampaignType === 'default' || this.newCampaignName == null)) ?
                    {
                        disabled: true,
                        text: "Suivant",
                    }
                    : ((this.currentTabIndex === 1 && (this.newCampaignGroupe == null || this.newCampaignService == null)) ?
                        {
                            disabled: true,
                            text: "Suivant",
                        }
                        : ((this.currentTabIndex === 2 && (this.currentTemplate == null)) ?
                            {
                                disabled: true,
                                text: "Suivant",
                            }
                            : ((this.currentTabIndex === 3 && (this.newCampaignDateStart == null || this.newCampaignDateEnd == null)) ?
                                {
                                    disabled: true,
                                    text: "Suivant",
                                }
                                :
                                {
                                    disabled: false,
                                    text: "Suivant",
                                }
                            )
                                // : ((this.currentTabIndex === 4 && (this.currentTemplate1 == null)) ?
                                //     {
                                //         disabled: true,
                                //         text: "Suivant",
                                //     }
                                //     :
                                //     {
                                //         disabled: false,
                                //         text: "Suivant",
                                //     })
                                // )
                            )
                        )
                    );
        },
        prevButtonOptions() {
            return {
                disabled: false,
                text: "Précédent"
            };
        },
        wizNextButtonOptions() {
            return {
                disabled: this.onStepButton(),
                text: "Suivant"
            };
        },
        wizDoneButtonOptions() {
            return {
                disabled: this.onStepButton() || this.isSubmit,
                text: !this.isSubmit ?  "Termine"  : `En cours de creation...`
            };
        },
        doneButtonOptions() {
            return (this.currentTemplate == null) ? {
                disabled: true,
                text: "Terminé"
            }: {
                disabled: false,
                text: "Terminé"
            };
        },
        minDateEnd() {
            if (this.newCampaignDateStart) {
                let currentDate =  new Date(this.newCampaignDateStart)
                return currentDate.setDate(currentDate.getDate() + 1)
            }
            return new Date()
        },
        
    },
    watch: {
        selectedTemplateId: function (newTemplateId) {
            this.templateSelect(newTemplateId);
        },
        newCampaignGroupe: function (value) {
            this.groupeApiWaiting = true;
            getAPI.get(`groupes/${value}`)
                .then(response => {
                    if(response.status == 200) {
                        this.groupApiWaitingData = response.data;
                        console.log(this.groupApiWaitingData)
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.groupeApiWaiting = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.groupeApiWaiting = false
                })
        },
        newCampaignService: function (value) {
            this.groupeApiWaiting = true
            getAPI.get(`services/get_users?service=${value}`).then(res => {
                //console.log(res)
                if (res.status == 200) {
                    this.serviceWaitingData = res.data.data
                    console.log(this.serviceWaitingData)
                }
                this.groupeApiWaiting = false
            })
            .catch(err => {
                console.log(err)
                this.groupeApiWaiting = false
            })
        },
        currentTabIndex: function (value) {
            if(value === 2) {
                if(this.templateData != null) {
                    this.getTemplate();
                    console.log("Tab templates: ", this.templateData)
                }
            }
            if(value === 4) {
                if(this.templateData1.length === 0) {
                    this.getLanding();
                }
            }
        },
    }
}
</script>

<style scoped>
.cfa-page{}
.cfa-block{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    /* border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

/* TAB STYLE */
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 10px;*/
  /*border-bottom: 1px solid #ccc;*/
}

.tab-header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-header ul li {
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px 5px 0 0;
}

.tab-header ul li.active {
  background-color: #333;
  color: #fff;
  border:1px solid #000;
}

.tab-header ul li:hover{
  background-color: #333;
  color: #fff;
  border:1px solid #000;
}

.tab-body {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
}
.gFolder-container{
    background-color: var(--primary-light-color-2);
    border-radius: 0.5em;
    height: 4.3em;
    width: 4.6em;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gBlock{
    align-items: center;
    justify-content: start;
    border-radius: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
    height: 8em !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.gBlock div:last-of-type p{
    margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
    font-weight: 400;
    font-size: 2em;
}
.tab_font{
    font-size: 13px;
}
.user-row{
    background: #fff;
    padding-left: 1em;
    margin-bottom: 0.2em;
}
.row-user{
    transition: .2s all ease-in;
}
.row-user:hover{
    background: #f5f5f5;
    cursor: pointer;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-button,
.right-button {
    padding: 10px;
    cursor: pointer;
    background-color: lightgray;
}
.top-div-container{
    width: 92%;
    margin: 20px auto;
}
.top-div-content select{
    width: 100%;
}
.top-div{
    flex: 0;
    margin: 0 10px;
}
.middle-div {
    flex: 1;
    margin: 0 10px;
}

.content {
    width: 100%;
    height: auto;
    border: 1px solid #e0e1e8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.cfa-section-title{
    color: #fff;
}
</style>