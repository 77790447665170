<template>
    <div class="cfa-root">
        <c-f-a-nav :alert="alertString" contentClass="position-relative">
            <template v-slot:title>
                <div class="w-100 pt-5 text-start mb-3 d-flex justify-content-between align-items-center efwegl">
                    <h1 v-if="subscriptionData?.is_activated == true && subscriptionData !=  null" class="cfa-section-title">{{ $t('user-traking') }} ({{userCount}})</h1>
                    <h1 v-else class="cfa-section-title">{{ $t('user-traking') }}</h1>
                    <div></div>
                </div>
            </template>
            <template v-slot:content>
                <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
                    <div class="tab" v-if="subscriptionData?.is_activated == true && subscriptionData != null">
                        <!--<div class="tab-header">
                            <ul>
                                <li :class="{ 'active': currentTab === 'individual' }" @click="currentTab = 'individual'"><i class="fa fa-users"></i>&nbsp;Progression des utilisateurs</li>
                            </ul>
                        </div>-->
                        <div class="tab-body"  style="border: none;">
                        <div v-if="currentTab === 'individual'">
                            <Table :columns="userAllColumns" :data="userAll" :columns-action="userAllToListen" @action="getCallToActionForUser" last-cell-width="0" :i="3"></Table>
                        </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="card-body p-0 mt-2 mb-0">
                        <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
                            <div class="d-flex align-items-center justify-content-between w-100">
                            <div>
                                {{ $t('our_plans') }}
                            </div>
                            <div class="">
                                <a href="/facturation/pricing"><button class="btn cfa-btn-primary">{{ $t('subscribe') }}</button></a>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

              <div v-show="waitingAPIResponse" class="cfa-page">
                    <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
                        <!-- <div class="preloader_center d-flex flex-column">
                            <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                            <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
                        </div> -->
                        <div class="loader-pulse">
                            <div class="logo-placeholder">
                                <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                                <span class="loader-text">{{ $t('loading') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </c-f-a-nav>
        <c-f-a-footer></c-f-a-footer>

        <!-- create -->
        <scalable-modal @callback="addNewOrg" :break="exitOrgModal" label="CRÉER UN PLAN" uid="cfa-modal-org-create" @close="newModule=false" :open="newModule" :advancedControl="true" validationButtonText="Inviter" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0">
                        <p class="mb-2"><strong>EMAIL</strong></p>
                        <input class="form-control" type="email" v-model="mew_member_email">
                    </div>
                </div>
            </template>
        </scalable-modal>

        <!-- update -->
        <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-org-update" @close="openUpdateOrg=false" :open="openUpdateOrg" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; '">
            <template v-slot:content>
                <!-- blo1 -->
                <div class="row w-100">
                    <div class="form-group py-2 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('titled') }}</strong></p>
                        <div class="d-flex flex-row">
                            <input class="form-control" type="text" v-model="updateDataPayload.name">
                        </div>
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('activity_area') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.activity">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('address') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.address">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('telephone') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.phone">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('email') }}</strong></p>
                        <input class="form-control" type="email" v-model="updateDataPayload.email">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('website') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.website">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>VILLE</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.city">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('pays') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.country">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('administrative_email') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.email_administratif">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('billing_email') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.email_facturation">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('administrative_manager') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.name_administratif">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('billing_responsible') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.name_facturation">
                    </div>
                </div>

                <div class="row w-100 mt-3">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('administrative_contact') }}</strong></p>
                        <input class="form-control" type="text" v-model="updateDataPayload.phone_administratif">
                    </div>
                </div>

                <div class="row w-100 mt-3 d-none">
                    <div class="form-group py-3 pe-0 pe-md-2 ">
                        <p class="mb-2"><strong>{{ $t('organization_logo') }}</strong></p>
                        <image-input @image-loaded="onImageLoaded2"/>
                    </div>
                </div>
            </template>
        </scalable-modal>

        <!-- delete -->
        <scalable-modal @callback="confirmDeletion" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
            <template v-slot:content>
                <p>{{ $t('delete_module') }} <span style="color: red;">{{ $t('delete_consent_red') }}</span></p>
                <div class="col-12 pl-0">
                    <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>{{ $t('module_title') }}</strong></p>
                        <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
                    </div>
                </div>
            </template>
        </scalable-modal>

        <div class="gPopupZone">
            <!-- 01 -->
            <scalable-modal uid="cfa-modal-004" display-mode="flex" @close="addGroup=false" :open="addGroup" :advancedControl="true" validationButtonText="Ajouter la list d'utilisateur" exitButtonText="Annuler">
                <template v-slot:content>
                    <form action="" method="post" enctype="multipart/form-data" style="width: 100%;">
                        <div class="w-100 mt-3">
                            <div class="form-group py-3 pe-0">
                                <p class="mb-2"><strong>{{ $t('choose_organization') }}</strong></p>
                                <custom-select :options="organisation_plain_list" v-model="selectedOrg" @input="getOrgSelected"></custom-select>
                            </div>
                        </div>
                        <div id="collapse-day-details" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body _p0">
                                <div class="form-group ">
                  <span class="d-flex align-items-center justify-content-between">
                    <label class="control-label" for="inputPassnew">{{ $t('list_of_email') }}</label>
                  </span>
                                    <br>
                                    <div style="min-height: 15vh; width: 100%;" id="studentList">
                                        <div class="input-group">
                                            <textarea id="mailListContainer" @keyup="checkMailList($event)" v-model="toAdd_email_list" style="min-height: 25em;" class="form-control" ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </scalable-modal>
            <!-- 02 -->
            <scalable-modal uid="cfa-modal-005 " display-mode="flex" @close="addUser=false" :open="addUser" :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler" :style="'width: 40%; height: auto;'">
                <template v-slot:content>
                    <div class="form-group w-100">
                        <div class="w-100 mt-3">
                            <div class="form-group py-3 pe-0">
                                <p class="mb-2"><strong>{{ $t('choose_organization') }}</strong></p>
                                <custom-select :options="organisation_plain_list" v-model="selectedOrg" @input="getOrgSelected"></custom-select>
                            </div>
                        </div>
                        <br>
                        <span class="d-flex align-items-center justify-content-between">
              <label class="control-label" for="inputPassnew">{{ $t('add-email') }}</label>
            </span>
                        <div style="min-height: 15vh" id="studentList">
                            <div class="input-group">
                                <input id="inputPassnew" class="form-control" name="inputPassnew" type="email"  v-model="toAdd_email">
                            </div>
                        </div>
                    </div>
                </template>
            </scalable-modal>
        </div>
    </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import {getAPI} from "@/axios-api";
// import ImageInput from '@/components/shared/input/ImageInput.vue'
// import TableExpend from "@/components/shared/table/TableExpend.vue";
import Table from "@/components/shared/table/Table.vue";
// import SelectButton from '@/components/shared/button/SelectButton.vue'
// import CustomSelect from "@/components/shared/input/CustomSelect.vue";
// import ProgressBarLine from '@/components/shared/chart/ProgressBarLine.vue'
import utils from "@/composable/utils"
export default {
    name: 'OrganisationView',
    components: {
        CFAFooter,
        CFANav,
        ScalableModal,
        // ImageInput,
        // TableExpend,
        // SelectButton,
        Table,
        // CustomSelect,
        // ProgressBarLine
    },
    data () {
        return {
            mew_member_email: '',
            currentTab: 'individual',
            newModule: false,
            exitOrgModal: null,
            alertString: '',
            waitingAPIResponse: true,
            allOrganisation: [],

            org_name: '',
            org_numSiret: '',
            org_activity: '',
            org_city: '',
            org_country: '',
            org_address: '',
            org_tel: '',
            org_email: '',
            org_website: '',
            org_logo: '',

            openDeletionBox: false,
            tempDeleteID: '',
            tempDeleteName: '',
            name_check: '',
            callbackPayload: null,
            exitOrgModalDel: null,
            updateDataPayload: null,
            exitOrgUpdateModal: null,
            openUpdateOrg: false,

            orgs: [],
            orgsColumns: ['NOM', 'ACTIVITÉ', 'ADRESSE', 'PHONE', 'MAIL', 'WEBSITE', 'ACTIONS'],
            orgsToListen: ['ACTIONS'],

            userAll: [],
            userAllColumns: ['Nom & Prénoms', "Service", 'Statut', 'CyberScore', 'Progression', 'CyberScore certification', 'Date inscription', 'Date démarrage', 'Date finalisation'],
            userAllToListen: [''],

            addUser: false,
            addGroup: false,
            toAdd_email_list: [],
            toAdd_email: null,

            subscriptionData: [],

            selectedOrg: null,
            organisation_plain_list: [],
            orgData: null,
            isOrg: true,
            userCount: 0,
            staticService: [],
            defaultImage: "/images/defaultImage.png",
        }
    },
    methods: {
        getService () {
          this.waitingAPIResponse = true
          this.waitingAPIResponse2 = true;
          getAPI.get(`services/?organisation=${localStorage.organisation}`)
              .then(response => {
                if(response.status === 200) {
                  let services = response.data.data;
                  this.staticService = services;
                }
                else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                setTimeout(() => {
                  this.waitingAPIResponse = false
                  this.waitingAPIResponse2 = false;
                }, 2000);
              })
              .catch(error => {
              if (error.response) {
                //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                //let msg = 'The request was made but no response was received. Please check your network.'
                //this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false;
              this.waitingAPIResponse2 = false;
            })
        },
         retrieveUserSubscription () {
            this.waitingAPIResponse = true
            getAPI.get(`subscriptions/find-subscription`).then(response => {
              if(response.status == 200) {
                this.subscriptionData = response.data.data

                if(this.subscriptionData != null){
                console.log("Infos: ", this.subscriptionData)
                }
                
                this.remain_days = this.subscriptionData.remain_days

                const created_at = new Date(this.subscriptionData.created_at)
                created_at.setDate(created_at.getDate() + 30);
                
                const expirationDate = created_at.toISOString();

                this.expiration_date = expirationDate
              }
              else {
                  //let msg = 'Oups ! something went wrong.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                  this.waitingAPIResponse = false
              }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
      },
        getOrganisationData () {
            let org = localStorage.getItem('organisation')
            console.log(org)
            if(org != null) {
                getAPI.get(`organisations/${org}`)
                    .then(response => {
                        if(response.status == 200) {
                            this.orgData = response.data
                            //this.alertString = `Données chargées avec succès|alert-yes|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        setTimeout(() => {
                            this.waitingAPIResponse = false
                        }, 2000);
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.waitingAPIResponse = false
                    })
            }
            else {
                this.isOrg = false
                this.orgData = null
            }
        },
        getMembers () {
            this.waitingAPIResponse = true
            let org = localStorage.getItem('organisation')
            getAPI.get(`accounts/users/organisation/${org}?is_activated=True`)
                .then(response => {
                    console.log(response)
                    if(response.status == 200) {
                        let users = response.data.data;
                        this.userCount = users.length;
                        this.userAll = []
                        console.log(users)
                        /*for(let i=0; i<users.length; i++) {
                            let row = users[i]
                            let email = `<a style="color: #175ccc; font-weight: 400;" href="mailto:${row.email}">${row.email}</a>`
                            let btn = `<div class="d-flex align-items-center">`;

                            let date_start = null;
                            let date_end = null;
                            let progress_value = 0;
                            let cyber_score = 0;
                            let stat_quiz=row.extra_data?.stat_quizz;
                            let stat_module = row.extra_data?.stat_module;
                            let status = (row.type_account == 'organisation') ? `
                            <span class="badge rounded-pill badge-danger- cfa-btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;MANAGER&nbsp;&nbsp;&nbsp;</span>
                                
                                  
                              ` :`
                                  <span class="badge rounded-pill badge-danger- cfa-btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;MEMBRE&nbsp;&nbsp;&nbsp;</span>
                              `;
                            console.log(stat_module)
                            if(stat_module != null) {
                                // calculate progression value
                                for(let i = 0; i<stat_module.length; i++) {
                                    progress_value += stat_module[i].progression
                                    cyber_score += stat_module[i].cyberscore
                                }
                                //progress_value = progress_value;
                                cyber_score = `
                                    <span class="badge rounded-pill badge-danger">${cyber_score}</span>
                                `;

                                // get start date
                                let temp = stat_module[0].created_at ? this.formatDateFromString(stat_module[0].created_at) : null;
                                date_start = temp ? `${temp[0]}-${temp[1]}-${temp[2]}` : `N/A`;
                                date_start = `${temp[0]}-${temp[1]}-${temp[2]}`;
                            //     if(row.stat_quizz[0].note > 0){
                            //         let tem=this.formatDateFromString(stat_module[0].updated_at);
                            //           date_end=`${tem[0]}-${tem[1]}-${tem[2]}`;
                            //    }else {
                            //     //     date_end = 'EN COURS';
                            //         date_end = `
                            //              <span class="badge rounded-pill badge-danger- cfa-btn-gold">&nbsp;&nbsp;&nbsp;&nbsp;EN COURS&nbsp;&nbsp;&nbsp;</span>
                            //      `;
                            //      }
                                //get final date
                                if(stat_quiz[0].note >0) {
                                    let tem=this.formatDateFromString(stat_module[0].updated_at);
                                      date_end=`${tem[0]}-${tem[1]}-${tem[2]}`;
                                    date_end = `
                                        <span class="badge rounded-pill badge-danger- cfa-btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;${date_end}&nbsp;&nbsp;&nbsp;</span>
                                    `;
                                }
                                else {
                                    date_end = 'EN COURS';
                                    date_end = `
                                        <span class="badge rounded-pill badge-danger- cfa-btn-gold">&nbsp;&nbsp;&nbsp;&nbsp;EN COURS&nbsp;&nbsp;&nbsp;</span>
                                    `;
                                }
                            }
                            else{
                                date_start = "...";
                                date_end = "...";
                                //date_end = `
                                    <span class="badge rounded-pill badge-danger- cfa-btn-secondary">&nbsp;&nbsp;&nbsp;&nbsp;${date_end}&nbsp;&nbsp;&nbsp;</span>
                                `;
                                cyber_score = `
                                    <span class="badge rounded-pill badge-danger">0</span>
                                `;
                            }

                            let progress = `<div class="cfa-progress-bar2 my-4 w-100">
                              <div class="d-flex align-items-center w-100">
                                <div class="position-relative cfa-progress-wrapper w-100" style="background-color: var(--primary-light-color); height: 1em; border-radius: 3px;">
                                  <div class="cfa-progress-line" style="background-color: var(--primary-color);width:${ progress_value }%; height: 1em; border-radius: 3px;'"></div>
                                </div>
                                &nbsp;
                                <span style="min-width: 3em;">${ progress_value }%</span>
                              </div>
                            </div>`;

                            let name = (row.firstname||row.email) + ' ' + (row.lastname||'');

                            let avatar = (row.image_url != null) ? `<div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center"><img width="25" height="25" src="${row.image_url}" alt="avatar"><div><span class="d-none d-md-flex">${name}&nbsp;</span>  </div></div>` :
                                `<div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center"><img width="25" height="25" src="${this.defaultImage}" alt="avatar"><div><span class="d-none d-md-flex">${name}</span>  </div></div>`

                            let service = `
                                    <span class="badge rounded-pill cfa-border-primary cfa-primary">&nbsp;&nbsp;&nbsp;&nbsp;${(this.staticService.length > 0) ? this.staticService[0].name : ''}&nbsp;&nbsp;&nbsp;</span>
                                `;
                            //let service = row.service.name;

                            this.userAll.push({'Nom & Prénoms': avatar, 'Service': service, 'Statut':status, 'Nom & Prénoms-': name, 'Email':email||'', 'Téléphone':row.phone, 'CyberScore': cyber_score, 'Progression': `<div>${progress}</div>`, 'Fin Souscription': 'undefined', 'Actions': btn, name: i, id: row.id, 'Date inscription':`<div>${this.formatDateFromString(row.created_at)[0]}-${this.formatDateFromString(row.created_at)[1]}-${this.formatDateFromString(row.created_at)[2]}</div>`, 'Date démarrage':date_start, 'Date finalisation':date_end})
                        }*/
                        
                        

                        for (let i = 0; i < users.length; i++) {
                            let row = users[i];
                            let email = `<a style="color: #175ccc; font-weight: 400;" href="mailto:${row.email}">${row.email}</a>`;
                            let btn = `<div class="d-flex align-items-center"></div>`;

                            //let date_start = `<div class="text-center d-flex align-items-center justify-content-center"><span>...<span></div>`;
                            let date_start = `<span class="badge rounded-pill badge-danger">Non commencé</span>`
                            let date_end = `<span class="badge rounded-pill badge-danger">Non commencé</span>`;
                            let progress_value = 0;
                            let cyber_score = 0;

                            let stat_quiz = row.extra_data?.stat_quizz || [];
                            let stat_module = row.extra_data?.stat_module || [];

                            // Determine status based on account type
                            let status = row.type_account === "organisation" ? `
                                <span class="badge rounded-pill badge-danger- cfa-btn-primary">MANAGER</span>
                            ` : `
                                <span class="badge rounded-pill badge-danger- cfa-btn-primary">MEMBRE</span>
                            `;

                            if (stat_module.length > 0) {
                                // Calculate progression value and cyberscore
                                for (let module of stat_module) {
                                    progress_value += module.progression;
                                    cyber_score += module.cyberscore;
                                }

                                // Format cyber score
                                cyber_score = `
                                    <span class="badge rounded-pill badge-danger">${cyber_score}</span>
                                `;

                                // Get start date
                                let created_at = stat_module[0]?.created_at;
                                if (created_at) {
                                    let temp = this.formatDateFromString(created_at);
                                    //date_start = `${temp[0]}-${temp[1]}-${temp[2]}`;
                                    date_start = `<div class="text-center">${temp[0]}/${temp[1]}/${temp[2]}</div>`;
                                }

                                // Get final date
                                if (stat_quiz[0]?.note > 0) {
                                    let updated_at = stat_module[0]?.updated_at;
                                    if (updated_at) {
                                        let temp = this.formatDateFromString(updated_at);
                                        date_end = `
                                            <span class="badge rounded-pill badge-danger- cfa-btn-primary">${temp[0]}-${temp[1]}-${temp[2]}</span>
                                        `;
                                    }
                                } /*else {
                                    date_end = `
                                        <span class="badge rounded-pill badge-danger- cfa-btn-gold">EN COURS</span>
                                    `;
                                }*/
                            } else {
                                // Default values when stat_module is null or empty
                                cyber_score = `
                                    <span class="badge rounded-pill badge-danger">0</span>
                                `;
                            }

                            let quizzScore = `<span class="badge rounded-pill badge-danger">${stat_quiz[0]?.note || '-' }</span>`
                            // Build progress bar
                            let progress = `
                                <div class="cfa-progress-bar2 my-4 w-100">
                                    <div class="d-flex align-items-center w-100">
                                        <div class="position-relative cfa-progress-wrapper w-100" style="background-color: var(--primary-light-color); height: 1em; border-radius: 3px;">
                                            <div class="cfa-progress-line" style="background-color: var(--primary-color); width: ${progress_value}%; height: 1em; border-radius: 3px;"></div>
                                        </div>
                                        &nbsp;
                                        <span style="min-width: 3em;">${progress_value}%</span>
                                    </div>
                                </div>
                            `;

                            // Build name and avatar
                            let name = `${row.firstname || row.email} ${row.lastname || ""}`;
                            let avatar = row.image_url ? `
                                <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                                    <img width="25" height="25" src="${row.image_url}" alt="avatar">
                                    <span><span class="d-md-flex">${name}&nbsp;</span></span>
                                </div>
                            ` : `
                                <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                                    <img width="25" height="25" src="${this.defaultImage}" alt="avatar">
                                    <span><span class="d-md-flex">${name}</span></span>
                                </div>
                            `;

                            // Build service badge
                            let service = `
                                <span class="badge rounded-pill cfa-border-primary cfa-primary">${this.staticService[0]?.name || ''}</span>
                            `;

                            // Validate phone number
                            let isValidPhone = this.validatePhoneNumber(row.phone);

                            this.userAll.push({
                                'Nom & Prénoms': avatar,
                                'Service': service,
                                'Statut': status,
                                'Nom & Prénoms-': name,
                                'Email': email || '',
                                'Téléphone': isValidPhone ? row.phone : "Invalid Phone",
                                'CyberScore': cyber_score,
                                'Progression': `<div>${progress}</div>`,
                                'CyberScore certification': quizzScore,
                                'Fin Souscription': 'undefined',
                                'Actions': btn,
                                'name': i,
                                'id': row.id,
                                //'Date inscription': `<div>${this.formatDateFromString(row.created_at).join('-')}</div>`,
                                'Date inscription': `<div>${utils.formatDateToLocal(row.created_at, "fr-FR")}</div>`,
                                'Date démarrage': date_start,
                                'Date finalisation': date_end
                            });
                        }
                        console.log(this.userAll)
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPIResponse = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        //let msg = 'The request was made but no response was received. Please check your network.'
                        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                })
        },
        onImageLoaded (imageData) {
            this.org_logo = imageData
        },
        onImageLoaded2 (imageData) {
            this.updateDataPayload.logo = imageData
        },
        addNewOrg () {
            if(this.mew_member_email != '') {
                const formData = new FormData();
                formData.append('email', this.mew_member_email);
                formData.append('organisation', localStorage.organisation);

                getAPI.post('accounts/register/add-user', formData, )
                    .then(response => {
                        if(response.status == 201) {
                            this.getMembers()
                            //let msg = 'Utilisateur invité avec succès!'
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgModal = Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgModal = Date.now()
                    })
            }
            else{
                //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgModal = Date.now()
            }
        },
        deleteData(id, name) {
            this.openDeletionBox = true;
            this.tempDeleteID = id;
            this.tempDeleteName = name
        },
        confirmDeletion () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
            }
            else{
                getAPI.delete(`organisations/${this.tempDeleteID}/`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Organisation supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllOrgs()
                            }, 1500)
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgModalDel = Date.now()
                        }
                    })
                    .catch(() => {
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
        },
        updateData (pos) {
            this.updateDataPayload = pos
            this.openUpdateOrg = true
        },
        confirmUpdate () {
            delete this.updateDataPayload.logo
            if(this.updateDataPayload.name != '' && this.updateDataPayload.numSiret != '' && this.updateDataPayload.activity != '' && this.updateDataPayload.adress != '' && this.updateDataPayload.phone != '' && this.updateDataPayload.email != '' && this.updateDataPayload.website != '') {
                getAPI.put(`organisations/${this.updateDataPayload.id}/`, this.updateDataPayload)
                    .then(response => {
                        if(response.status == 200) {
                            this.getAllOrgs()
                            //let msg = 'Organisation MAJ avec succès!'
                            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                            this.exitOrgUpdateModal = Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.exitOrgUpdateModal = Date.now()
                    })
            }
            else {
                //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgUpdateModal = Date.now()
            }
        },
        getCallToAction (payload) {
            if(payload.action_type == 'activate'||payload.action_type == 'deactivate') {
                this.openDeletionBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            }
            if(payload.action_type == 'view') {
                getAPI.get(`organisations/${payload.id}`,)
                    .then(response => {
                        if(response.status == 200) {
                            let data = response.data
                            this.updateData(data);
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                    })
            }
        },
        catchAction (data) {
            if(data == '#user-group') {
                this.addGroup = true
            }
            else if(data == '#unique-user') {
                this.addUser = true
            }
        },
        checkMail (mail) {
            if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
                return true
            }
            return false
        },
        checkMailList (e) {
            if(e.keyCode === 32) {
                this.listOfEmail += '\n';
            }
            let data = this.listOfEmail.split('\n')
            document.getElementById('mailListContainer').style.borderColor = '#b9f5b9'
            for(let i=0; i<data.length; i++) {
                if(!this.checkMail(data[i])) {
                    document.getElementById('mailListContainer').style.borderColor = '#ff0000'
                    break
                }
            }
            console.log(data)
        },
        getCallToActionForUser (payload) {
            if(payload.name == 0) {
                //let msg = 'Oups ! Vous ne pouvez désactivé le compte administrateur de cette organisation'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            else if(payload.action_type == 'activate'||payload.action_type == 'deactivate') {
                getAPI.put(`accounts/users/change-statut/${payload.id}/`, {
                    is_active: (payload.action_type == 'activate') ? true : false
                }).then(response => {
                    if(response.status == 200) {
                        //let msg = 'Utilisateur MAJ avec succès.'
                        //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                        this.getMembers()
                    }
                    else {
                        //let msg = 'Oups ! something went wrong.'
                        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                }).catch(() => {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                })
            }
        },
        formatDateFromString (value) {
            if (!value) return ''
            const date = new Date(value)
            const day = String(date.getDate()).padStart(2, '0')
            const month = String(date.getMonth() + 1).padStart(2, '0')
            const year = date.getFullYear()
            const hour = String(date.getHours()).padStart(2, '0')
            const minute = String(date.getMinutes()).padStart(2, '0')
            return [day, month, year, hour, minute]
        },
        validatePhoneNumber(phone) {
            // Check if the phone number contains only numeric digits
            return /^\d+$/.test(phone);
        }
    },
    mounted() {
        this.getMembers()
        this.retrieveUserSubscription()
        this.getService()
    }
}
</script>

<style>
/*.cfa-root-content{*/
/*    padding: 2em;*/
/*    !*width: 100%;*!*/
/*    width: calc(100vw - 20em) !important;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
</style>
<style scoped>
/* TAB STYLE */
.tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    /*border-bottom: 1px solid #ccc;*/
}

.tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #333;
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
}

.tab-header ul li.active {
    background-color: #333;
    color: #fff;
}

.tab-body {
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
    /*background-color: #fff;*/
}


</style>
