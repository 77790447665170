<template>
  <!--section 0-->
  <div class="cfa-block p-4" style="box-shadow: none;">
    <div>
      <div class="d-flex align-items-center justify-content-between sfger">
        <h5 class="mb-0">
          {{ $t('preference_logo') }} 
          <!-- <button ref="popoverInfo" type="button" class="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Top popover">Popover on top </button>  -->
          (300px x 150px)
        </h5>
        <a @click="openFileSelector" class="btn cfa-btn-primary d-flex align-items-center" style="font-weight: 400;">
          <i v-if="!uploadPending" class="fa fa-image margin-right-10 d-none d-md-block" aria-hidden="true">&nbsp;</i>
          <span v-if="!uploadPending" class="d-inline">{{ $t('change_app_logo') }}</span>
          <span v-else class="" style="width: 11em;">
            <img width="20" src="@/assets/img/gif/load2.gif" alt="loader" />
          </span>
        </a>
        <input type="file" accept="image/*" ref="fileSelector" @change="uploadImage" style="display: none;">
      </div>
      <section class="text-center py-4  mt-2 d-flex justify-content-center" style="border-radius: 0.5em;">
        <div class="logo-prefer ratio ratio-21x9">
          <img v-if="orgDataFav != null" class="bg-white p-1" style="" :src="orgDataFav"
            alt="logo company" />
          <img v-else class="bg-white p-1" style="" src="@/assets/brand/spec-brand-1.svg"
            alt="logo company" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getAPI, guestAPI } from '@/axios-api.js'
//import { Popover } from "bootstrap";
import { ref } from "vue"
export default {
  name: 'AppLogo',
  components: {
  },
  setup() {
    const popoverInfo = ref(null)
    return {
      popoverInfo
    }
  },
  data() {
    return {
      userData: null,
      alertString: null,
      defaultImage: " /images/defaultImage.png",
      firstname: '...',
      lastname: '...',
      phonePersonal: '...',
      phoneProfessional: '...',
      oldPassword: '',
      silentRefresh: null,
      uploadPending: false,
      role: '',
      primary: "#28a9d1",
      secondary: "#2f3246",
      type: 'HEX', // UI,
      orgDataFav: null,
    }
  },
  methods: {
    changeType() {
      this.type = document.getElementById('selector').value;
    },
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    setPrimaryColor() {
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', this.primary);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    setSecondaryColor() {
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    changeLanguage(obj) {
      localStorage.setItem('language', obj.target.value)
    },
    retrieveUserData() {
      getAPI.get('accounts/users/me')
        .then(response => {
          if (response.status == 200) {
            this.userData = response.data.data
            this.firstname = this.userData.firstname
            this.lastname = this.userData.lastname
            this.role = this.userData.role
            this.phonePersonal = this.userData.phone
            this.phoneProfessional = this.userData.phone;
            this.orgDataFav = this.userData.organisation.favicon;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    tryChangePassword() {
      if (this.oldPassword.length > 2) {
        guestAPI.post('accounts/login',
          {
            email: this.userData.email,
            password: this.oldPassword,
          })
          .then(response => {
            if (response.status == 200) {
              // send password forget mail
              guestAPI.post('accounts/forgot-password', {
                email: this.userData.email,
              })
                .then(response => {
                  if (response.status == 200) {
                    //this.alertString = "Veuillez vérifier votre adresse e-mail pour changer le mot de passe.|alert-yes|6000::"+Date.now()
                  }
                  else {
                    //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                  }
                })
                .catch(() => {
                  //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
                })
              // -------------------------
            }
            else {
              //this.alertString = `Votre mot de passe erroné. Etes-vous ${sessionStorage.firstname} ${sessionStorage.lastname}?|alert-no|6000::`+Date.now()
            }
          })
          .catch(() => {
            //this.alertString = `Votre mot de passe erroné. Etes-vous ${sessionStorage.firstname} ${sessionStorage.lastname}?|alert-no|6000::`+Date.now()
          })
      }
      else {
        //this.alertString = "Veuillez remplir correctement le champ.|alert-no|5000::"+Date.now()
      }
    },
    openFileSelector() {
      this.$refs.fileSelector.click();
    },
    async uploadImage() {
      const file = this.$refs.fileSelector.files[0];
      const formData = new FormData();
      //formData.append('favicon', file);
      console.log('form data', formData);
      const maxSizeInMB = 2; // 2 MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (file) {
        if (file.size > maxSizeInBytes) {
          console.log("File is within the size limit. Ready to upload.");
          this.$swal({
              title: 'Logo d\'Application',
              text: `Veuillez choisir un fichier inferieur a 2MB`,
              type: 'warning',
              showCancelButton: false,
              confirmButtonText: "Fermer"
          });
          return
        } else {
          formData.append('favicon', file);

        }
      }
      this.uploadPending = true
      //return
      //eslint-disable-next-line
      getAPI.put(`organisations/change_favicon/${sessionStorage.organisation}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((payload) => {
        console.log(payload);
        this.orgDataFav = payload.data.data.favicon
        this.alertString = "Profil mis à jour avec succès.|alert-yes|5000::"+Date.now()
        this.silentRefresh = Date.now();
        this.uploadPending = false
      }).catch((err) => {
        this.alertString = "Fichier trop large.|alert-no|5000::"+Date.now()
        this.uploadPending = false
        console.log(err)
      });
    },
    updateUserProfil() {
      if (this.firstname.length > 2 && this.lastname.length > 2 && this.phonePersonal.length > 5) {
        getAPI.put(`accounts/users/${sessionStorage.user}/`, {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phonePersonal,
          role: this.role
        }).then(response => {
          if (response.status == 200) {
            sessionStorage.setItem('firstname', this.firstname)
            sessionStorage.setItem('lastname', this.lastname)
            
            //this.alertString = "Profil mis a jour avec succès|alert-yes|5000::"+Date.now()
            this.silentRefresh = Date.now();
            // this.$router.go(0);
          }
          else {
            //this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
          }
        }).catch(() => {
          //this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
        })
      }
      else {
        //this.alertString = "Veuillez remplir les champs correctement.|alert-no|5000::"+Date.now()
      }
    },
    initPopover () {
      
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
      const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
      console.log(popoverList)
    }
  },
  mounted() {
    this.retrieveUserData()
    //new Popover(this.popoverInfo.value);
  }
}
</script>

<style scoped>
.cfa-page {}

.cfa-block {
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);

  /* border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

.form-group label {
  margin-bottom: 0.5em;
  font-weight: 400;
}
</style>