<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 v-if="active  " class="cfa-section-title">GESTION DES UTILISATEURS ({{userCount}})</h1>
          <h1 v-else class="cfa-section-title">GESTION DES UTILISATEURS</h1>
          <div></div>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
          <div v-if="active">
            <div class="tab">
              <div class="tab-header" style="margin-bottom: 1em;">
                <ul class="tab-ul">
                  <li :class="{ 'active': currentTab === 'user' }" @click="selectTab('user')" class="d-flex badge rounded-pill cfa-tab-btn tab_font"> Utilisateurs</li>
                  <li :class="{ 'active': currentTab === 'service' }" @click="selectTab('service')" class="d-flex badge rounded-pill cfa-tab-btn tab_font"> Service</li>
                  <li :class="{ 'active': currentTab === 'group' }" @click="selectTab('group')" class="d-flex badge rounded-pill cfa-tab-btn tab_font"> Groupes</li>
                </ul>
                <div class="d-flex align-items-center justify-content-end p-1">
                  <button v-if="currentTab === 'user'" @click="this.newModule2=true" class="btn cfa-btn-primary" style="height: 2.7em; margin-right: 15px;">
                    INVITER UN MEMBRE <i class="fa fa-plus">&nbsp;</i>
                  </button>
                  <button v-if="currentTab === 'user'" @click="this.newModule=true" class="btn cfa-btn-primary" style="height: 2.7em;">
                    AJOUTER UN MEMBRE <i class="fa fa-plus">&nbsp;</i>
                  </button>
                  <button v-if="currentTab === 'service'" @click="newService" class="btn cfa-btn-primary" style="height: 2.7em;">
                    NOUVEAU SERVICE <i class="fa fa-plus">&nbsp;</i>
                  </button>
                  <button v-if="currentTab === 'group'" @click="newGroup" class="btn cfa-btn-primary" style="height: 2.7em;">
                    NOUVEAU GROUPE <i class="fa fa-plus">&nbsp;</i>
                  </button>
                </div>
              </div>
              <div class="tab-body" style="border: none;">
                <div v-if="currentTab === 'user'">
                  <Table :columns="userAllColumns" :data="userAll" :columns-action="userAllToListen" @action="getCallToActionForUser" last-cell-width="0" :i="3"></Table>
                </div>
                <div v-if="currentTab === 'service'">
                  <Table :hide-control="(serviceAll.length === 0)" :i="4" :columns="serviceAllColumns" :data="serviceAll" :columns-action="serviceAllToListen" @action="getCallToActionForService" :customized-default-image="true" :last-cell-width="0">
                    <template v-slot:defaultImage>
                      <img src="@/assets/img/gif/information.gif" alt="empty content" style="width: 9em; margin: auto;"/>
                      <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucun service pour le moment !</span>
                    </template>
                  </Table>
                </div>
                <div v-if="currentTab === 'group'">
                  <Table :hide-control="(groupAll.length === 0)" :i="4" :columns="groupAllColumns" :data="groupAll" :columns-action="groupAllToListen" @action="getCallToActionForGroup" :customized-default-image="true" :last-cell-width="0">
                    <template v-slot:defaultImage>
                      <img src="@/assets/img/gif/groups.gif" alt="empty content" style="width: 9em;"/>
                      <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucun groupe !</span>
                    </template>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <div class="card-body p-0 mt-2 mb-0">
            <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <div>
                      Souscrivez à un de nos plans pour bénéficier de cette fonctionnalité !
                    </div>
                  </div>
                  <div class="tab-body" style="border: none;">
                    <div v-if="currentTab === 'user'">
                      <Table :columns="userAllColumns" :data="userAll" :columns-action="userAllToListen"
                        @action="getCallToActionForUser" last-cell-width="0" i-min-width="25em" :i="3"></Table>
                    </div>
                    <div v-if="currentTab === 'service'">
                      <Table :hide-control="(serviceAll.length === 0)" :i="4" :columns="serviceAllColumns"
                        :data="serviceAll" :columns-action="serviceAllToListen" @action="getCallToActionForService"
                        :customized-default-image="true" :last-cell-width="0">
                        <template v-slot:defaultImage>
                          <img src="@/assets/img/gif/information.gif" alt="empty content"
                            style="width: 9em; margin: auto;" />
                          <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_service') }}</span>
                        </template>
                      </Table>
                    </div>
                    <div v-if="currentTab === 'group'">
                      <Table :hide-control="(groupAll.length === 0)" :i="4" :columns="groupAllColumns" :data="groupAll"
                        :columns-action="groupAllToListen" @action="getCallToActionForGroup"
                        :customized-default-image="true" :last-cell-width="0">
                        <template v-slot:defaultImage>
                          <img src="@/assets/img/gif/groups.gif" alt="empty content" style="width: 9em;" />
                          <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">{{ $t('no_groups') }}</span>
                        </template>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <!-- <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
            </div> -->
            <div class="loader-pulse">
                <div class="logo-placeholder">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <span class="loader-text">{{ $t('loading') }}</span>
                </div>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>

    <!-- create -->
    <scalable-modal @callback="addNewOrg" display-mode="flex" :break="exitOrgModal" label="CRÉER UN PLAN"
      uid="cfa-modal-org-create" @close="newModule = false; this.tableData = []; this.inputArray = [{ firstname: '', lastname: '', email: '', phone: '', position: '', service: ''}];" :open="newModule" :advancedControl="true"
      validationButtonText="Ajouter" exitButtonText="Annuler"
      :style="'width: 90em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <div class="tab-form">
          <div class="tab-header-form" style="margin-bottom: 1em;">
            <ul class="tab-ul-form" style="display: flex; justify-content: flex-start; align-items: center; width: 100%;">
              <li :class="{ 'active': currentUserTab === 'singleUser' }" @click="selectPopupTab('singleUser')"
                class="d-flex badge rounded-pill cfa-tab-btn tab_font cba-font"> Utilisateur unique</li>
              <li :class="{ 'active': currentUserTab === 'bulkUser' }" @click="selectPopupTab('bulkUser')"
                class="d-flex badge rounded-pill cfa-tab-btn tab_font cba-font"> Utilisateur groupé</li>
            </ul>
          </div>
        </div>
        <div class="tab-body-form" style="border: none;">
          <div v-if="currentUserTab === 'singleUser'">
            <div class="row w-100 mb-4 mt-inherit data-row" v-for="(inputData, index) in inputArray" :key="index">
              <div class="form-group py-2 ps-0 pe-0 pe-md-3 col-2">
                <p class="mb-2"><strong>{{ $t('Name') }}</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="text" v-model="inputData.firstname">
                </div>
              </div>
              <div class="form-group py-2 pe-md-3 col-2 px-0">
                <p class="mb-2"><strong>{{ $t('f_name') }}</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="text" v-model="inputData.lastname">
                </div>
              </div>
              <div class="form-group py-2 ps-0 pe-0 pe-md-3 col-2">
                <p class="mb-2"><strong>{{ $t('email') }}</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="email" v-model="inputData.email">
                </div>
              </div>
              <div class="form-group py-2 pe-md-3 col-2 px-0">
                <p class="mb-2"><strong>{{ $t('PHONE') }}</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="text" v-model="inputData.phone">
                </div>
              </div>
              <div class="form-group py-2 pe-md-3 col-3">
                <p class="mb-2"><strong>{{ $t('assign_to') }}</strong></p>
                <select @change="parseUserList" v-model="inputData.service" class="form-control">
                  <option disabled selected value="">{{ $t('select_service') }}</option>
                  <option v-for="service in staticService" :key="service.id" :value="service.name">{{
                    splitGroup(service.name) }}</option>
                  <!-- <option v-for="service,i in staticService" :key="i">{{ service.name }}</option> -->
                </select>
              </div>
              <div class="form-group py-2 pe-md-3 col-1 mt-3">
                <button class="btn btn-multiple me-2" @click="addRow(index)"
                  v-if="index === inputArray.length - 1">+</button>
                <button class="btn btn-danger" @click="removeRow(index)" v-if="inputArray.length > 1">-</button>
              </div>
            </div>
          </div>
          <div v-if="currentUserTab === 'bulkUser'">
            <div class="row w-100 data-row">
              <div class="form-group py-3 col-3">
                <p class="mb-2"><strong>{{ $t('upload_list') }}</strong></p>
                <div class="">
                  <input class="form-control" type="file" @change="handleFileUpload" accept=".csv">
                  <!-- Download Template -->
                  <a
                    href="/template.csv"
                    class="btn btn-link mt-2"
                    download="Template.csv"
                  >
                    {{ $t('download_csv')}}
                  </a>
                </div>
              </div>
              <div class="form-group py-3 offset-lg-8 col-1" v-if="tableData.length">
                <button class="btn m-btn2 cfa-btn-secondary" @click="resetTableData">Reset</button>
              </div>
            </div>
            <div class="row w-100 data-row" v-if="tableData.length > 0" style='margin-left:10px;max-height:230px; overflow-y:scroll;'>
              <table class="table bulk-table mt-0">
                <thead>
                  <tr>
                    <th class="table-header" v-for="(value, index) in tableData[0]" :key="index">{{ index }}</th>
                    <!-- <th>Selectionner un service</th> -->
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                    <td v-for="(value, key) in row" :key="key">{{ value }}</td>
                    <!-- <td>
                      <select @change="parseUserList"  class="form-control">
                        <option disabled selected value="">{{ $t('select_service') }}</option>
                        <option v-for="service in staticService" :key="service.id" :value="service.name">{{
                          splitGroup(service.name) }}</option>
                        
                      </select>
                    </td> -->
                    <td><button class="btn btn-danger" @click="deleteRow(rowIndex)"><i class="fa fa-trash"></i></button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <scalable-modal @callback="addNewOrg2" display-mode="flex" :break="exitOrgModal2" label="CRÉER UN PLAN"
      uid="cfa-modal-org-create2" @close="newModule2 = false" :open="newModule2" :advancedControl="true"
      validationButtonText="Inviter" exitButtonText="Annuler"
      :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <div class="row w-100 mt-3">
          <div v-if="modeLine" class="px-0">
            <div class="form-group py-3 pe-0">
              <p class="mb-2"><strong>EMAIL</strong></p>
              <input class="form-control" type="email" v-model="mew_member_email">
            </div>
            <div class="form-group py-3 pe-0">
              <p class="mb-2"><a class="switch-link" @click.prevent="modeLine = false" href="">
                {{ $t('cascade ') }}</a></p>
            </div>
          </div>
          <div v-else class="px-0">
            <!-- <div class="form-group py-3 pe-0">
              <p class="mb-2"><strong>{{ $t('user_list') }}</strong></p>
              <textarea id="userListEditor" @input="parseUserList2" v-model="userListText2" class="form-control"
                style="min-height: 168px; border-width: 3px;"></textarea>
              <small>{{ $t('each_user_line') }}</small>
            </div> -->
            <div class="row w-100 data-row">
              <div class="form-group py-3 col-12">
                <p class="mb-2"><strong>{{ $t('upload_list') }}</strong></p>
                <div class="">
                  <input class="form-control" type="file" @change="handleFileUpload" accept=".csv">
                  <!-- Download Template -->
                  <a
                    href="/template.csv"
                    class="btn btn-link mt-2"
                    download="Template.csv"
                  >
                    {{ $t('download_csv')}}
                  </a>
                </div>
              </div>
              <div class="form-group py-3 offset-lg-8 col-1" v-if="tableData.length">
                <button class="btn m-btn2 cfa-btn-secondary" @click="resetTableData">Reset</button>
              </div>
              <div class="row w-100 col-12 data-row" v-if="tableData.length > 0" style='margin-left:10px;max-height:230px; overflow-y:scroll;'>
                <table class="table bulk-table mt-0">
                  <thead>
                    <tr>
                      <th class="table-header" v-for="(value, index) in tableData[0]" :key="index">{{ index }}</th>
                      <!-- <th>Selectionner un service</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                      <td v-for="(value, key) in row" :key="key">{{ value }}</td>
                      <!-- <td>
                        <select @change="parseUserList"  class="form-control">
                          <option disabled selected value="">{{ $t('select_service') }}</option>
                          <option v-for="service in staticService" :key="service.id" :value="service.name">{{
                            splitGroup(service.name) }}</option>
                          
                        </select>
                      </td> -->
                      <td><button class="btn btn-danger" @click="deleteRow(rowIndex)"><i class="fa fa-trash"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group py-3 pe-0">
              <p class="mb-2"><a class="switch-link" @click.prevent="modeLine = true" href="">
                {{ $t('single_user') }}</a></p>
            </div>
          </div>
          <div class="form-group py-3" v-if="modeLine">
            <p class="mb-2"><strong>{{ $t('assign_to') }}</strong></p>
            <select @change="parseUserList2" v-model="service_id" class="form-control">
              <option disabled selected value="">{{ $t('select_service') }}</option>
              <option v-for="service in staticService" :key="service.id" :value="service.id">{{ splitGroup(service.name)
                }}</option>
              <!-- <option v-for="service,i in staticService" :key="i">{{ service.name }}</option> -->
            </select>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- update user -->
    <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN"
      uid="cfa-modal-org-update" @close="openUpdateOrg = false" :open="openUpdateOrg" :advancedControl="true"
      validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex"
      :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; '">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-if="tempUpdateID == null" class="w-100">
          <div class="row w-100 m-inherit">
            <div class="form-group py-2 ps-0 pe-0 pe-md-3 col-6">
              <p class="mb-2"><strong>{{ $t('Name') }}</strong></p>
              <div class="d-flex flex-row">
                <input class="form-control" type="text" v-model="updateDataPayload.firstname">
              </div>
            </div>
            <div class="form-group py-2 col-6 px-0">
              <p class="mb-2"><strong>{{ $t('f_name') }}</strong></p>
              <div class="d-flex flex-row">
                <input class="form-control" type="text" v-model="updateDataPayload.lastname">
              </div>
            </div>
          </div>

          <div class="row w-100 m-inherit">
            <div class="form-group py-3 px-0">
              <p class="mb-2"><strong>{{ $t('PHONE') }}</strong></p>
              <input class="form-control" type="text" v-model="updateDataPayload.phone">
            </div>
          </div>

          <div class="row w-100 m-inherit">
            <div class="form-group py-3 px-0">
              <p class="mb-2"><strong>{{ $t('email') }}</strong></p>
              <input class="form-control" type="email" v-model="updateDataPayload.email">
            </div>
          </div>

          <div class="row w-100 m-inherit">
            <div class="form-group py-3 px-0">
              <p class="mb-2"><strong>SERVICE</strong></p>
              <!-- <input class="form-control" type="email" v-model="updateDataPayload.service.name" placeholder="this.splitGroup(updateDataPayload.service.name)"> -->
              <select class="form-control" v-model="userUpdateServiceId">
                <option selected disabled value="">{{ $t('select_new_service') }}</option>
                <option v-for="service in staticService" :key="service.id" :value="service.id">{{ service.name }}
                </option>
              </select>
            </div>
          </div>


        </div>
        <div v-if="waitingAPIResponse">
          <div class="cfa-page">
            <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
              <!-- <div class="preloader_center d-flex flex-column">
                <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
              </div> -->
              <div class="loader-pulse">
                <div class="logo-placeholder">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <span class="loader-text">{{ $t('loading') }}</span>
                </div>
            </div>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <scalable-modal @callback="confirmUpdateServ" :break="exitOrgUpdateModal2" label="CRÉER UN PLAN"
      uid="cfa-modal-service-update" @close="openUpdateOrg2 = false" :open="openUpdateOrg2" :advancedControl="true"
      validationButtonText="Enregistrer" exitButtonText="Annuler" display-mode="flex"
      :style="'width: 40em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-if="tempUpdateID == null" class="w-100">
          <div class="row w-100 m-inherit">
            <div class="form-group px-0">
              <p class="mb-2"><strong>{{ $t('Service') }}</strong></p>
              <select class="form-control" v-model="newServ">
                <option selected disabled value="">{{ $t('select_new_service') }}</option>
                <option v-for="service in staticService" :key="service.id" :value="service.id">{{ service.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- delete -->
    <scalable-modal @callback="confirmDeletion1" :break="exitServiceModalDel" :notifyTaskDone="callbackPayload"
      :uid="'general-deletion-modal'" @close="openDeletionBox = false" :open="openDeletionBox" :advancedControl="true"
      validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex"
      :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>{{ $t('delete_consent') }} <br /><span style="color: red;">{{ $t('delete_consent_red') }}</span></p>
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 pe-md-2">
            <p class="mb-2"><strong>{{ $t('title_confirmation') }}</strong></p>
            <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
          </div>
        </div>
      </template>
    </scalable-modal>
    <scalable-modal @callback="confirmDeletionService" :break="exitServiceModalDel" :notifyTaskDone="callbackPayload"
      :uid="'general-deletion-modal-for-service'" @close="openDeletionBox1 = false" :open="openDeletionBox1"
      :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex"
      :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>{{ $t('delete_service') }} <span style="color: red;">{{ $t('delete_consent_red') }}</span>
        </p>
        <div class="form-group py-2 col-12 px-0">
          <p class="mb-2"><strong>Entrez le nom du service</strong></p>
          <div class="d-flex flex-row">
            <input class="form-control" type="text" v-model="deleteText" >
          </div>
        </div>
      </template>
    </scalable-modal>


    <!-- edit -->
    <scalable-modal @callback="confirmServiceUpdate" :break="exitServiceUpdate" :notifyTaskDone="callbackPayload"
      :uid="'general-edit-modal-for-service'" @close="openServiceUpdate = false" :open="openServiceUpdate"
      :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" displayMode="flex"
      :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <div class="form-group py-2 col-12 px-0">
          <p class="mb-2"><strong>{{ $t('Service') }}</strong></p>
          <div class="d-flex flex-row">
            <input class="form-control" type="text" v-model="servicePayload.name">
          </div>
        </div>
        <div v-if="!waitingAPIResponse2" class="w-100">
          <div class="form-group py-2">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <p class="mb-2"><strong>{{ $t('add_users') }}</strong></p>
              <button @click="selectAll" class="btn cfa-btn-primary">{{ this.selectedUser.length == this.userList.length ? $t('deselect_all') : $t('select_all') }}</button>
            </div>
            <div v-for="(user) in paginatedUsers" :key="user.id" class="d-flex align-items-center user-row row-user"
              :style="(user.role.name === 'manager') ? 'background-color: #cef1f3;' : ''">
              
              <input type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="selectedUser">
              <label :title="user.email" :for="'permission-' + user.id"
                class="ms-2 pe-2 w-100 d-flex align-items-center justify-content-between">
                <div>
                  <span>{{ user.firstname || 'INVITÉ' }} {{ user.lastname }}</span> 
                  <span style="color: red; margin-left:2px" v-if="!user.email_verified_at">(Non verifie)</span> - <span class="badge text-bg-warning">{{ (user.service ? user.service.name.split("_")[0] : this.getDefaultService?.name)}}</span>
                </div>
                <img :src="user.image_url || defaultImage" style="width: 2em;" />
              </label>
            </div>
            <nav class="w-100">
              <ul class="pagination justify-content-end mt-3">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="btn cfa-btn-light" @click="prevPage" :disabled="currentPage === 1">Précédent</button>
                </li>

                <li class="d-flex justify-content-center align-items-center">
                  <span>{{ currentPage }} / {{ totalPages }}</span>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <button class="btn cfa-btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </template>
    </scalable-modal>


    <!-- create -->
    <scalable-modal @callback="confirmNewGroup" :break="exitNewGroupModal" label="CRÉER UN PLAN"
      uid="cfa-modal-phish-group-create" @close="openNewGroup = false" :open="openNewGroup" :advancedControl="true"
      validationButtonText="Enregistrer les modifications" exitButtonText="Annuler" displayMode="flex"
      :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-if="!waitingAPIResponse2" class="row w-100">
          <div class="form-group py-2 px-0">
            <p class="mb-2"><strong>{{ $t('group_name') }}</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control w-100" type="text" v-model="updateGroupPayload.name">
            </div>
          </div>
        </div>

        <div v-if="!waitingAPIResponse2" class="w-100">
          <div class="form-group py-2">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <p class="mb-2"><strong>{{ $t('add_users') }}</strong></p>
              <button @click="selectAll" class="btn cfa-btn-primary">{{ this.selectedUser.length == this.userList.length ? $t('deselect_all') : $t('select_all') }}</button>
            </div>
            <div v-for="(user) in paginatedUsers" :key="user.id" class="d-flex align-items-center user-row row-user"
              :style="(user.role.name === 'manager') ? 'background-color: #cef1f3;' : ''">
              <input v-if="user.email_verified_at != null" type="checkbox" :id="'permission-' + user.id"
                :value="user.id" v-model="selectedUser">
              <input v-else type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="selectedUser">
              <label :title="user.email" :for="'permission-' + user.id"
                class="ms-2 pe-2 w-100 d-flex align-items-center justify-content-between">
                <div>
                  <span>{{ user.firstname || 'INVITÉ' }} {{ user.lastname }}</span> 
                  <span style="color: red; margin-left:2px" v-if="!user.email_verified_at">(Non verifie)</span>
                </div>
                <img :src="user.image_url || defaultImage" style="width: 2em;" />
              </label>
            </div>
            <!-- Pagination Controls -->
            <nav class="w-100">
              <ul class="pagination justify-content-end mt-3">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <!-- <button class="page-link" @click="prevPage">Previous</button> -->
                  <button class="btn cfa-btn-light" @click="prevPage" :disabled="currentPage === 1">Précédent</button>
                </li>
                <!-- <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                  <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li> -->
                <li class="d-flex justify-content-center align-items-center">
                  <span>{{ currentPage }} / {{ totalPages }}</span>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <!-- <button class="page-link" @click="nextPage">Next</button> -->
                  <button class="btn cfa-btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div v-if="waitingAPIResponse2" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
            <!-- <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
            </div> -->
            <div class="loader-pulse">
                <div class="logo-placeholder">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <span class="loader-text">{{ $t('loading') }}</span>
                </div>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- create -->
    <!-- <scalable-modal @callback="confirmNewService" :uid="'cfa-modal-service-create-new'" :break="exitNewServiceModal" label="CRÉER UN PLAN" @close="openNewService=false" :open="openNewService" :advancedControl="true" validationButtonText="Ajouter ce service" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <div class="form-group py-2 px-0 w-100 mb-3">
          <p class="mb-2"><strong>SERVICE</strong></p>
          <div class="d-flex flex-row">
            <input class="form-control w-100" type="text" placeholder="Saisissez le nom du service à ajouter" v-model="service_name" >
          </div>
        </div>
      </template>
    </scalable-modal> -->

    <scalable-modal @callback="confirmNewService" :uid="'cfa-modal-service-create-new'" :break="exitNewServiceModal"
      label="CRÉER UN PLAN" @close="openNewService = false" :open="openNewService" :advancedControl="true"
      validationButtonText="Ajouter ce service" exitButtonText="Annuler" displayMode="flex"
      :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <div class="form-group py-2 px-0 w-100 mb-3">
          <p class="mb-2"><strong>{{ $t('service') }}</strong></p>
          <div class="d-flex flex-row">
            <input class="form-control w-100" type="text" placeholder="Saisissez le nom du service à ajouter"
              v-model="service_name">
          </div>
        </div>
        <div v-if="!waitingAPIResponse2" class="w-100">
          <div class="form-group py-2">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <p class="mb-2"><strong>{{ $t('add_users') }}</strong></p>
              <button @click="selectAll" class="btn cfa-btn-primary">{{ this.selectedUser.length == this.userList.length ? $t('deselect_all') : $t('select_all') }}</button>
            </div>
            <div v-for="(user) in paginatedUsers" :key="user.id" class="d-flex align-items-center user-row row-user"
              :style="(user.role.name === 'manager') ? 'background-color: #cef1f3;' : ''">
              
              <input type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="selectedUser">
              <label :title="user.email" :for="'permission-' + user.id"
                class="ms-2 pe-2 w-100 d-flex align-items-center justify-content-between">
                <div>
                  <span>{{ user.firstname || 'INVITÉ' }} {{ user.lastname }}</span> 
                  <span style="color: red; margin-left:2px" v-if="!user.email_verified_at">(Non verifie)</span> - <span class="badge text-bg-warning">{{ (user.service ? user.service.name.split("_")[0] : this.getDefaultService?.name)}}</span>
                </div>
                <img :src="user.image_url || defaultImage" style="width: 2em;" />
              </label>
            </div>
            <nav class="w-100">
              <ul class="pagination justify-content-end mt-3">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="btn cfa-btn-light" @click="prevPage" :disabled="currentPage === 1">Précédent</button>
                </li>

                <li class="d-flex justify-content-center align-items-center">
                  <span>{{ currentPage }} / {{ totalPages }}</span>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <button class="btn cfa-btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </template>
    </scalable-modal>


    
    <scalable-modal @callback="confirmUpdateGroup" :break="exitGroupUpdateModal" label="CRÉER UN PLAN"
      uid="cfa-modal-phish-group-update" @close="openUpdateGroup = false" :open="openUpdateGroup" :advancedControl="true"
      validationButtonText="Enregistrer les modifications" exitButtonText="Annuler" displayMode="flex"
      :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-if="!updateGroupStatusRequest" class="row w-100">
          <div class="form-group py-2 px-0">
            <p class="mb-2"><strong>{{ $t('group_name') }}</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control w-100" type="text" v-model="updateGroupData.name">
            </div>
          </div>
        </div>

        <div v-if="!updateGroupStatusRequest" class="w-100">
          <div>
            <div class="form-group py-2" style="max-height: 350px;">
              <p class="mb-2"><strong>{{ $t('group_users') }}</strong></p>

              <!-- User List with Pagination -->
              <div v-for="user in paginatedUsers" :key="user.id" 
                  class="d-flex align-items-center user-row row-user"
                  :style="(user.role.name === 'manager') ? 'background-color: #cef1f3;' : ''">
                
                <!-- <input v-if="user.email_verified_at != null" type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="flattenedIds"> -->
                <input type="checkbox" :id="'permission-' + user.id" :value="user.id" v-model="flattenedIds"  >
                
                <label :title="user.email" :for="'permission-' + user.id"
                      class="ms-2 pe-2 w-100 d-flex align-items-center justify-content-between">
                  <span>{{ user.firstname || 'INVITÉ' }} {{ user.lastname }}</span>
                  <img :src="user.image_url || defaultImage" style="width: 2em;" />
                </label>
              </div>
            </div>
            <!-- Pagination Controls -->
            <nav class="w-100">
              <ul class="pagination justify-content-end mt-3">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <!-- <button class="page-link" @click="prevPage">Previous</button> -->
                  <button class="btn cfa-btn-light" @click="prevPage" :disabled="currentPage === 1">Précédent</button>
                </li>
                <!-- <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                  <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li> -->
                <li class="d-flex justify-content-center align-items-center">
                  <span>{{ currentPage }} / {{ totalPages }}</span>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <!-- <button class="page-link" @click="nextPage">Next</button> -->
                  <button class="btn cfa-btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div v-if="updateGroupStatusRequest" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 40vh">
            <!-- <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">{{ $t('loading') }}</p>
            </div> -->
            <div class="loader-pulse">
                <div class="logo-placeholder">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <span class="loader-text">{{ $t('loading') }}</span>
                </div>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <div class="gPopupZone">
      <scalable-modal uid="cfa-modal-004" display-mode="flex" @close="addGroup = false" :open="addGroup"
        :advancedControl="true" validationButtonText="Ajouter la list d'utilisateur" exitButtonText="Annuler">
        <template v-slot:content>
          <form action="" method="post" enctype="multipart/form-data" style="width: 100%;">
            <div class="w-100 mt-3">
              <div class="form-group py-3 pe-0">
                <p class="mb-2"><strong>{{ $t('choose_organization') }}</strong></p>
                <custom-select :options="organisation_plain_list" v-model="selectedOrg"
                  @input="getOrgSelected"></custom-select>
              </div>
            </div>
            <div id="collapse-day-details" class="collapse show" aria-labelledby="headingOne"
              data-parent="#accordionExample">
              <div class="card-body _p0">
                <div class="form-group ">
                  <span class="d-flex align-items-center justify-content-between">
                    <label class="control-label" for="inputPassnew">
                      {{ $t('field_below') }}
                    </label>
                  </span>
                  <br>
                  <div style="min-height: 15vh; width: 100%;" id="studentList">
                    <div class="input-group">
                      <textarea id="mailListContainer" @keyup="checkMailList($event)" v-model="toAdd_email_list"
                        style="min-height: 25em;" class="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </template>
      </scalable-modal>
      <scalable-modal uid="cfa-modal-005 " display-mode="flex" @close="addUser = false" :open="addUser"
        :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler"
        :style="'width: 40%; height: auto;'">
        <template v-slot:content>
          <div class="form-group w-100">
            <div class="w-100 mt-3">
              <div class="form-group py-3 pe-0">
                <p class="mb-2"><strong>{{ $t('choose_organization') }}</strong></p>
                <custom-select :options="organisation_plain_list" v-model="selectedOrg"
                  @input="getOrgSelected"></custom-select>
              </div>
            </div>
            <br>
            <span class="d-flex align-items-center justify-content-between">
              <label class="control-label" for="inputPassnew">{{ $t('add-email') }}</label>
            </span>
            <div style="min-height: 15vh" id="studentList">
              <div class="input-group">
                <input id="inputPassnew" class="form-control" name="inputPassnew" type="email" v-model="toAdd_email">
              </div>
            </div>
          </div>
        </template>
      </scalable-modal>
    </div>
    <screen-loader v-if="isLoading"/>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import { getAPI } from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";
//import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { handleApiErrors } from '@/utils/handleApiErrors';
import ScreenLoader from "@/components/shared/ScreenLoader.vue";


export default {
  name: 'OrganisationView',
  components: {
    //VueDatePicker,
    CFAFooter,
    CFANav,
    ScalableModal,
    Table,
    ScreenLoader
  },
  data() {
    return {
      currentUserTab: 'singleUser',
      openNewService: false,
      exitNewServiceModal: null,
      modeLine: true,
      mew_member_email: '',
      currentTab: 'user',
      active:null,
      newModule: false,
      newModule2: false,
      exitOrgModal: null,
      exitOrgModal2: null,
      alertString: '',
      waitingAPIResponse2: true,
      waitingAPIResponse: true,
      allOrganisation: [],
      service_name: "",

      org_name: '',
      org_numSiret: '',
      org_activity: '',
      org_city: '',
      org_country: '',
      org_address: '',
      org_tel: '',
      org_email: '',
      org_website: '',
      org_logo: '',

      currentTabIndex: 0,
      userList: [],
      selectedUser: [],
      credit_mail: '•••',
      credit_sms: '•••',
      total_credit_mail: '•••',
      total_credit_sms: '•••',
      updateGroupData: {},
      updateGroupStatusRequest: true,
      flattenedIds: [],
      exitGroupUpdateModal: null,
      groupAllData: [],
      groupeApiWaiting: false,
      groupApiWaitingData: [],
      groupeApiTemplateWaiting: false,
      groupApiTemplateWaitingData: [],

      openDeletionBox: false,
      tempDeleteID: '',
      tempDeleteName: '',
      name_check: '',
      callbackPayload: null,
      exitOrgModalDel: null,
      updateDataPayload: null,
      exitOrgUpdateModal: null,
      openUpdateOrg: false,
      tempUpdateID: null,

      exitOrgUpdateModal2: null,
      openUpdateOrg2: false,

      openNewGroup: false,
      exitNewGroupModal: null,

      orgs: [],
      orgsColumns: ['NOM', 'ACTIVITÉ', 'ADRESSE', 'PHONE', 'MAIL', 'WEBSITE', 'ACTIONS'],
      orgsToListen: ['ACTIONS'],

      userIndividuals: [],
      userIndividualsColumns: ['n°', 'Nom & Prénoms', 'Téléphone', 'Email', 'CyberScore', 'Progression', 'Fin Souscription', 'Actions'],
      userIndividualsToListen: ['Actions'],

      userAll: [],
      userAllColumns: ['Nom & Prénoms', 'Service', 'Status', 'Téléphone', 'Email', 'Actions'],
      userAllToListen: ['Actions'],

      groupAll: [],
      groupAllColumns: ['n°', 'Nom', 'Utilisateurs', 'Denière modification', 'Actions'],
      groupAllToListen: ['Actions'],

      serviceAll: [],
      serviceAllColumns: ['n°', 'Intitulé', "Nombre d'Utilisateur", 'Créé le', 'Actions'],
      serviceAllToListen: ['Actions'],
      staticService: [],

      addUser: false,
      addGroup: false,
      toAdd_email_list: [],
      toAdd_email: null,

      selectedOrg: null,
      organisation_plain_list: [],
      orgData: null,
      isOrg: true,

      userEmail: null,
      dateEnd: null,
      subscriptionData: [],
      subscriptionData2: [],
      service_id: '',

      emailList: '',
      areAllEmailsValid: false,
      validEmailList: [],
      userCount: 0,
      defaultImage: "/images/defaultImage.png",

      updateGroupPayload: null,
      openUpdateGroup: false,

      openDeletionBox1: false,
      exitServiceModalDel: null,
      tempDeleteID1: null,

      remain_days: "",
      updateServiceId: "",

      payloadUser: [],
      payloadUserId: "",
      newServ: '',

      openServiceUpdate: false,
      exitServiceUpdate: null,
      tempDeleteID2: [],

      servicePayload: [],

      userListText: '',
      userListe: [],
      postData: [],
      postData2: [],
      userListText2: '',
      userListe2: [],

      inputArray: [
        {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          position: localStorage.organisation,
          service: ''
        }
      ],
      tableData: [],
      userUpdateServiceId: null,
      currentPage: 1,
      pageSize: 5,  // Number of users per page
      deleteText: '',
      isLoading: true
    }
  },
  computed: {
    filteredService() {
      return this.staticService.slice(1);
    },
    getDefaultService() {
      if (this.staticService.length > 0 && this.orgData) {
        return this.staticService.find(serv => serv.name == this.orgData.name)
      }
      return {}
    },
    totalPages() {
      return Math.ceil(this.userList.length / this.pageSize);
    },
    paginatedUsers() {
      let start = (this.currentPage - 1) * this.pageSize;
      return this.userList.slice(start, start + this.pageSize);
    }
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    handleFileUpload(event) {
      const fileInput = event.target; // Reference to the file input
      const file = event.target.files[0];
      console.log(file)
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csv = e.target.result;
          const lines = csv.split("\n");
          const headers = lines[0].split(",");
          const data = [];
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split(",");
            if (line.length === headers.length) {
              const obj = {};
              for (let j = 0; j < headers.length; j++) {
                if(j==5 && line[j] == '') {
                  obj[headers[j]] = 'DevFrontCyberSpector';
                }else{
                  obj[headers[j]] = line[j];
                }
              }
              data.push(obj);
            }
          }
          this.tableData = data;
          fileInput.value = "";
          try {
            this.cleanTableData()
          } catch (error) {
            this.tableData = []
            this.alertString = `${error.message}|alert-no|5000::`+Date.now()
          }
          console.log(this.tableData)
        };
        reader.readAsText(file);
      }
    },
    resetTableData() {
      this.tableData = [];
    },
    deleteRow(rowIndex) {
      if(confirm('Are you sure you want to perform this action?')){
        this.tableData.splice(rowIndex, 1);
      }
    },
    addRow(index) {
      this.inputArray.splice(index + 1, 0, {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        position: localStorage.organisation,
        service: ''
      });
    },
    removeRow(index) {
      if (this.inputArray.length > 1) {
        this.inputArray.splice(index, 1);
      }
    },
    parseUserList() {
      this.userListe = [];

      const lines = this.userListText.split('\n');

      lines.forEach(line => {
        const userInfo = line.split(',');

        const user = {
          email: userInfo[0] ? userInfo[0].trim() : '',
          lastname: userInfo[1] ? userInfo[1].trim() : '',
          firstname: userInfo[2] ? userInfo[2].trim() : '',
          phone: userInfo[3] ? userInfo[3].trim() : '',
          position: userInfo[4] ? userInfo[4].trim() : '',
          service: this.service_id
        };

        this.userListe.push(user);
      });

      this.postData = { users: this.userListe };
      console.log("Utilisateurs: ", this.userListe)
    },
    parseUserList2() {
      this.userListe2 = [];

      const lines = this.userListText2.split('\n');

      lines.forEach(line => {
        this.userListe2.push(line);
      });

      this.postData2 = this.userListe2
    },
    convertDate(dateString, dateOnly = false) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString();
      //const formattedTime = date.toLocaleTimeString();
      if (!dateOnly) {
        return `<b>${formattedDate}</b>`;
      }
      return `<b>${formattedDate}</b>`;
    },
    splitGroup(group) {
      const parties = group.split("_");
      const gpName = parties[0];
      return gpName;
    },
    selectAll() {
      if (this.selectedUser.length > 0 && this.selectedUser.length == this.userList.length) {
        this.selectedUser = []
      } else {
        this.selectedUser = this.userList.map(user => user.id);
      }
    },
    async getMembers() {
      this.waitingAPIResponse = true
      this.waitingAPIResponse2 = true;

      let org = localStorage.getItem('organisation')
      await getAPI.get(`accounts/users/organisation/${org}`)
        .then(response => {
          //console.log(response)
          if (response.status === 200) {
            console.log("Members: ", response.data.data);
            this.userList = response.data.data;
            let users = response.data.data;
            this.userCount = users.length;
            this.userAll = []
            for (let i = 0; i < users.length; i++) {
              let row = users[i]
              let email = `<a style="color: #175ccc; font-weight: 400;" class="email" href="mailto:${row.email.toLowerCase()}">${row.email.toLowerCase()}</a>`
              let btn = `<div class="d-flex align-items-center">`;
              if (row.role.name !== 'manager') {
                //btn += (row.email_verified_at != null) ? ((row.is_active) ? `<button title="DESACTIVER L'ACCES" btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-eye"> &nbsp;</i>ACTIF&nbsp;&nbsp;</button>` : `<button title="REACTIVER L'ACCES" btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-eye-slash"></i> INACTIF</button>`) : `<button title="EN ATTENTE" btn-id="${row.id}" btn-action="empty" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-dot-circle"></i> PENDING&nbsp;</button>`;
                // btn += `<button title="SUPPRIMER L'UTILISATEUR DE VOTRE ORGANISATION" btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash">&nbsp;</i></button>`;
                btn += `<button title="MODIFIER L'UTILISATEUR" btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-primary btn-action cfa-btn-light"><i class="fa fa-edit">&nbsp;</i></button>`;
                btn += `<button title="MODIFIER LE SERVICE" btn-id="${row.id}" btn-action="changeService" class="ms-2 btn btn-action cfa-btn-light"><i class="fa fa-clipboard">&nbsp;</i></button>`;
                btn += `<button title="REENVOYER L'INVITATION" btn-id="${row.id}" btn-action="re_invite" class="ms-2 btn btn-primary- btn-action cfa-btn-light"><i class="fa fa-envelope-o">&nbsp;</i></button>`;
                btn += `<button title="${row.is_active ? `DESACTIVER L'UTILISATEUR` : `ACTIVER L'UTILISATEUR` }" btn-id="${row.id}" btn-action="desactivate" class="ms-2 btn btn-primary- btn-action cfa-btn-light ${row.is_active ? `btn-act-deactivate` : `btn-act-activate` }"><i class="fa fa-user-slash">&nbsp;</i></button>`;
                //: `<button title="INVITATION ACCEPTÉE" btn-id="${row.id}" btn-action="nothing" class="ms-2 btn btn-primary- btn-action cfa-btn-light"><i class="fa fa-check">&nbsp;</i></button>`;
                btn += `</div>`;
              }
              
              else {
                btn += ``;
                btn += `</div>`;
              }
              localStorage
              let name = (row.firstname || 'PENDING') + ' ' + (row.lastname || 'INVITE');
              let status = (row.is_active == false) ? `
                                  <span class="badge rounded-pill badge-danger- cfa-btn-secondary">NON VERIFIE&nbsp;</span>
                              ` : (row.type_account == 'organisation') ? `
                                  <span class="badge rounded-pill badge-danger- cfa-btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;MANAGER&nbsp;&nbsp;&nbsp;</span>
                              ` : `
                                  <span class="badge rounded-pill badge-danger- cfa-btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;MEMBRE&nbsp;&nbsp;&nbsp;</span>
                              `;
              let avatar = (row.image_url != null) ? `<div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center"><img width="25" height="25" src="${row.image_url}" alt="avatar"><div><span class="d-none d-md-flex">${name}&nbsp;</span>  </div></div>` :
                `<div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center"><img width="25" height="25" src="${this.defaultImage}" alt="avatar"><div><span class="d-none d-md-flex">${name}&nbsp;</span>  </div></div>`

                let service = `
                <span class="badge rounded-pill cfa-border-primary cfa-primary">&nbsp;&nbsp;&nbsp;&nbsp;${(this.staticService.length > 0) ? (row.service ? row.service.name : this.getDefaultService?.name) : ''}&nbsp;&nbsp;&nbsp;</span>
                `;
                let phone = `<div class="text-center">${row.phone ?? "-" }</div>`
                //console.log(`Users: ${i}`, row);
              // classify and add to array
              this.userAll.unshift({ 'n°': (i + 1), 'Status': status, 'Nom & Prénoms': avatar, 'Service': this.splitGroup(service), 'Email': email, 'Téléphone': phone || '...', 'CyberScore': 0, 'Fin Souscription': 'undefined', 'Actions': btn, name: row.email, id: row.id })
            }
            this.isLoading = false
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            this.isLoading = false

          }
          setTimeout(() => {
            this.waitingAPIResponse = false
            this.waitingAPIResponse2 = false;
          }, 2000);
        })
        .catch(error => {
          this.isLoading = false
          if (error.response) {
            //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false;
          this.waitingAPIResponse2 = false;
        })
    },
    // getServiceUsers (service_id) {
    //   getAPI.get(`services/get_users?service=${service_id}`)
    //   .then(response => {
    //     if(response.status === 200) {
    //       let users = response.data.data
    //       let nbrUsers = users.length

    //       console.log("Le nombre d'utilisateurs pour le service :", service_id, " est: ", nbrUsers);

    //       //let msg = 'Données chargés avec succès !'
    //       //this.alertString = `${msg}|alert-yes|5000::`+Date.now()

    //       return nbrUsers;
    //     }
    //     else {
    //       //let msg = 'Oups ! something went wrong.'
    //       //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //     }
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
    //     }
    //     else if (error.request) {
    //       //let msg = 'The request was made but no response was received. Please check your network.'
    //       //this.alertString = `${msg}|alert-no|8000::`+Date.now()
    //     }
    //     else {
    //       //let msg = 'Oups ! something went wrong.'
    //       //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //     }
    //   })
    // },
    // getService () {
    //   this.waitingAPIResponse = true
    //   this.waitingAPIResponse2 = true;
    //   getAPI.get(`services/?organisation=${localStorage.organisation}`)
    //   .then(response => {
    //     if (response.status === 200) {
    //       let services = response.data.data;

    //       this.staticService = services;
    //       this.serviceAll = [];

    //       for (let i = 0; i < services.length; i++) {
    //         let row = services[i];

    //         this.getServiceUsers(row.id)
    //         // nbrUsers.push(...user);

    //         let label = `
    //             <span style="min-width: 10em !important;" class="badge rounded-pill badge-danger- cfa-btn-secondary">${row.name || 'EMPTY'}&nbsp;</span>
    //         `;

    //         let btn = `<div class="d-flex align-items-center">`;

    //         if (i === 0) {
    //             // C'est le premier élément, n'affiche pas le bouton de suppression
    //             btn += `<button btn-id="${row.id}" btn-action="nothing" class="ms-2 btn btn-primary- btn-action cfa-btn-light cfa-btn-danger" disabled><i class="fa fa-trash"></i></button>`;
    //         } else {
    //             // Ce n'est pas le premier élément, affiche le bouton de suppression
    //             btn += `<button  btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action cfa-btn-danger"><i class="fa fa-trash"></i></button>`;
    //         }

    //         btn += `</div>`;

    //         // classify and add to array
    //         this.serviceAll.unshift({
    //             'n°': i,
    //             'Intitulé': label,
    //             "Nombre d'Utilisateur": this.getServiceUsers(row.id),
    //             'Créé le': this.convertDate(row.created_at),
    //             'Actions': btn,
    //             name: row.name,
    //             id: row.id
    //         });

    //         //console.log("123", this.serviceAll);
    //       }

    //     }
    //     else {
    //       //let msg = 'Oups ! something went wrong.'
    //       //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //     }
    //     setTimeout(() => {
    //       this.waitingAPIResponse = false
    //       this.waitingAPIResponse2 = false;
    //     }, 2000);
    //   })
    //   .catch(error => {
    //         if (error.response) {
    //           //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
    //         }
    //         else if (error.request) {
    //           //let msg = 'The request was made but no response was received. Please check your network.'
    //           //this.alertString = `${msg}|alert-no|8000::`+Date.now()
    //         }
    //         else {
    //           //let msg = 'Oups ! something went wrong.'
    //           //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //         }
    //         this.waitingAPIResponse = false;
    //         this.waitingAPIResponse2 = false;
    //       })
    // },
    async getService() {
      this.waitingAPIResponse = true;
      this.waitingAPIResponse2 = true;

      try {
        const response = await getAPI.get(`services/?organisation=${localStorage.organisation}`);
        console.log(response)
        if (response.status === 200) {
          let services = response.data.data;
          this.staticService = services;
          this.serviceAll = [];

          for (let i = 0; i < services.length; i++) {
            let row = services[i];

            let nbrUsers = await this.getServiceUsers(row.id);

            let label = `
              <span style="min-width: 10em !important;" class="badge rounded-pill badge-danger- cfa-btn-secondary">${row.name || 'EMPTY'}&nbsp;</span>
            `;

            let btn = `<div class="d-flex align-items-center">`;

            if (i === 0) {
              btn += `<button  btn-id="${row.id}" btn-action="edit" class="ms-2 btn btn-action cfa-btn-primary cfa-btn-light"><i class="fa fa-edit"></i></button>`;
              btn += `<button btn-id="${row.id}" btn-action="nothing" class="ms-2 btn btn-action btn-danger" disabled><i class="fa fa-trash"></i></button>`;
            } else {
              btn += `<button  btn-id="${row.id}" btn-action="edit" class="ms-2 btn btn-action cfa-btn-primary cfa-btn-light"><i class="fa fa-edit"></i></button>`;
              btn += `<button  btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
            }

            btn += `</div>`;

            //this.serviceAll.unshift({
            this.serviceAll.push({
              'n°': i + 1,
              'Intitulé': this.splitGroup(label),
              "Nombre d'Utilisateur": `<div class="text-center">${nbrUsers}</div>`,
              'Créé le': this.convertDate(row.created_at),
              'Actions': btn,
            });
          }
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
          this.waitingAPIResponse2 = false;
        }, 2000);
      } catch (error) {
        if (error.response) {
          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false;
        this.waitingAPIResponse2 = false;
      }
    },
    async createService(serviceName) {
      try {
        const response = await getAPI.post(`services/`, {
          name: serviceName,
          organisation:  localStorage.organisation
        });
        console.log(response)
        if (response.status === 201 || response.status === 200) {
          let services = response.data.data;
          return services
        }
        
      } catch (error) {
        console.log(error)
        if (error.response.status == 400) {
          if (error.response?.data?.name) {
            let msg = error.response?.data?.name[0]
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        }
        return false
      }
    },
    async getServiceUsers(service_id) {
      try {
        if (!service_id) {
          throw new Error("Service ID is undefined.");
        }
        const response = await getAPI.get(`services/get_users?service=${service_id}`);

        if (response.status === 200) {
          const users = response.data.data;
          const nbrUsers = users.length;
          console.log("Le nombre d'utilisateurs pour le service:", service_id, "est:", nbrUsers);
          return nbrUsers;
        }
        // else {
        //   //let msg = 'Oups ! something went wrong.'
        //   //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        // }
      } catch (error) {
        if (error.response) {
          //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        // else {
        //   //let msg = 'Oups ! something went wrong.'
        //   //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        // }
      }
    },
    updateGroup(pos) {
      this.updateGroupPayload = pos
      this.openNewGroup = true
    },
    newGroup() {
      this.userGroupAll = []
      this.updateGroup({ name: '' });
      this.getMembers();
    },
    newService() {
      this.openNewService = true;
    },
    confirmNewService() {
      if (this.service_name !== '' && this.service_name.length >= 2) {
        /*getAPI.post(`services/`, {
          name: this.service_name,
          organisation: localStorage.organisation,
        })*/
        getAPI.post(`services/add_or_update_user_to_service`, {
          service_name: this.service_name,
          //organisation: localStorage.organisation,
          users_ids: this.selectedUser
        })
          .then(response => {
            if (response.status === 201 || response.status === 200) {
              this.getService()
              let msg = 'Service créé avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.service_name = ""
              this.exitNewServiceModal = Date.now()
            }
            else {
              let msg = 'Service non cree. Veuillez reesayer'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitNewServiceModal = Date.now()
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `Erreur de creation du service. Veuillez reesayer|alert-no|7000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitNewServiceModal = Date.now()
          })
      }
      else {
        this.exitNewServiceModal = Date.now()
        //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
    },
    getCallToActionForService(payload) {
      if (payload.action_type === 'delete') {
        this.openDeletionBox1 = true;
        this.tempDeleteID1 = payload.id;
      }
      if (payload.action_type === 'edit') {
        this.openServiceUpdate = true;
        this.tempDeleteID2 = payload.id;
        this.getSpecificService();
      }
      console.log("Payload: ", payload);
    },
    getSpecificService() {
      getAPI.get(`services/${this.tempDeleteID2}`)
        .then(response => {
          if (response.status === 200) {
            this.servicePayload = response.data
            this.servicePayload.name = this.splitGroup(this.servicePayload.name)
            console.log("Details d'un service: ", this.servicePayload)
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          // this.exitNewServiceModal = Date.now()
        })
    },
    confirmServiceUpdate() {
      /*getAPI.patch(`services/${this.tempDeleteID2}/`, {
        name: this.servicePayload.name
      })*/
        getAPI.patch(`services/add_or_update_user_to_service/${this.tempDeleteID2}`, {
          service_name: this.servicePayload.name,
          //organisation: localStorage.organisation,
          users_ids: this.selectedUser
        })
        .then(response => {
          if (response.status === 200) {
            let msg = 'Service mis a jour avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitServiceUpdate = Date.now()
            this.selectedUser = []
            this.getService()
          }
          else {
            let msg = 'Une erreur s\'est produite' 
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitServiceUpdate = Date.now()
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitServiceUpdate = false
        })
    },
    confirmNewGroup() {
      //console.log(this.selectedUser)
      //console.log(this.updateGroupPayload.name)
      if (this.updateGroupPayload.name !== '' && this.selectedUser.length > 0) {
        getAPI.post(`groupes/`, {
          name: this.updateGroupPayload.name,
          organisation: localStorage.organisation,
          users: this.selectedUser
        })
          .then(response => {
            if (response.status === 201) {
              this.getAllGroup()
              let msg = 'Groupe créé avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.exitNewGroupModal = Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitNewGroupModal = Date.now()
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.alertString = handleApiErrors(error);
            this.exitOrgUpdateModal = Date.now()
          })
      }
      else {
        this.exitNewGroupModal = Date.now()
        //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
    },
    async getCallToActionForGroup(payload) {
      if (payload.action_type === 'delete') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if (payload.action_type === 'edit') {
        this.openUpdateGroup = true;
        this.updateGroupStatusRequest = true;
        this.tempDeleteID = payload.id;

        await this.getMembers();
        await getAPI.get(`groupes/${payload.id}/`,)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              this.flattenedIds = []
              this.updateGroupData = response.data;
              //this.flattenedIds = this.updateGroupData.users.map(({ id }) => id);
              //console.log(this.updateGroupData, this.flattenedIds)
              this.checkUserInGroup()
              this.updateGroupStatusRequest = false;
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
      }
    },
    confirmDeletion1() {

      console.log("C'est la fonction !!")

      if (this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
        this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
      }
      else {
        getAPI.delete(`groupes/${this.tempDeleteID}/`)
          .then(response => {
            if (response.status == 204) {
              this.callbackPayload = 'Groupe supprimé avec succès' + '::' + Date.now();
              setTimeout(() => {
                this.getAllGroup()
              }, 1500)
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitGroupUpdateModal = Date.now()
            }
          })
          .catch(() => {
            this.exitGroupUpdateModal = Date.now()
            this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
          })
      }
    },
    async confirmUpdateGroup() {
      if (this.updateGroupData.name.length <= 0) {
        this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
      }
      else {
        const arrayOfIds = Object.values(this.flattenedIds);
        console.log("Ids: ", arrayOfIds);
        await getAPI.patch(`groupes/${this.tempDeleteID}/`, {
          name: this.updateGroupData.name,
          users: arrayOfIds,
        })
          .then(response => {
            console.log("Aiiiie: ", this.flattenedIds)
            if (response.status === 200) {
              this.callbackPayload = 'Groupe mis à jour avec succès' + '::' + Date.now();
              let msg = "Groupe mis à jour avec succès"
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              setTimeout(() => {
                this.getAllGroup()
                this.exitGroupUpdateModal = Date.now()

              }, 1500)
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitGroupUpdateModal = Date.now()
            }
          })
          .catch((error) => {
            console.log(error.message)
            this.exitGroupUpdateModal = Date.now()
            this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
          })
      }
    },
    selectTab(section) {
      localStorage.setItem('tab_management_active', section)
      this.currentTab = section
    },
    selectPopupTab(section) {
      this.currentUserTab = section
    },
    validateEmails(textAreaId) {
      this.emailList = this.emailList.toLowerCase();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const textArea = document.getElementById(textAreaId);
      const emails = textArea.value.split(',');
      const validEmails = [];
      let allEmailsValid = true;

      for (let i = 0; i < emails.length; i++) {
        const email = emails[i].trim();
        if (emailRegex.test(email)) {
          validEmails.push(email);
        } else if (email !== '') {
          allEmailsValid = false;
        }
      }

      if (allEmailsValid) {
        textArea.style.borderColor = 'green';
        if (validEmails.length > 0) {
          this.validEmailList = validEmails;
        }
      }
      else {
        textArea.style.borderColor = 'red';
        this.validEmailList = validEmails;
      }
    },
    async getOrganisationData() {
      let org = localStorage.getItem('organisation')
      if (org != null) {
        await getAPI.get(`organisations/${org}/`)
          .then(response => {
            if (response.status == 200) {
              this.orgData = response.data
              //this.alertString = `Données chargées avec succès|alert-yes|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
      }
      else {
        this.isOrg = false
        this.orgData = null
      }
    },
    onImageLoaded(imageData) {
      this.org_logo = imageData
    },
    onImageLoaded2(imageData) {
      this.updateDataPayload.logo = imageData
    },
    async retrieveUserSubscription() {
      this.waitingAPIResponse = true
      await getAPI.get(`subscriptions/find-subscription`).then(response => {
        if (response.status == 200) {
          this.subscriptionData = response.data.data
          this.remain_days = this.subscriptionData?.remain_days
         this.active=this.subscriptionData?.is_activated;
         console.log(this.active)        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    },
    cleanTableData() {
      this.tableData = this.tableData.map((row) => {
      const cleanedRow = {};
      Object.keys(row).forEach((key) => {
        const cleanedKey = key.trim().replace(/\r/g, ""); // Trim and remove `\r` from keys
        let value = row[key];

        // Check if the value is null or an empty string
        if ( cleanedKey.toLowerCase() == "email" && (value === null || (typeof value === "string" && value.trim() === ""))) {
          throw new Error(`"${cleanedKey}" d'un utilisateur ne peut etre vide`);
        }

        if (typeof value === "string") {
          value = value.trim().replace(/\r/g, ""); // Trim and remove `\r` from values
        }
        // Set service by default if no service
        if (cleanedKey.toLowerCase() == "service" && (!value || value == "")) {
          value = this.orgData.name
        }
        cleanedRow[cleanedKey] = value;
        });
        return cleanedRow;
      });
    },
    async addNewOrg() {
      // if(this.modeLine) {
      //   if(this.mew_member_email != '') {
      //     // const formData = new FormData();
      //     // formData.append('email', this.mew_member_email);
      //     // formData.append('organisation', localStorage.organisation);
      //     // formData.append('service', this.service_id);

      //     // console.log("Email:", formData.get('email'))
      //     // console.log("Organisation:", formData.get('organisation'))
      //     // console.log("Service:", formData.get('service'))

      //     //console.log("Details:", formData)

      //     let uniqUser = []

      //     const uniq = {
      //       email: this.mew_member_email,
      //       lastname: '',
      //       firstname: '',
      //       phone: '',
      //       position: '',
      //       service: this.service_id
      //     };

      //     uniqUser.push(uniq)

      //     getAPI.post('accounts/register/add-user', {"users": uniqUser})
      //     .then(response => {
      //         console.log('email: ', this.mew_member_email)
      //         console.log('organisation: ', localStorage.organisation)
      //         console.log('service: ', this.service_id)
      //           if(response.status == 201) {
      //             this.getMembers()
      //             //let msg = 'Utilisateur invité avec succès!'
      //             //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
      //             this.exitOrgModal = Date.now()
      //           }
      //           else {
      //             //let msg = 'Oups ! something went wrong.'
      //             //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      //           }
      //           this.exitOrgModal = Date.now()
      //         })
      //         .catch(error => {
      //           console.log('email: ', this.mew_member_email)
      //           console.log('org: ', localStorage.organisation)
      //           console.log('service: ', this.service_id) 
      //           /*getAPI.post('accounts/register/add-user',
      //           {
      //             'email': this.mew_member_email,
      //             'organisation': localStorage.organisation,
      //             'service': this.service_id
      //           })*/
      //           if (error.response) {
      //             //console.log(error.response.data.message)
      //             ////this.alertString = `${error.response.data.email[0]||error.response.data[0]}|alert-no|10000::`+Date.now()
      //             ////this.alertString("You haven't valid subscription", 'alert-no', 10000)
      //           }
      //           else if (error.request) {
      //             //let msg = 'The request was made but no response was received. Please check your network.'
      //             //this.alertString = `${msg}|alert-no|8000::`+Date.now()
      //           }
      //           else {
      //             //let msg = 'Oups ! something went wrong.'
      //             //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      //           }
      //           this.exitOrgModal = Date.now()
      //         })
      //   }
      //   else{
      //     //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
      //     //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      //     this.exitOrgModal = Date.now()
      //   }
      // }
      // else {
      //   const formDataList = this.validEmailList.map(email => {
      //     const formData = new FormData();
      //     formData.append('email', email);
      //     formData.append('organisation', localStorage.organisation);
      //     return formData;
      //   });
      //   Promise.all(formDataList.map(formData => getAPI.post('accounts/register/add-user', this.postData)))
      //       .then(responses => {
      //         const successfulResponses = responses.filter(response => response.status === 201);
      //         if (successfulResponses.length === formDataList.length) {
      //           this.getMembers();
      //           //let msg = 'Utilisateurs invités avec succès!';
      //           //this.alertString = `${msg}|alert-yes|5000::`+Date.now();
      //         } else {
      //           //let msg = 'Certaines invitations ont échoué.';
      //           //this.alertString = `${msg}|alert-no|5000::`+Date.now();
      //         }
      //         this.exitOrgModal = Date.now();
      //       })
      //       .catch(error => {
      //         //let msg = 'Oups ! something went wrong.';
      //         if (error.response) {
      //           console.log(error.response);
      //           //this.alertString = `${error.response.data.email[0]||error.response.data[0]}|alert-no|10000::`+Date.now();
      //         } else if (error.request) {
      //           //msg = 'The request was made but no response was received. Please check your network.';
      //           //this.alertString = `${msg}|alert-no|8000::`+Date.now();
      //         }
      //         //this.alertString = `${msg}|alert-no|5000::`+Date.now();
      //         this.exitOrgModal = Date.now();
      //       });
      // }
      if(this.tableData.length > 0 && this.currentUserTab == "bulkUser") {
        const payload = [];
        const servicesMap = {}
        // Fetch existing services from API
        const existingServices = this.staticService;
        // Build a map of existing services
        existingServices.forEach(service => {
          servicesMap[service.name] = service.id;
        });
        /*this.tableData.forEach(element => {
          payload.push({
            firstname: element.Nom,
            lastname: element.Prenoms,
            email: element.Email,
            phone: element.Telephone,
            position: localStorage.organisation,
            service: element.Service
          })
        })*/
        // Iterate over tableData and handle services
        for (const element of this.tableData) {
          console.log(element)
          let serviceId;
          let serviceRawName = this.formatString(element.Service)
            
          let serviceName = `${serviceRawName}_${this.orgData.name}` 
          let isServiceError = false
          // Check if service already exists
          if (servicesMap[serviceRawName] || servicesMap[serviceName]) {
            serviceId = servicesMap[serviceName];
          } else {
            // Create the service via API and get its ID
            const newService = await this.createService(serviceRawName);
            if (!newService) {
              isServiceError = true 
              this.exitOrgModal = Date.now()
              return
            }
            serviceId = newService.id;

            // Add the new service to the map to avoid duplicate calls
            servicesMap[serviceName] = serviceId;
          }
          if (isServiceError) {
            this.exitOrgModal = Date.now()
            return
          }
          // Add the processed row to the payload
          payload.push({
            firstname: element.Nom,
            lastname: element.Prenoms,
            email: element.Email,
            phone: element.Telephone,
            position: localStorage.organisation,
            service: serviceName, // Use service ID here
            //service: serviceId, // Use service ID here
          });
        }
        console.log('table data',payload);
        //return
        //eslint-disable-next-line
        await getAPI.post('accounts/register/add-users', {
          "users": payload
        })
          .then(response => {
            console.log(response)
            if (response.status == 201 || response.status == 200) {
              this.tableData = [];
              let msg = 'Utilisateur ajouter avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.getMembers()
              this.exitOrgModal = Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              alert(msg)
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgModal = Date.now()
          })
          .catch(error => {
            /*getAPI.post('accounts/register/add-user',
            {
              'email': this.mew_member_email,
              'organisation': localStorage.organisation,
              'service': this.service_id
            })*/
            if (error.response) {
              console.log(error)
              //alert(error.response.data.error)
              //this.alertString = `${error.response.data.email[0]||error.response.data[0]}|alert-no|10000::`+Date.now()
              //this.alertString("You haven't valid subscription", 'alert-no', 10000)
              if (error.response?.data.email) {
                this.alertString = `L'email d'un utilisateur deja|alert-no|10000::`+Date.now()
              }
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              alert(msg)
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              alert(msg)
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgModal = Date.now()
          }) 
      } else if (this.inputArray.length > 0 && this.currentUserTab == "singleUser") {
        console.log('input data',this.inputArray);
        getAPI.post('accounts/register/add-users', {
          "users": this.inputArray
        })
          .then(response => {
            console.log(response)
            if (response.status == 201 || response.status == 200) {
              this.inputArray = [
                {
                  firstname: '',
                  lastname: '',
                  email: '',
                  phone: '',
                  position: localStorage.organisation,
                  service: ''
                }
              ];
              let msg = `Utilisateur ajouter avec succès!`
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.getMembers()
              this.exitOrgModal = Date.now()
            }
            else {
              let msg = `Une erreur s'est produite`
              //alert(msg)
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgModal = Date.now()
          })
          .catch(error => {
            /*getAPI.post('accounts/register/add-user',
            {
              'email': this.mew_member_email,
              'organisation': localStorage.organisation,
              'service': this.service_id
            })*/
            if (error.response) {
              console.log(error)
              let msg = `Une erreur s'est produite`
              //alert(error.response.data.error)
              if (error.response?.data?.error == "Service name is required for each user") {
                msg = "Vous devez sélectionner un service pour chaque utilisateur"
              } else if (error.response?.data?.error == "You have exceeded the number of users allowed for your plan") {
                msg = "Vous avez execédé le nombre d'utilisateurs requis par cette formule"
              } else if (error.response?.data?.email) {
                msg = "L'email de cet utilisateur existe déja"
              }
              this.alertString = `${msg}|alert-no|10000::`+Date.now()

              //this.alertString("You haven't valid subscription", 'alert-no', 10000)
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              alert(msg)
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              alert(msg)
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgModal = Date.now()
          })
      } else {
        let msg
        if (this.currentUserTab == "singleUser") {
          msg = 'Veuillez remplir les champs convenablement'
        } else {
          msg = 'Veuillez télécharger un fichier'
        }
        //alert(msg)
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitOrgModal = Date.now()

      }
    },
    formatString(input) {
      // Trim leading and trailing whitespace
      let trimmed = input.trim();

      // Replace multiple spaces between words with a single space
      trimmed = trimmed.replace(/\s+/g, '');

      // Capitalize the first letter of each word
      let capitalized = trimmed.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');

      return capitalized;
    },
    async addNewOrg2() {
      if (this.modeLine) {
        if (this.mew_member_email != '' && this.service_id != '') {

          let uniqUser = []

          uniqUser.push(this.mew_member_email)

          getAPI.post('organisations/send/invitations/', {
            emails: uniqUser,
            service: this.service_id
          })
            .then(response => {
              console.log(response)
              console.log('email: ', this.mew_member_email)
              if (response.status == 201 || response.status == 200) {
                let msg = 'Utilisateur invité avec succès!'
                this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                this.getMembers();
                // this.exitOrgModal2 = Date.now()
                this.exitOrgModal2 = true
              }
              else {
                if (response.data.message == "Failed to send one or more emails!") {
                  let msg = "Echec d'envoie de l'invitation. Veuillez reesayez"
                  this.alertString = `${msg}|alert-no|8000::`+Date.now()
                } else {
                  let msg = "Une erreur s'est produite"
                  this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
              }
              // this.exitOrgModal2 = Date.now()
              this.mew_member_email = '';
              this.service_id = '';
              this.exitOrgModal2 = true
            })
            .catch(error => {
              console.log(error)
              if (error.response && error.response.status == 500) {
                this.alertString = `Une erreur server s'est produite`+Date.now()
              } else if (error.response && !error.response.data.success) {
                //console.log(error.response.data.message)
                if (error.response.data.data == "invalid serializer") {
                  let text = error.response.data.message.non_field_errors[0]
                  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
                  const emailMatch = text.match(emailRegex);

                  if (emailMatch) {
                    console.log("Extracted email:", emailMatch[0]);
                    this.alertString = `L'utilisateur ${emailMatch[0]} existe deja|alert-no|10000::`+Date.now()
                  }
                } else if (error.response.data.message && error.response.data.message.startsWith("Failed to send email")) {
                  this.alertString = `Echec d'envoie de l'email`+Date.now()
                } else {
                  this.alertString = `${error.response.data?.email[0]||error.response.data[0]}|alert-no|10000::`+Date.now()
                }
                //this.alertString("You haven't valid subscription", 'alert-no', 10000)
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.mew_member_email = '';
              this.service_id = '';
              this.exitOrgModal2 = Date.now()
              //this.exitOrgModal2 = true

            })
        } 
        else {
          this.mew_member_email = '';
          this.service_id = '';
          let msg = 'Veuillez remplir les champ convenablement.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
          //this.exitOrgModal2 = true
          this.exitOrgModal2 = Date.now()
        }
      }
      else {
        if (this.tableData.length > 0) {
          const payload = [];
          const servicesMap = {}
          // Fetch existing services from API
          const existingServices = this.staticService;
          // Build a map of existing services
          existingServices.forEach(service => {
            servicesMap[service.name] = service.id;
          });
          
          // Iterate over tableData and handle services
          let emailPayload = []
          let servicePayloadId
          let isServiceError = false
          console.log(servicesMap)
          //console.log(this.orgData)
          for (const element of this.tableData) {
            console.log(element)
            let serviceId;
            let serviceRawName = this.formatString(element.Service)
            //if (!serviceRawName || serviceRawName == "" ) serviceName = this.formatString(this.orgData.name)
            let serviceName = `${serviceRawName}_${this.orgData.name}` 
            console.log(serviceName)
            // Check if service already exists
            if (servicesMap[serviceRawName] || servicesMap[serviceName]) {
              serviceId = servicesMap[serviceRawName] ?? servicesMap[serviceName] ;
            } else {
              // Create the service via API and get its ID
              const newService = await this.createService(serviceRawName);
              if (!newService) {
                isServiceError = true 
                this.exitOrgModal = Date.now()
                return
              }
              serviceId = newService.id;

              // Add the new service to the map to avoid duplicate calls
              servicesMap[serviceName] = serviceId;
            }
            if (isServiceError) {
              this.exitOrgModal = Date.now()
            }
            servicePayloadId = serviceId

            // Add the processed row to the payload
            emailPayload.push(element.Email)
            payload.push({
              firstname: element.Nom,
              lastname: element.Prenoms,
              email: element.Email,
              phone: element.Telephone,
              position: localStorage.organisation,
              service: serviceName, // Use service ID here
            });
          }
          console.log('table data',payload);
          await getAPI.post('organisations/send/invitations/', {
            emails: emailPayload,
            service: servicePayloadId
          })
            .then(response => {
              console.log('email: ', this.mew_member_email)
              if (response.status == 201 || response.status == 200) {
                let msg = 'Utilisateur invité avec succès!'
                this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                this.getMembers();
                // this.exitOrgModal2 = Date.now()
                this.exitOrgModal2 = true
              }
              else {
                if (response.data.message == "Failed to send one or more emails!") {
                  let msg = `Echec d'envoie de l'invitation pour ${response.data?.failed_emails[0]}. Veuillez reesayez`
                  this.alertString = `${msg}|alert-no|8000::`+Date.now()
                } else {
                  let msg = "Une erreur s'est produite"
                  this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
              }
              // this.exitOrgModal2 = Date.now()
              this.mew_member_email = '';
              this.service_id = '';
              this.exitOrgModal2 = true
            })
            .catch(error => {
              // console.log('email: ', this.mew_member_email)
              // console.log('org: ', localStorage.organisation)
              // console.log('service: ', this.service_id)
              
              if (error.response && !error.response.data?.success) {
                //console.log(error.response.data.message)
                if (error.response.data.data == "invalid serializer") {
                  let text = error.response.data.message.non_field_errors[0]
                  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
                  const emailMatch = text.match(emailRegex);

                  if (emailMatch) {
                    console.log("Extracted email:", emailMatch[0]);
                    this.alertString = `L'utilisateur ${emailMatch[0]} existe deja|alert-no|10000::`+Date.now()
                  }
                } else {
                  this.alertString = `${error.response.data.email[0]||error.response.data[0]}|alert-no|10000::`+Date.now()

                }
                //this.alertString("You haven't valid subscription", 'alert-no', 10000)
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              // this.exitOrgModal2 = Date.now()
              this.mew_member_email = '';
              this.service_id = '';
              this.exitOrgModal2 = true

            })
        } else {
          let msg = 'Veuillez télécharger un fichier valide.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
          this.exitOrgModal2 = Date.now()
        }
        /*getAPI.post('organisations/send/invitations/', {
          emails: this.postData2,
          service: this.service_id
        })
          .then(response => {
            if (response.status == 201 || response.status == 200) {
              this.getMembers()
              let msg = 'Utilisateurs invité avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              // this.exitOrgModal2 = Date.now()
              this.exitOrgModal2 = true

            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            // this.exitOrgModal2 = Date.now()
            this.exitOrgModal2 = true

          })
          .catch(error => {
            console.log('email: ', this.mew_member_email)
            console.log('org: ', localStorage.organisation)
            console.log('service: ', this.service_id)
            
            if (error.response && !error.response.data.success) {
                //console.log(error.response.data.message)
                if (error.response.data.data == "invalid serializer") {
                  let text = error.response.data.message.non_field_errors[0]
                  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
                  const emailMatch = text.match(emailRegex);

                  if (emailMatch) {
                    console.log("Extracted email:", emailMatch[0]);
                    this.alertString = `L'utilisateur ${emailMatch[0]} existe deja|alert-no|10000::`+Date.now()
                  }
                } else {
                  this.alertString = `${error.response.data.email[0]||error.response.data[0]}|alert-no|10000::`+Date.now()

                }
                
              }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            // this.exitOrgModal2 = Date.now()
            this.exitOrgModal2 = true

          })*/
      }
    },
    deleteData(id, name) {
      this.openDeletionBox = true;
      this.tempDeleteID = id;
      this.tempDeleteName = name
    },
    async confirmDeletionService() {
      let service = this.staticService.find(serv => serv.id == this.tempDeleteID1)
      console.log(service, this.deleteText)
      //return
      //eslint-disable-next-line
      if (service && (service.name.split("_"))[0] == this.deleteText) {
        await getAPI.delete(`services/${this.tempDeleteID1}/`)
          .then(response => {
            if (response.status === 204) {
              console.log("C'est ici !!!")
              //this.callbackPayload = 'Service supprimé avec succès' + '::' + Date.now();
              let msg = 'Service supprime'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.exitServiceModalDel = Date.now()
              setTimeout(() => {
                this.getService()
              }, 1500)
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitServiceModalDel = Date.now()
            }
          })
          .catch((error) => {
            //this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            this.alertString = handleApiErrors(error)
            this.exitServiceModalDel = Date.now()
          })
      } else {
          this.alertString = `${"Veuillez entrez le nom du service"}|alert-no|5000::`+Date.now()
          this.exitServiceModalDel = Date.now()
      }
    },
    // confirmDeletionGroup () {
    //   if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
    //     this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
    //   }
    //   else{
    //     console.log("Egal")
    //     getAPI.delete(`groupes/${this.tempDeleteID1}/`)
    //         .then(response => {
    //             if(response.status === 204) {
    //               this.callbackPayload = 'Groupe supprimé avec succès' + '::' + Date.now();
    //               setTimeout(() => {
    //                 this.getService()
    //               }, 1500)
    //             }
    //             else {
    //               //let msg = 'Oups ! something went wrong.'
    //               //this.alertString = `${msg}|alert-no|5000::`+Date.now()
    //               this.exitServiceModalDel = Date.now()
    //             }
    //         })
    //         .catch(() => {
    //           this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
    //           this.exitServiceModalDel = Date.now()
    //         })
    //       }
    // },
    confirmDeletion() {
      if (this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
        this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
      }
      else {
        getAPI.delete(`organisations/${this.tempDeleteID}/`)
          .then(response => {
            if (response.status == 204) {
              this.callbackPayload = 'Organisation supprimé avec succès' + '::' + Date.now();
              setTimeout(() => {
                this.getAllOrgs()
              }, 1500)
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitOrgModalDel = Date.now()
            }
          })
          .catch(() => {
            this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
          })
      }
    },
    updateData(pos) {
      this.updateDataPayload = pos
      this.openUpdateOrg = true
    },
    confirmUpdateServ() {
      getAPI.post(`services/update_service_for_user`, {
        user: this.payloadUserId,
        //user: this.updateDataPayload.id,
        service: this.newServ,
      })
        .then(response => {
          if (response.status == 200) {
            console.log("wwwwwwwww: ", this.payloadUserId);
            console.log("Serv: ", this.newServ);
            this.getMembers();
            //let msg = response.data.data;
            ////let msg = 'Uitlisateur mis à jour avec succès!'
            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgUpdateModal2 = Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal2 = Date.now()
        })
        .catch(error => {
          if (error.response) {
            //let msg = 'Please select a service to update !'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            ////this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal2 = Date.now()
        })
    },
    async confirmUpdate() {

      delete this.updateDataPayload.image_url

      if (this.updateDataPayload?.name != '' && this.updateDataPayload.numSiret != '' && this.updateDataPayload.activity != '' && this.updateDataPayload.adress != '' && this.updateDataPayload.phone != '' && this.updateDataPayload.email != '' && this.updateDataPayload.website != '') {
        if (this.updateDataPayload.service?.id != this.userUpdateServiceId) {{
          await getAPI.post(`services/update_service_for_user`, {
            user: this.updateDataPayload.id,
            service: this.userUpdateServiceId
          }).catch(err => console.log(err))
        }}
        await getAPI.patch(`accounts/users/manager/${this.updateDataPayload.id}/`, this.updateDataPayload)
          .then(response => {
            console.log("wwwwwwwww2222222222: ", this.updateServiceId);
            if (response.status == 200) {
              this.getMembers()
              let msg = 'Uitlisateur mis à jour avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.exitOrgUpdateModal = Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgUpdateModal = Date.now()
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgUpdateModal = Date.now()
          })
        
      }
      else {
        //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitOrgUpdateModal = Date.now()
      }
    },
    getCallToAction(payload) {
      if (payload.action_type === 'activate' || payload.action_type === 'deactivate') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if (payload.action_type === 'view') {
        getAPI.get(`organisations/${payload.id}/`,)
          .then(response => {
            if (response.status === 200) {
              let data = response.data
              this.updateData(data);
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
      }
    },
    catchAction(data) {
      if (data == '#user-group') {
        this.addGroup = true
      }
      else if (data == '#unique-user') {
        this.addUser = true
      }
    },
    checkMail(mail) {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true
      }
      return false
    },
    checkMailList(e) {
      if (e.keyCode === 32) {
        this.listOfEmail += '\n';
      }
      let data = this.listOfEmail.split('\n')
      document.getElementById('mailListContainer').style.borderColor = '#b9f5b9'
      for (let i = 0; i < data.length; i++) {
        if (!this.checkMail(data[i])) {
          document.getElementById('mailListContainer').style.borderColor = '#ff0000'
          break
        }
      }
      console.log(data)
    },
    retrieveUserData() {
      getAPI.get('accounts/users/me')
        .then(response => {
          if (response.status == 200) {
            this.userEmail = response.data.data.email
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    getCallToActionForUser(payload) {

      this.payloadUser = payload;
      this.payloadUserId = payload.id;

      console.log('payload', payload);

      if (payload.name == this.userEmail) {
        //let msg = 'Oups ! Vous ne pouvez désactivé le compte administrateur de cette organisation'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      else if (payload.action_type == 'activate' || payload.action_type == 'deactivate') {
        getAPI.put(`accounts/users/change-statut/${payload.id}/`, {
          is_active: (payload.action_type == 'activate') ? true : false
        }).then(response => {
          if (response.status == 200) {
            //let msg = 'Utilisateur MAJ avec succès.'
            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.getMembers()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        }).catch(() => {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        })
      }
      else if (payload.action_type == 'delete') {
        getAPI.put(`accounts/users/change-statut--/${payload.id}/`, {
          is_active: (payload.action_type == 'activate') ? true : false
        }).then(response => {
          if (response.status == 200) {
            //let msg = 'Utilisateur MAJ avec succès.'
            //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.getMembers()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        }).catch(() => {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        })
      }
      else if (payload.action_type == 're_invite') {
        getAPI.post(`accounts/resend-email-activate-account/`, {
          email: payload.name
        })
          .then(response => {
            console.log(response)
            if (response.status == 200) {
              let msg = `Le mail d'invitation a été envoyé avec succès.`
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            }
            else {
              let msg = 'Une erreur s\'est produite'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch((err) => {
            console.log(err)
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          })
      }
      if (payload.action_type == 'edit') {
        this.openUpdateOrg = true;
        this.tempUpdateID = payload.id;
        getAPI.get(`accounts/user/${payload.id}/`,)
          .then(response => {
            
            if (response.status == 200) {
              let data = response.data.data
              this.updateDataPayload = data;
              if (this.updateDataPayload.service?.id) {
                this.userUpdateServiceId = this.updateDataPayload.service?.id ?? this.getDefaultService?.id
              }
              this.tempUpdateID = null
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
      }
      if (payload.action_type == 'changeService') {
        this.openUpdateOrg2 = true;
        this.tempUpdateID = payload.id;
        getAPI.get(`accounts/user/${payload.id}/`,)
          .then(response => {
            if (response.status == 200) {
              let data = response.data.data
              this.updateDataPayload = data;
              this.tempUpdateID = null
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch(error => {
            if (error.response) {
              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              //let msg = 'The request was made but no response was received. Please check your network.'
              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              //let msg = 'Oups ! something went wrong.'
              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
      }
      if (payload.action_type == "desactivate") {
        //alert("desactivate")
        console.log(payload.id)
        getAPI.post(`accounts/users/${payload.id}/activate/`).then(res => {
          console.log(res)
          this.getMembers()
        }).
        catch(err => {
          console.log(err)
        })
      }
    },
    canSetDate() {
      return this.subscriptionData.length != 0 && localStorage.type_account == "organisation";
    },
    getDashboardData() {
      this.waitingAPIResponse = true
      getAPI.get(`other/dashboard/`)
        .then(response => {
          if (response.status == 200) {
            this.dateEnd = response.data.data?.deadline
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
    },
    getAllGroup() {
      this.waitingAPIResponse = true
      //getAPI.get(`phishing/groups/`)
      getAPI.get(`groupes`)
        .then(response => {
          console.log(response)
          if (response.status === 200) {
            let groups = response.data.data;
            this.groupAllData = response.data.data;
            this.groupAll = []
            for (let i = 0; i < groups.length; i++) {
              let row = groups[i];

              let users_count = `
                                <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                            `;
              let btn = `
                  <div class="d-flex align-items-center">
                      <button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-primary btn-action cfa-btn-light">
                          <i class="fa fa-edit"></i>
                      </button>
                      <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action">
                          <i class="fa fa-trash"></i>
                      </button>
                  </div>`;

              //this.splitGroup(row.name)
              this.groupAll.push({ 'n°': (i + 1), 'Nom': `<span class="selectable">${row.name}</span>`, 'Utilisateurs': users_count, 'Denière modification': this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id })
            }
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            //let msg = 'Oups ! something went wrong.'
            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
    },
    checkUserInGroup() {
      let group = this.updateGroupData
      //console.log(group, userId, group.users.includes(userId))
      this.userList.forEach(user => {
        if (group.users.includes(user.id)){
          this.flattenedIds.push(user.id)
          
        } 
      })
      //return group.users.includes(userId)
      
      //return false
    }
  },
  async mounted() {
    await this.retrieveUserSubscription()
    await this.getOrganisationData()
    await this.getService()
    this.getAllGroup()
    this.getDashboardData()
    await this.getMembers()
    this.retrieveUserData()
    this.getServiceUsers()
  },
  watch: {
    dateEnd: function (value) {
      this.waitingAPIResponse = true
      getAPI.patch(`organisations/${localStorage.organisation}/`, {
        deadline_training: value,
      }).then(response => {
        if (response.status == 200) {
          this.subscriptionData2 = response.data.data
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    }
  }
}
</script>

<style>
/*.cfa-root-content{*/
/*    padding: 2em;*/
/*    !*width: 100%;*!*/
/*    width: calc(100vw - 20em) !important;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
</style>
<style scoped>
/* TAB STYLE */
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 10px;*/
  /*border-bottom: 1px solid #ccc;*/
}

.tab-form{
  width:100%;
}

.tab-header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-header ul li {
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px 5px 0 0;
}
.tab-header-form ul li{
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px 5px 0 0;
}
.cba-font{
  background-color:#fff;
  color:#000;
  border: 1px solid #000;

}

.tab-header ul li.active {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
}
.tab-header-form ul li.active {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
}

.tab-header ul li:hover {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
}
.tab-header-form  ul li:hover {
  background-color: #333;
  color: #fff;
  border: 1px solid #000;
}

.tab-body {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
}
.tab-body-form {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
  width: 100%;
}

.table-header{
  text-transform: capitalize;
}
.pl-0 {
  padding-left: 0;
}

a.switch-link {
  padding: 6px;
  background: #000;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
}

.badge {
  font-size: .95rem;
}

.cfa-tab-btn {
  border-bottom: none;
}

.user-row {
  border: 1px solid #00000030;
  padding: 0 6px;
  border-radius: 0.375rem;
  background-color: transparent !important;
  margin: 10px 0;
}

.cfa-section-title {
  color: #fff;
}

.cfa-modal-content .form-control {
  box-shadow: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #00000030;
  border-radius: 0;
  color: #000;
}

input[type=email] {
  text-transform: lowercase !important;
}

button.btn-multiple {
  background: #132B4D;
  color: #fff;
}
.bulk-table tr th,
.bulk-table tr td{
  vertical-align: middle;
}
.tab-ul-form {
  padding-left: 0;
}
.data-row{
  margin-left:5px;
  margin-right:5px;
  margin-bottom: 10px;
}
#userListEditor{
  text-transform: lowercase;
}
</style>
