<template>
    <div class="input-container country-code">
        <country-code class="country-dial" @code="changeCode" :dial="dial" />

        <input 
            type="text" 
            :placeholder="textHolder" 
            :value="phoneNumber" 
            @input="onInput"
        />
    </div>
</template>

<script>
import countryCode from './countryCode.vue';

export default {
    name: 'InputPhone',
    components: { countryCode },
    props: {
        textHolder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        initValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            phoneNumber: '',  // Only the number without country code
            dialCode: '+229', // Default country code
            countryCodeList: [
                { country: "Benin", code: "+229" },
                { country: "Afghanistan", code: "+93" },
                { country: "Albania", code: "+355" },
                { country: "Algeria", code: "+213" },
                { country: "American Samoa", code: "+1684" },
                { country: "Andorra", code: "+376" },
                { country: "Angola", code: "+244" },
                { country: "Argentina", code: "+54" },
                { country: "Australia", code: "+61" },
                { country: "Austria", code: "+43" },
                { country: "Azerbaijan", code: "+994" },
                { country: "Bahamas", code: "+1242" },
                { country: "Bahrain", code: "+973" },
                { country: "Bangladesh", code: "+880" },
                { country: "Belgium", code: "+32" },
                { country: "Bolivia", code: "+591" },
                { country: "Brazil", code: "+55" },
                { country: "Cameroon", code: "+237" },
                { country: "Canada", code: "+1" },
                { country: "Chile", code: "+56" },
                { country: "China", code: "+86" },
                { country: "Colombia", code: "+57" },
                { country: "Denmark", code: "+45" },
                { country: "Dominican Republic", code: "+1" },
                { country: "Ecuador", code: "+593" },
                { country: "Egypt", code: "+20" },
                { country: "Finland", code: "+358" },
                { country: "France", code: "+33" },
                { country: "Germany", code: "+49" },
                { country: "Ghana", code: "+233" },
                { country: "Greece", code: "+30" },
                { country: "India", code: "+91" },
                { country: "Indonesia", code: "+62" },
                { country: "Iran", code: "+98" },
                { country: "Iraq", code: "+964" },
                { country: "Ireland", code: "+353" },
                { country: "Italy", code: "+39" },
                { country: "Japan", code: "+81" },
                { country: "Kenya", code: "+254" },
                { country: "Malaysia", code: "+60" },
                { country: "Mexico", code: "+52" },
                { country: "Morocco", code: "+212" },
                { country: "Netherlands", code: "+31" },
                { country: "Nigeria", code: "+234" },
                { country: "Norway", code: "+47" },
                { country: "Pakistan", code: "+92" },
                { country: "Philippines", code: "+63" },
                { country: "Poland", code: "+48" },
                { country: "Portugal", code: "+351" },
                { country: "Russia", code: "+7" },
                { country: "South Africa", code: "+27" },
                { country: "Spain", code: "+34" },
                { country: "Turkey", code: "+90" },
                { country: "Ukraine", code: "+380" },
                { country: "United Kingdom", code: "+44" },
                { country: "United States", code: "+1" }
            ],
            dial:""
        };
    },
    emits: ['update:modelValue', 'updateNumber'],
    watch: {
        modelValue(newValue) {
            if (newValue.startsWith('+')) {
                this.extractCountryCode(newValue);
            } else {
                this.phoneNumber = newValue;
            }
        }
    },
    methods: {
        changeCode(country) {
            this.dialCode = '+' + country[2];
            this.updatePhoneNumber(this.phoneNumber);
        },
        onInput(event) {
            let newValue = event.target.value.replace(/[^0-9]/g, '');
            this.phoneNumber = newValue;
            this.updatePhoneNumber(newValue);
        },
        updatePhoneNumber(number) {
            const fullNumber = this.dialCode + number;
            this.$emit('update:modelValue', fullNumber);
            this.$emit('updateNumber', fullNumber);
        },
        extractCountryCode(fullNumber) {
            for (let entry of this.countryCodeList) {
                if (fullNumber.startsWith(entry.code)) {
                    this.dialCode = entry.code;
                    this.dial = entry.code.replace('+', '')
                    this.phoneNumber = fullNumber.replace(entry.code, '');
                    return;
                }
            }
            // If no matching country code is found, assume a default split
            this.dialCode = fullNumber.match(/^\+\d+/)?.[0] || '+229';
            this.phoneNumber = fullNumber.replace(this.dialCode, '');
        }
    },
    mounted() {
        if (this.initValue.startsWith('+')) {
            this.extractCountryCode(this.initValue);
        } else {
            this.phoneNumber = this.initValue;
        }
        this.updatePhoneNumber(this.phoneNumber);
    }
};
</script>
