<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">{{ $t('company_title') }}</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div v-if="orgData != null" class="page animsition" style="animation-duration: 0s; opacity: 1;">
            <div class="card o-hidden welcome-card">
              <!-- <div class="card-body">
                                <div>
                                  <h4 class="mb-3 mt-1 f-w-500 mb-0 f-22">Espace entreprise</h4>
                                  <p style="width: 100%;">Explorer nos offres de sensibilisation</p>
                                  <div class="whatsnew-btn mt-2">
                                      <-- /facturation/pricing ->
                                      <a href="/facturation/pricing" class="btn btn-outline-white">Voir tous les plans !</a>
                                  </div>
                                </div>
                                <div>
                                  <h4 class="mb-3 mt-1 f-w-500 mb-0 f-22">Espace entreprise</h4>
                                  <p style="width: 100%;">Explorer nos offres de sensibilisation</p>
                                  <div class="whatsnew-btn mt-2">
                                      <-- /facturation/pricing ->
                                      <a href="/facturation" class="btn btn-outline-white">Voir tous les plans !</a>
                                  </div>
                                </div>
                            </div> -->
              <div class="card-body d-flex justify-content-between">
                <div>
                  <h4 class="mb-3 mt-1 f-w-500 mb-0 f-22">{{ $t('business_area_title') }}</h4>
                  <p style="width: 100%;">{{ $t('explore_text') }}</p>
                  <div class="whatsnew-btn mt-2">
                    <a href="/facturation/pricing" class="btn btn-outline-white">{{ $t('explore_button') }}</a>
                  </div>
                </div>
                <!-- <div>
                                  <h4 class="mb-3 mt-1 f-w-500 mb-0 f-22">Espace entreprise</h4>
                                  <p style="width: 100%;">Explorer nos offres de sensibilisation</p>
                                  <div class="whatsnew-btn mt-2">
                                      <a href="/facturation" class="btn btn-outline-white">Voir tous les plans !</a>
                                  </div>
                              </div> -->
              </div>

              <img class="welcome-img" src="@/assets/img/png/office-building.png" alt="search image">
            </div>

            <div class="row">
              <div class="col-lg-12 col-xl-6">
                <div class="card course-box">
                  <div class="card-body entreprise-block" style="color: #132B4D">
                    <h5 style="color: #132B4D">{{  $t('company_code') }}</h5>
                    <br>
                    <span class="f-14 f-w-500 f-light" style="color: #132B4D">
                      {{  $t('company_code_text') }}
                    </span>
                    <br>
                    <br>
                    <div class="d-flex align-items-center justify-content-between btnsss"
                      style="position: relative; margin-top: .8em;">
                      <div class="d-flex align-items-center">
                        <a @click.prevent="copyContent" class="btn cfa-btn-primary btn-hover-effect rounded-0 d-flex align-items-center copy-code-btn" href=""
                          style="border-top-left-radius: 5px  !important; border-bottom-left-radius: 5px !important;">
                          <i class="fa fa-copy white-color" ></i> {{$t('copy_code')}}
                        </a>
                        <div ref="myDiv" @click="copyContent" href="" class="codeStyle"
                          style="border-top-right-radius: 5px; border-bottom-right-radius: 5px;">{{ orgData.code }}
                        </div>
                      </div>
                      <button @click="codeConfirmBox=true" class="btn cfa-btn-danger btn-hover-effect rounded-0 d-flex align-items-center change-code-btn"
                        style="border-radius: 5px !important;">
                        <i class="fa fa-refresh white-color"></i>
                        {{$t('change_code')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-xl-6">
                <!-- <div class="card pe-0 get-card overflow-hidden entreprise-block">
                                        <div v-if="subscriptionData.length > 0" class="card-header card-no-border d-flex justify-content-between align-items-center" padding: 1.5em 2em">
                                            <h5>Licence</h5>
                                            <button class="btn btn-success" :style=" `background-color: ${( subscriptionData[0]?.is_activated) ? 'green' : 'red'}`" >{{ ( subscriptionData[0]?.is_activated) ? 'Active' : 'Expirée' }}</button>
                                        </div>
                                        <div v-else class="card-header card-no-border d-flex justify-content-between align-items-center" style=" padding: 1.5em 2em">
                                            <h5>Licence</h5>
                                            <button class="btn btn-success" style="background-color: rgb(255 114 94);" >Aucun plan actif</button>
                                        </div>
                                            <div v-if="subscriptionData.length > 0" class="d-flex" style="padding: 0 2em 1.5em;">
                                                <div class="card mb-0 w-100">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Plan actif</h5>
                                                        <a class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" style="pointer-events: none; position: relative; top: 7px;" v-html="subscriptionData[0]?.plan.name.toUpperCase()"></a>
                                                    </div>
                                                </div>

                                                <div class="card mb-0 w-100 ms-4">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Capacité de la licence</h5>
                                                        <a class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" href="" style="pointer-events: none; position: relative; top: 7px;">
                                                            <-- {{ subscriptionData[0]?.plan.duration }} utilisateurs ->
                                                          ILLIMITÉ
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="card-body pt-0">
                                                <div  class="">
                                                    <div class="d-flex justify-content-center align-items-center oups">
                                                        <div class="d-flex flex-column my-4">
                                                            <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 2.5em; margin: auto;"/>
                                                            <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label" style="color: #132B4D">Aucune souscription pour le moment !</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div> -->
                <div class="card pe-0 get-card overflow-hidden entreprise-block">
                  <div v-if="subscriptionData?.is_activated == true"
                    class="card-header card-no-border d-flex justify-content-between align-items-center"
                    style=" padding: 1.5em 2em">
                    <h5>Licence</h5>
                    <button class="btn btn-success"
                      :style="`background-color: ${(subscriptionData?.is_activated) ? 'green' : 'red'}`">{{ (
                        subscriptionData?.is_activated) ? 'Active' : 'Expirée' }}</button>
                  </div>
                  <div v-else class="card-header card-no-border d-flex justify-content-between align-items-center"
                    style=" padding: 1.5em 2em">
                    <h5>{{$t('license')}}</h5>
                    <button class="btn btn-success" style="background-color: rgb(255 114 94);">Aucun plan
                      actif</button>
                  </div>
                  <div v-if="subscriptionData?.is_activated == true" class="d-flex crvsce wyefwey"
                    style="padding: 0 2em 1.5em;">
                    <div class="fioce">
                      <h5 class="card-title">Plan actif</h5>
                      <a v-if="remain_days != ''" class="btn btn-light-  cfa-bg-light-primary f-light no-wrap"
                        style="pointer-events: none; position: relative;" v-html="lastTransactionPlan"></a>
                      <a v-else class="btn btn-light-  cfa-bg-light-primary f-light no-wrap"
                        style="pointer-events: none; position: relative;" v-html="'---'"></a>
                    </div>
                    <div class="fioce">
                      <h5 class="card-title">Jours restants</h5>
                      <a v-if="remain_days != ''" class="btn btn-light-  cfa-bg-light-primary f-light no-wrap"
                        style="pointer-events: none; position: relative;" v-html="remain_days + ' jours'"></a>
                      <a v-else class="btn btn-light-  cfa-bg-light-primary f-light no-wrap"
                        style="pointer-events: none; position: relative;" v-html="remain_days + '---'"></a>
                    </div>
                    <div class="fioce">
                      <h5 class="card-title">Capacité de la licence</h5>
                      <a v-if="subscriptionData?.is_activated == true"
                        class="btn btn-light-  cfa-bg-light-primary f-light no-wrap" href=""
                        style="pointer-events: none; position: relative;">
                        <!-- {{ subscriptionData[0]?.plan.duration }} utilisateurs -->
                          {{subscriptionData?.nb_user }}
                        utilisateurs
                      </a>
                      <a v-else class="btn btn-light-  cfa-bg-light-primary f-light no-wrap" href=""
                        style="pointer-events: none; position: relative;">
                        <!-- {{ subscriptionData[0]?.plan.duration }} utilisateurs -->
                        ---
                      </a>
                    </div>
                  </div>
                  <div v-else class="card-body pt-0">
                    <div class="">
                      <div class="d-flex justify-content-center align-items-center oups">
                        <div class="d-flex flex-column my-4">
                          <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content"
                            style="width: 2.5em; margin: auto;" />
                          <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label" style="color: #132B4D">Aucune
                            souscription pour le moment !</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4" v-if="subscriptionData?.is_activated == true">
                <div class="card course-box">
                  <div class="card-body time-block">
                    <div class="course-widget">
                      <div class="course-icon position-relative">
                        <svg class="fill-icon" style="background: #13dce6;">
                          <use href="http://127.0.0.1:8000/assets/svg/icon-sprite.svg#course-1"></use>
                        </svg>
                      </div>
                      <!-- <div>
                                                <h4 class="mb-0">Activation</h4><span class="f-light" style="color: ">Date d'activation de la licence</span>
                                                <a style="pointer-events: none;" v-if="subscriptionData.length > 0" class="btn btn-light- ff-bold cfa-bg-light-primary f-light no-wrap" href="" v-html="convertDate(subscriptionData[0]?.start_date, true)"></a>
                                                <a v-else class="btn btn-light f-light" href="" >---</a>
                                            </div> -->
                      <div>
                        <div v-if="subscriptionData?.created_at != null">
                          <h4 class="mb-0">Activation</h4><span class="f-light" style="color: ">{{ $t('license_activation_date') }}</span>
                          <a style="pointer-events: none;" v-if="subscriptionData != null"
                            class="btn btn-light- ff-bold cfa-bg-light-primary no-wrap white-color" href=""
                            v-html="convertDate(subscriptionData?.created_at, true)"></a>
                          <a v-else class="btn btn-light f-light" href="">---</a>
                        </div>
                        <div v-else>
                          <h4 class="mb-0">Activation</h4><span class="f-light" style="color: ">{{ $t('license_activation_date') }}</span>
                          <a style="pointer-events: none;" v-if="subscriptionData != null"
                            class="btn btn-light- ff-bold cfa-bg-light-primary white-color no-wrap" href=""
                            v-html="'---'"></a>
                          <a v-else class="btn btn-light f-light" href="">---</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="square-group">
                    <li class="square-1 warning"></li>
                    <li class="square-1 primary"></li>
                    <li class="square-2 warning1"></li>
                    <li class="square-3 danger"></li>
                    <li class="square-4 light"></li>
                    <li class="square-5 warning"></li>
                    <li class="square-6 success"></li>
                    <li class="square-7 success"></li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4" v-if="subscriptionData?.is_activated == true">
                <div class="card course-box">
                  <div class="card-body time-block">
                    <div class="course-widget">
                      <div class="course-icon warning">
                        <svg class="fill-icon">
                          <use href="http://127.0.0.1:8000/assets/svg/icon-sprite.svg#course-2"></use>
                        </svg>
                      </div>
                      <!-- <div>
                                                <h4 class="mb-0">Expiration</h4><span class="f-light" style="color: #132B4D">Date d'expiration de la licence</span>
                                                <--<a style="pointer-events: none;" v-if="subscriptionData.length > 0" class="btn ff-bold cfa-bg-light-primary f-light no-wrap" href="" v-html="convertDate(subscriptionData[0]?.end_date, true)"></a>->
                                                <a style="pointer-events: none;" v-if="subscriptionData.length > 0" class="btn ff-bold cfa-bg-light-primary f-light no-wrap" href="" v-html="'NON DÉFINI'"></a>
                                                <a v-else class="btn btn-light f-light" href="" >---</a>
                                            </div> -->
                      <div>
                        <div v-if="subscriptionData?.created_at != null">
                          <h4 class="mb-0">Expiration</h4><span class="f-light" style="color: #132B4D">{{ $t('license_expiration_date') }}</span>
                          <!--<a style="pointer-events: none;" v-if="subscriptionData.length > 0" class="btn ff-bold cfa-bg-light-primary f-light no-wrap" href="" v-html="convertDate(subscriptionData[0]?.end_date, true)"></a>-->
                          <a style="pointer-events: none;" v-if="subscriptionData != null"
                            class="btn ff-bold cfa-bg-light-primary white-color no-wrap" href=""
                            v-html="convertDate(expiration_date, true)"></a>
                          <a v-else class="btn btn-light white-color" href="">---</a>
                        </div>
                        <div v-else>
                          <h4 class="mb-0">Expiration</h4><span class="f-light" style="color: #132B4D">{{ $t('license_expiration_date') }}</span>
                          <!--<a style="pointer-events: none;" v-if="subscriptionData.length > 0" class="btn ff-bold cfa-bg-light-primary f-light no-wrap" href="" v-html="convertDate(subscriptionData[0]?.end_date, true)"></a>-->
                          <a style="pointer-events: none;" v-if="subscriptionData != null"
                            class="btn ff-bold cfa-bg-light-primary white-color no-wrap" href="" v-html="'---'"></a>
                          <a v-else class="btn btn-light white-color" href="">---</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="square-group">
                    <li class="square-1 warning"></li>
                    <li class="square-1 primary"></li>
                    <li class="square-2 warning1"></li>
                    <li class="square-3 danger"></li>
                    <li class="square-4 light"></li>
                    <li class="square-5 warning"></li>
                    <li class="square-6 success"></li>
                    <li class="square-7 success"></li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4" v-if="subscriptionData?.is_activated == true">
                <div class="card course-box time-block">
                  <div class="card-body">
                    <div class="course-widget">
                      <div class="course-icon warning">
                        <svg class="fill-icon" style="background: #272f43;">
                          <use href="http://127.0.0.1:8000/assets/svg/icon-sprite.svg#course-2"></use>
                        </svg>
                      </div>
                      <!-- <div>
                                            <h4 class="mb-0">Finalisation</h4><span class="f-light" style="color: #132B4D">Date de finalisation par les utilisateurs</span>
                                            <a style="pointer-events: none;" v-if="subscriptionData.length > 0" class="btn ff-bold cfa-bg-light-primary f-light no-wrap" href="" v-html="convertDate(dateEnd, true)"></a>
                                            <a v-else class="btn btn-light f-light" href="" >---</a>
                                        </div> -->
                      <div>
                        <h4 class="mb-0">Finalisation</h4><span class="f-light" style="color: #132B4D">{{ $t('user_finalization_date') }}</span>
                        <div v-if="dateEnd != null">
                          <a style="pointer-events: none;" v-if="subscriptionData != null"
                            class="btn ff-bold cfa-bg-light-primary white-color no-wrap" href=""
                            v-html="convertDate(dateEnd, true)"></a>
                          <a v-else class="btn btn-light white-color" href="">---</a>
                        </div>
                        <div v-else>
                          <a style="pointer-events: none;" v-if="subscriptionData != null"
                            class="btn ff-bold cfa-bg-light-primary white-color no-wrap" href="" v-html="'NON DEFINI'"></a>
                          <a v-else class="btn btn-light white-color" href="">---</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="square-group">
                    <li class="square-1 warning"></li>
                    <li class="square-1 primary"></li>
                    <li class="square-2 warning1"></li>
                    <li class="square-3 danger"></li>
                    <li class="square-4 light"></li>
                    <li class="square-5 warning"></li>
                    <li class="square-6 success"></li>
                    <li class="square-7 success"></li>
                  </ul>
                </div>
              </div>
            </div>

            <!--<div class="col-md-3 col-12">
                        <div class="col-12" v-if="subscriptionData.is_activated == true">
                            <div class="card course-box">
                                <div class="card-body">
                                    <h5>Mon code entreprise</h5>
                                    <span class="f-14 f-w-500 f-light">Code d’Inscription de votre entreprise :</span>
                                    <br>
                                    <div class="d-flex align-items-center" style="position: relative;top: 0.7em;">
                                        <a @click.prevent="copyContent" class="btn cfa-btn-primary btn-hover-effect rounded-0" href="" style="border-top-left-radius: 5px  !important; border-bottom-left-radius: 5px !important;">
                                            <i class="fa fa-copy" style="margin: 0.3em;"></i> Copier
                                        </a>
                                        <div ref="myDiv" @click="copyContent" href="" class="codeStyle" style="border-top-right-radius: 5px; border-bottom-right-radius: 5px;">{{ orgData.code }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="card course-box">
                                <div class="card-body">
                                    <div class="course-widget">
                                        <div class="course-icon warning">
                                            <svg class="fill-icon" style="background: #272f43;">
                                                <use href="http://127.0.0.1:8000/assets/svg/icon-sprite.svg#course-2"></use>
                                            </svg>
                                        </div>
                                        <div>
                                            <h4 class="mb-0">Finalisation</h4><span class="f-light">Date de finalisation par les utilisateurs</span>
                                            <a v-if="subscriptionData.length > 0" class="btn btn-light f-light no-wrap" href="" v-html="convertDate(subscriptionData[0]?.end_date)"></a>
                                            <a v-else class="btn btn-light f-light" href="" >---</a>
                                        </div>
                                    </div>
                                </div>
                                <ul class="square-group">
                                    <li class="square-1 warning"></li>
                                    <li class="square-1 primary"></li>
                                    <li class="square-2 warning1"></li>
                                    <li class="square-3 danger"></li>
                                    <li class="square-4 light"></li>
                                    <li class="square-5 warning"></li>
                                    <li class="square-6 success"></li>
                                    <li class="square-7 success"></li>
                                </ul>
                            </div>
                        </div>
                    </div>-->

            <!--<div class="col-md-3 col-12 pe-md-0">
                        <div class="card get-card overflow-hidden license-block" >
                            <div v-if="subscriptionData.length > 0" class="card-header card-no-border d-flex justify-content-between align-items-center" >
                                <h5>Licence</h5>
                                <button class="btn btn-success" :style="`background-color: ${( subscriptionData[0]?.is_activated) ? 'green' : 'red'}`" >{{ ( subscriptionData[0]?.is_activated) ? 'Active' : 'Expirée' }}</button>
                            </div>
                            <div v-else class="card-header card-no-border d-flex justify-content-between align-items-center" >
                                <h5>Licence</h5>
                                <button class="btn btn-success" style="background-color: #f4f4f4;" >Aucun plan actif</button>
                            </div>
                            <div class="card-body pt-0">
                                <div v-if="subscriptionData.length > 0" class="mt-2">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">Plan actif</h5>
                                            <a class="btn btn-light btn-sm">{{ subscriptionData[0]?.plan.name.toLowerCase() }}</a>
                                        </div>
                                    </div>

                                    <div class="card mt-3 mb-0">
                                        <div class="card-body">
                                            <h5 class="card-title">Capacité de la licence</h5>
                                            <a class="btn btn-light btn-sm">{{ subscriptionData[0]?.plan.duration }}</a>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="">
                                    <div class="d-flex justify-content-center align-items-center oups" style="min-height: 33vh">
                                        <div class="d-flex flex-column my-4">
                                            <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 10em; margin: auto;"/>
                                            <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label" style="">Aucune souscription pour le moment !</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->

            <!--<div class="col-12 px-md-0">
                        <div class="card course-card">
                            <div class="card-header card-no-border">
                                <div class="header-top">
                                    <h5 class="m-0">Mon abonnement</h5>
                                    <div class="card-header-right-icon">
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle" id="popularButton" type="button" >Voir les offres</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <div class="course-main-card">
                                    <button class="btn btn-square btn-primary btn-lg" type="button" data-bs-original-title style="border-radius: 5px;;">
                                        <span style="font-size: 10px;font-size: 13px;">&nbsp;&nbsp;CHOISIR UN PLAN&nbsp;&nbsp;</span>
                                    </button>
                                    <p style="background-color: #7366ff14; color: #9f9db5; border-radius: 10px; padding: 5px 10px; text-align: left;" class="mt-2">
                                        Assurez votre sécurité à chaque instant. Abonnez-vous maintenant et bénéficiez de l'installation gratuite de nos capteurs de dernière génération à votre domicile. Notre équipe s'occupera de tout dans les 24 heures suivant votre souscription.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>-->

            <div class="row">
              <div v-if="canSetDate()" class="col-12">
                <div class="card-transparent cfa-warning-deadline py-3 px-3 mb-4"
                  style="border: 2px solid var(--primary-color); color: var(--primary-color);font-weight: 400; border-radius: 4px;">
                  <div class="d-flex align-items-center justify-content-between w-100 efwegl">
                    <div>
                      <p style="font-weight: 400; font-size: 1.3em; color: #132B4D">{{ $t('awareness_end_date')}}</p>
                    </div>
                    <div class="00">
                      <VueDatePicker v-model="dateEnd" locale="fr" cancelText="Annuler" selectText="Choisir"
                        :title="'DEFINIR UNE DATE'" :format="'dd-MM-yyyy, hh:mm:ss'"></VueDatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="subscriptionData?.is_activated == true">
              <div class="col-12">
                <div class="card pr-0 course-box" style="margin-bottom: 0;">
                  <div class="card-body entreprise-block">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h5>{{ $t('visible_modules') }}</h5>
                        <span class="f-14 f-w-500 f-light" style="color: #132B4D">{{ $t('select_modules') }}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <br>
                    <div class="d-flex align-items-center flex-wrap" style="position: relative; top: 0.7em;">
                      <!-- <button class="btn btn-primary" @click.stop="updateVisibility(modules)" >
                        {{$t('check_all')}} 
                      </button> -->
                      <div class="d-flex justify-content-between w-100" :style="{padding: '1em 0.5em', margin: '0.3em',}">
                        <p class="fs-5">
                          {{$t('check_all')}} 
                        </p>
                        <label class="toggle-container" @click.stop="updateVisibility(modules)" >
                            <input type="checkbox" class="toggle-input" :checked="modules.every(module => module.visible)" >
                            <div class="toggle-track">
                              <span class="toggle-thumb"></span>
                            </div>
                          </label>
                      </div>
                      <div v-for="(module,index) in modules" :key="module.id" @click.stop="toggleVisibility(module)"
                        style="width: 100%;">
                        <div :style="{
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          padding: '1em 0.5em',
                          margin: '0.3em',
                          borderRadius: '0.25em',
                          backgroundColor: module.visible ? 'var(--primary-light-color)' : 'white',
                          minWidth: '5em',
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }">
                          <!-- &nbsp;<input type="checkbox" :checked="module.visible" @click="updateVisibility(module)"> -->
                          <span>
                            &nbsp;&nbsp;{{ module.name }}
                          </span>
                          <label class="toggle-container" @click.stop="updateVisibilities(module,index)" >
                            <input type="checkbox" class="toggle-input" :checked="module.visible" >
                            <div class="toggle-track">
                              <span class="toggle-thumb"></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <br>
                    <!-- <div class="d-flex justify-content-end align-items-center mt-2">
                      <button CLASS="btn cfa-btn-primary d-flex align-items-center justify-content-end">
                        {{ $t('save') }}
                      </button>&nbsp;
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <!--v-if="subscriptionData.length > 0"-->
            <app-theme v-if="subscriptionData?.is_activated == true"
              @alert="(e) => { this.alertString = e }"></app-theme>
            
            
            <div class="row">
              <div class="col-12">
                <div class="cfa-block p-4">
                  <div class="d-flex align-items-center justify-content-between sfger">
                    <h5 class="mb-0">{{ $t('organization_details') }}</h5>
                    <a v-if="subscriptionData?.is_activated == true" @click="openFileSelector"
                      class="btn cfa-btn-primary d-flex align-items-center" style="font-weight: 400;">
                      <i v-if="!uploadPending" class="fa fa-image margin-right-10 d-none d-md-block"
                        aria-hidden="true">&nbsp;</i>
                      <span v-if="!uploadPending" class="d-inline">{{$t('define_logo')}}</span>
                      <span v-else class="" style="width: 11em;">
                        <img width="20" src="@/assets/img/gif/load2.gif" alt="loader" />
                      </span>
                    </a>
      
                    <input type="file" accept="image/*" ref="fileSelector" @change="uploadImage" style="display: none;">
                  </div>
                  <div class="mt-2">
                    <section v-if="subscriptionData?.is_activated == true" class="text-center d-flex justify-content-center py-4"
                      style="border-radius: 0.5em;">
                      <div>
                        <img v-if="orgDataLogo != null" class="bg-white p-1" width="150" style="border-radius: 100%;"
                          :src="orgDataLogo" alt="logo company" />
                        <img v-else class="bg-white p-1" width="150" style="border-radius: 100%;"
                          src="@/assets/img/png/avatar.png" alt="logo company" />
                      </div>
                    </section>
                    <div class="row">
                      <section class="pb-0 col-12 col-md-6">
                        <form>
                          <div class="tab-pane active d-flex flex-wrap">
                            <div class="col-12">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>{{ $t('structure_name') }}</strong></p>
                                <input class="form-control" type="text" disabled="" v-model="orgData.name">
                              </div>
                            </div>
                            <div class="col-12">
                              <!-- <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>{{ $t('telephone') }}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.phone">
                              </div> :class="{ 'ui-input--error': errors.phone }" -->
                              <!-- <div class="relative">
                                <country-code class="country-dial absolute top-0 pl-2 country-input" @code="changeCode" />
                                <input id="telephone" type="text" placeholder="Entrez votre numero" required class="input input-bordered w-full tel-input ui-input" v-model="orgData.phone" />
                              </div> -->
                              
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>{{ $t('telephone') }}</strong></p>
                                <input-phone @updateNumber="(number) => orgData.phone=number" :init-value="orgData.phone.startsWith('+')?orgData.phone : `+${orgData.phone}`"  /> 
                              </div>
                            </div>
                            <div class="col-12 pl-0">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong> {{  $t('country') }}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.country">
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>{{$t('email')}}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.email">
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                      <section class="pb-0 col-12 col-md-6">
                        <form>
                          <div class="tab-pane active d-flex flex-wrap">
                            <div class="col-12">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>{{ $t('profile_activity_area') }}</strong></p>
                                <input class="form-control" type="text" :value="activityData?.name">
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>{{ $t('profile_city') }}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.city">
                              </div>
                            </div>
                            <div class="col-12 pl-0">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>{{ $t('head_office_address') }}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.address">
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>{{ $t('website') }}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.website">
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                    <div class="mt-4 d-flex justify-content-between align-items-center">
                      <div class="text-left"></div>
                      <button @click="updateOrg" id="validateButton-pass" class="btn btn-round cfa-btn-primary"
                        type="submit">
                        {{$t('validate')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="page-content" style="">
              <!--section 0-->

              <div class="row">
                <div class="col-12 col-md-6 ">
                  <div class="cfa-block p-4 mt-4">
                    <div>
                      <div class="mb-4">
                        <h5>
                          {{ $t('profile_administrative_contact') }}
                        </h5>
                      </div>
                      <section>
                        <form>
                          <div>
                            <div class="col-12">
                              <div class="form-group ">
                                <p class="mb-2"><strong>{{ $t('names') }}</strong></p>
                                <input class="form-control" type="text" v-model="orgData.name_administratif">
                              </div>
                            </div>
                            <div class="row d-flex align-items-center">
                              <div class="col-6">
                                <div class="form-group ">
                                  <p class="mb-2"><strong>{{ $t('email') }}</strong></p>
                                  <input class="form-control" type="text" v-model="orgData.email_administratif">
                                </div>
                              </div>
                              <div class="col-6 pl-0">
                                <div class="form-group py-3 pe-0 pe-md-2">
                                  <p class="mb-2"><strong>{{$t('telephone')}}</strong></p>
                                  <input class="form-control" type="text" v-model="orgData.phone_administratif">
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                      <div class="col-12 pl-0">
                        <div class="form-group py-3 pe-0 pe-md-2">
                          <p class="mb-2"><strong>&nbsp;</strong></p>
                          <div class="d-flex justify-content-end">
                            <button @click="updateOrg" id="validateButton-pass" class="btn btn-round cfa-btn-primary"
                              type="submit">
                              {{$t('validate')}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 ">
                  <div class="cfa-block p-4 mt-4">
                    <div>
                      <h5>{{$t('billing_contact')}}</h5>
                      <div>
                        <form>
                          <div>
                            <div class="col-12">
                              <div class="form-group py-2 ps-md-2">
                                <p class="mb-2"><strong>Organisation Name</strong></p>
                                <input class="form-control" type="text" v-model="orgData.name">
                              </div>
                            </div>
                            <div class="row d-flex align-items-center">
                              <div class="col-6">
                                <div class="form-group ">
                                  <p class="mb-2"><strong>{{ $t('email') }}</strong></p>
                                  <input class="form-control" type="text" v-model="orgData.email_facturation">
                                </div>
                              </div>
                              <div class="col-6 pl-0">
                                <div class="form-group py-3 pe-0 pe-md-2">
                                  <p class="mb-2"><strong>{{$t('telephone')}}</strong></p>
                                  <!-- <input class="form-control" type="text" v-model="orgData.phone"> -->
                                  <input-phone @updateNumber="(number) => orgData.phone=number" :init-value="orgData.phone.startsWith('+')?orgData.phone : `+${orgData.phone}`"  />
                                </div>
                              </div>
                            </div>
                            <div class="col-12 pl-0">
                              <div class="form-group py-3 pe-0 pe-md-2">
                                <p class="mb-0"><strong>&nbsp;</strong></p>
                                <div class="d-flex justify-content-end">
                                  <button @click="updateOrg" id="validateButton-pass"
                                    class="btn btn-round cfa-btn-primary" type="button">
                                    {{$t('validate')}}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
              <div class="preloader_center d-flex flex-column">
                <img src="@/assets/img/svg/cfa-ud-assets-13.svg" alt="cfa empty section" style="width: 22em;">
                <h3 class="pl-3 mt-3">Vous n'appartenez à aucune organisation !</h3>
              </div>
            </div>
          </div>
          <!--<modal v-if="showModal" @close="showModal = false">
            <template v-slot:header>
              <h3>Choisissez une avatar</h3>
            </template>
<template v-slot:body>
              <div class="col-12 pl-0">
                <div class="form-group ">
                  <label class="control-label" for="inputPassnew">Avatar</label>
                  <div class="input-group">
                    <input class="btn cfa-btn-primary modal-default-button" type="file" name="" id="" value="SELECTIONNER UN AVATAR">
                  </div>
                </div>
              </div>
            </template>
<template v-slot:footer></template>
</modal>-->
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <!-- <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div> -->
            <div class="loader-pulse">
                <div class="logo-placeholder">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <span class="loader-text">{{ $t('loading') }}</span>
                </div>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <scalable-modal @callback="updateCode" :break="exitCodeModal" :uid="'code-confirmation-modal'" @close="codeConfirmBox=false" :open="codeConfirmBox" :advancedControl="true" validationButtonText="Oui" exitButtonText="Non" displayMode="flex" :style="'width: 30em; height: 22em;'">
            <template v-slot:content>
                <!-- <p>{{ $t('delete_consent') }}<span style="color: red;">{{ $t('delete_consent_red') }}</span></p> -->
                <div class="col-12 pl-0">
                    <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Êtes-vous sûr(e) de vouloir régénérer le code d'invitation de votre organisation?</strong></p>
                        <!-- <input class="form-control" type="text" autocomplete="new-password" v-model="name_check"> -->
                    </div>
                </div>
            </template>
        </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import { getAPI } from "@/axios-api";
import VueDatePicker from "@vuepic/vue-datepicker";
import AppTheme from "@/components/shared/AppTheme.vue";
//import countryCode from '@/components/shared/input/countryCode.vue';
import InputPhone from '@/components/shared/input/InputPhone.vue';
import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
import utils from "@/composable/utils"

export default {
  name: 'CompanyView',
  components: {
    VueDatePicker,
    CFAFooter,
    CFANav,
    AppTheme,
    //countryCode
    InputPhone,
    ScalableModal 
  },
  data() {
    return {
      waitingAPIResponse: true,
      orgData: null,
      activityData: null,
      modulesData:[],
      modules:[],
      orgDataLogo: null,
      alertString: '',
      isOrg: true,
      uploadPending: false,
      subscriptionData: [],
      silentRefresh: null,
      // modules: [
      //   { id: 1, name: 'Module 1', visible: true },
      //   { id: 2, name: 'Module 2', visible: true },
      //   { id: 3, name: 'Module 3', visible: true },
      //   { id: 4, name: 'Module 4', visible: true },
      //   { id: 5, name: 'Module 5', visible: true },
      //   { id: 6, name: 'Module 6', visible: true },
      //   { id: 7, name: 'Module 7', visible: true },
      //   { id: 8, name: 'Module 8', visible: true },
      //   { id: 9, name: 'Module 9', visible: true },
      //   { id: 10, name: 'Module 10', visible: false },
      //   { id: 11, name: 'Module 11', visible: false },
      //   { id: 12, name: 'Module 12', visible: false },
      // ],
      selectedModules: [],
      dateEnd: null,
      lastTransactionPlan: "",
      remain_days: "",
      expiration_date: "",
      phone: "",
      codeConfirmBox: false,
      exitCodeModal: null
    }
  },
  methods: {
    /*changeCode (country) {
        
        this.dialCode = '+' + country[2] + ''
        this.formData.country = country[0];
        
        console.log(country)
        
    },*/
    hexToRgb(hex) {
      // Remove '#' character if present
      hex = hex.replace('#', '');
      // Convert hex to RGB
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    },
    retrieveOrg() {
      getAPI.get(`organisations/${localStorage.organisation}/`)
        .then(response => {
          if (response.status == 200) {
            this.organisationData = response.data
            this.primary = this.organisationData.primary_color;
            this.secondary = this.organisationData.secondary_color;
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            //this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            //this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    saveColor() {
      const payload = {
          primary_color: this.primary_color,
          secondary_color: this.secondary_color,
          tertiary_color: this.tertiary_color,
      };
      getAPI.put(`organisations/change_color/${localStorage.organisation}/`, payload)
        .then(response => {
          if (response.status === 200) {
            console.log('response', response);
            if(response.data.data){
              // localStorage.setItem('primaryColor', this.primary);
              // localStorage.setItem('secondaryColor', this.secondary);
              localStorage.setItem('tertiaryColor', response.data.data.tertiary_color);
            }
            this.alertString = "Color changed successfully |alert-yes|5000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('respose',error.response);
            this.alertString = `Upgrade your plan for change color|alert-no|5000::`+Date.now()
          }
          else if (error.request) {
            this.alertString = "The request was made but no response was received. Please check your network.|alert-no|8000::"+Date.now()
          }
          else {
            this.alertString = "Oups ! something went wrong.|alert-no|5000::"+Date.now()
          }
        })
    },
    activeColor() {
      const primaryColor = this.primary;
      if(primaryColor){
        const primaryRgb = this.hexToRgb(primaryColor);
        const pr = primaryRgb.r;
        const pg = primaryRgb.g;
        const pb = primaryRgb.b;
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-r', pr);
        document.documentElement.style.setProperty('--primary-color-g', pg);
        document.documentElement.style.setProperty('--primary-color-b', pb);
      }
    
      //secondary
      const secondaryColor = this.secondary;
      if(secondaryColor){
        const secondaryRgb = this.hexToRgb(secondaryColor);
        const sr = secondaryRgb.r;
        const sg = secondaryRgb.g;
        const sb = secondaryRgb.b;
        document.documentElement.style.setProperty('--secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--secondary-color-r', sr);
        document.documentElement.style.setProperty('--secondary-color-g', sg);
        document.documentElement.style.setProperty('--secondary-color-b', sb);
      }
    },
    async setPrimaryColor() {
      this.primary_color = this.primary;
      this.saveColor()
      const actualColor = this.primary;
      const rgb = this.hexToRgb(this.primary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--primary-color', actualColor);
      document.documentElement.style.setProperty('--primary-color-r', r);
      document.documentElement.style.setProperty('--primary-color-g', g);
      document.documentElement.style.setProperty('--primary-color-b', b);
    },
    async setSecondaryColor() {
      this.secondary_color = this.secondary;
      this.saveColor();
      const rgb = this.hexToRgb(this.secondary);
      // Update separate RGB values
      const r = rgb.r;
      const g = rgb.g;
      const b = rgb.b;
      document.documentElement.style.setProperty('--secondary-color', this.secondary);
      document.documentElement.style.setProperty('--secondary-color-r', r);
      document.documentElement.style.setProperty('--secondary-color-g', g);
      document.documentElement.style.setProperty('--secondary-color-b', b);
    },
    updateCode() {
      /*const userInput = prompt("Êtes-vous sûr(e) de vouloir régénérer le code d'invitation de votre organisation? (Oui/Non)");
      if (userInput && userInput.toLowerCase() === "oui") {
      }*/
      this.waitingAPIResponse = true
      getAPI.patch(`organisations/${localStorage.organisation}/`, {
        code: ""
      })
        .then((response) => {
          //let msg = 'Code changé avec succès'
          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
          console.log(response)
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
          this.orgData.code = response.data.code
          this.exitCodeModal = Date.now()
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            // //let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
    },
    canSetDate() {
      return this.subscriptionData?.length != 0 && localStorage.type_account == "organisation";
    },
    getModule() {
      this.waitingAPIResponse = true
      getAPI.get('modules/all')
        .then(response => {
          console.log(response)
          if (response.status === 200) {
            console.log("dataModule:", response.data.data);
            this.modulesData = response.data.data;
            let org = localStorage.getItem('organisation')
            // Flatten and assign data to modules list
            this.modules = this.modulesData.map(module => {
              const isVisible = module.is_visible_for.some(uuid => uuid === org);
              return {
                id: module.id,
                name: 'MODULE ' + module.number + ' - ' + module.title,
                is_visible_for: module.is_visible_for,
                visible: isVisible
              };
            });

            console.log("Modules: ", this.modules);
          }
          else {
              // //let msg = 'Oups ! something went wrong.'
              ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
              this.waitingAPIResponse = false
          }, 2000);
          })
            .catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    ////let msg = 'Oups ! something went wrong.'
                    ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                this.waitingAPIResponse = false
            })
    },
    toggleVisibility(module) {
      module.visible = !module.visible;
    },
updateVisibilities(module, index = 0) {
      console.log(module)
      //this.waitingAPIResponse = true
        if(module.visible == true){
          //console.log(module);
          getAPI.patch(`modules/make_module_visible/${module.id}?is_visible=false`).then(response => {
            if (response.status == 200) {
              console.log('response', response);
              this.alertString = `Le module ${index + 1} n'est plus visible|alert-yes|5000::`+Date.now()
            }
            else {
              this.alertString = "Une erreure s'est produite |alert-no|5000::"+Date.now()
            }
            this.getModule()
            setTimeout(() => {
                //this.waitingAPIResponse = false
            }, 2000);
           }).catch(() => {
             this.alertString = "Une erreur.|alert-no|5000::"+Date.now()
            })
        }
        else{
          getAPI.patch(`modules/make_module_visible/${module.id}?is_visible=true`).then(response => {
          if (response.status == 200) {
            console.log('response', response);
            this.alertString = `Le module ${index + 1} est visible|alert-yes|5000::`+Date.now()
         }
         else {
          this.alertString = `Quelque chose s'est mal passé.|alert-no|5000::`+Date.now()
         }
          this.getModule()
          setTimeout(() => {
            this.waitingAPIResponse = false
            }, 2000);
          }).catch(() => {
            this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
          })
        }
    },







  updateVisibility(modules) {
    console.log("test", modules);

    // Initialize request payloads
    //const invisibleModules = [];
    //const visibleModules = [];

    // Loop through modules and group them based on visibility
    /*for (let i = 0; i < modules.length; i++) {
        if (modules[i].visible) {
            invisibleModules.push(modules[i].id);
        } else {
            visibleModules.push(modules[i].id);
        }
    }*/

    // Function to send API request
    const sendVisibilityUpdate = (moduleIds, isVisible) => {
        if (moduleIds.length === 0) return;

        getAPI.patch(`modules/make_visible`, {
            modules_ids: moduleIds,
            is_visible: isVisible
        })
        .then(response => {
            if (response.status === 200) {
                console.log('response', response);
                this.alertString = `Les modules sont maintenant ${isVisible === "false" ? "invisibles" : "visibles"} |alert-yes|5000::` + Date.now();
            } else {
                this.alertString = "Une erreur s'est produite |alert-no|5000::" + Date.now();
            }
            this.getModule();
        })
        .catch(() => {
            this.alertString = "Une erreur.|alert-no|5000::" + Date.now();
        });
    };

    // Send API requests for both visible and invisible module updates
    //sendVisibilityUpdate(invisibleModules, "false");
    //sendVisibilityUpdate(visibleModules, "true");
    if (modules.every(module => module.visible)) {
      sendVisibilityUpdate(modules.map(module => module.id), "false");      
    } else {
      sendVisibilityUpdate(modules.map(module => module.id), "true");
    }
},



    getOrganisationData() {
      let org = localStorage.getItem('organisation')
      console.log(org)
      if (org != null) {
        getAPI.get(`organisations/${org}/`)
          .then(response => {
            if (response.status == 200) {
              this.orgData = response.data
              console.log('orgData', this.orgData);
              if(this.orgData.activity) {
                getAPI.get(`activity/${this.orgData.activity}/`)
                .then(res => {
                    if (res.status == 200) {
                      this.activityData = res.data;
                      console.log('activity data', this.activityData)
                    }
                    setTimeout(() => {
                      this.waitingAPIResponse = false
                    }, 2000);
                    //this.alertString = `Détails de l'organisation chargés avec succès|alert-yes|8000::`+Date.now()
                  })
                  .catch(error => {
                    console.log(error)
                    if (error.response) {
                      this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                      let msg = 'The request was made but no response was received. Please check your network.'
                      this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                      let msg = 'Oups ! something went wrong.'
                      this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false
                  });
              }
              // //let msg = 'Oups ! something went wrong.'
              ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
      }
      else {
        this.isOrg = false
        this.orgData = null
      }
    },
    openFileSelector() {
      this.$refs.fileSelector.click();
    },
    async uploadImage() {
      const file = this.$refs.fileSelector.files[0];
      const formData = new FormData();
      //formData.append('logo', file);
      const maxSizeInMB = 2; // 2 MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (file) {
        if (file.size > maxSizeInBytes) {
          console.log("File is within the size limit. Ready to upload.");
        } else {
          formData.append('logo', file);

        }
      }
      this.uploadPending = true
      //return
      //eslint-disable-next-line
      getAPI.put(`organisations/upload_logo/${localStorage.organisation}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((payload) => {
        console.log(payload);
        this.orgDataLogo = payload.data.data.logo
        location.reload();
        //this.alertString = "Profil mis à jour avec succès.|alert-yes|5000::"+Date.now()
        this.silentRefresh = Date.now();
        this.uploadPending = false
      }).catch((err) => {
        //this.alertString = "Fichier trop large.|alert-no|5000::"+Date.now()
        this.uploadPending = false
        console.log(err)
      });
    },
    updateOrg() {
      if (this.orgData.phone.startsWith('+')) {
        this.orgData.phone = this.orgData.phone.slice(1)
      }
      const orgDataClone = { ...this.orgData };
      delete orgDataClone.logo;
      delete orgDataClone.favicon;
      utils.delObjectProps(orgDataClone)
      console.log('organisation data', orgDataClone);

      getAPI.patch(`organisations/${localStorage.organisation}/`, orgDataClone).then(response => {
        console.log(response)
        if (response.status == 200) {
          console.log('response', response);
          this.alertString = "Informations mis a jour avec succès|alert-yes|5000::"+Date.now()
          this.getOrganisationData();
        }
        else {
          this.alertString = "Quelque chose s'est mal passé.|alert-no|5000::"+Date.now()
        }
      }).catch(() => {
        this.alertString = "Veuillez remplir convenablement les champs.|alert-no|5000::"+Date.now()
      })
    },
    retrieveUserTransactions() {
      this.waitingAPIResponse = true
      getAPI.get(`/transactions?user_id=${localStorage.getItem('user')}`)
        .then(response => {
          if (response.status == 200) {
            this.transactionsData = response.data

            this.transactions = []

            const lastTransaction = this.transactionsData[this.transactionsData.length - 1]

            this.lastTransactionPlan = lastTransaction.plan.name;

          }
          else {
            ////let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        }).catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            ////let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
    },
    retrieveUserSubscription() {
      this.waitingAPIResponse = true
      getAPI.get(`subscriptions/find-subscription`).then(response => {
        if (response.status == 200) {
          this.subscriptionData = response.data.data

          this.remain_days = this.subscriptionData.remain_days

          const created_at = new Date(this.subscriptionData.created_at)
          created_at.setDate(created_at.getDate() + this.remain_days);

          const expirationDate = created_at.toISOString();

          this.expiration_date = expirationDate

          this.orgDataLogo = this.subscriptionData.organisation.logo;

          //console.log("Tatata: ", this.subscriptionData)
        }
        else {
          // //let msg = 'Oups ! something went wrong.'
          ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          // //let msg = 'Oups ! something went wrong.'
          ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    },
    convertDate(dateString, dateOnly = false) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString();
      const formattedTime = date.toLocaleTimeString();
      if (!dateOnly) {
        return `<b>${formattedDate} à ${formattedTime}</b>`;
      }
      return `<b>${formattedDate}</b>`;
    },
    copyContent() {
      // Copy content of the div
      const divContent = this.$refs.myDiv.innerText;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(divContent);
      }
      else {
        const tempInput = document.createElement('input');
        tempInput.value = divContent;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
      }

      // Show notification
      //this.alertString = "Code copié|alert-middle|3000::"+Date.now()
      this.$swal({
        title: 'Code copié avec succès',
        html: "<p style='text-align: justify'>Vous pouvez partager ce code avec un utilisateur qui pourra s'inscrire avec ce dernier pour rejoindre votre organisation. Ses frais de sensibilisation seront ainsi pris en charge par votre organisation et vous pourrai faire le suivi de son evolution sur l'ensemble des modules disponibles.</p>",
        type: 'warning',
        showCancelButton: false,
        confirmButtonText: "D'accord !"
      });
    },
    getDashboardData() {
      this.waitingAPIResponse = true
      getAPI.get(`other/dashboard/`)
        .then(response => {
          if (response.status == 200) {
            this.dateEnd = response.data.data?.deadline
          }
          else {
            // //let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            //let msg = 'The request was made but no response was received. Please check your network.'
            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            ////let msg = 'Oups ! something went wrong.'
            ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
    },
  },
  mounted() {
    this.getDashboardData()
    this.getModule();
    this.retrieveUserSubscription();
    this.getOrganisationData();
    this.retrieveUserTransactions();
  },
  watch: {
    dateEnd: function (value) {
      this.waitingAPIResponse = true
      getAPI.patch(`organisations/${localStorage.organisation}/`, {
        deadline_training: value,
      }).then(response => {
        if (response.status == 200) {
          this.subscriptionData = response.data
          this.retrieveUserSubscription();
        }
        else {
          ////let msg = 'Oups ! something went wrong.'
          ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          ////let msg = 'Oups ! something went wrong.'
          ////this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    }
  }
}
</script>

<style scoped lang="scss" >
//@import "../../assets/scss/tailwinds.scss";

/* General styles for the toggle switch container */
.toggle-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* The hidden checkbox input */
.toggle-input {
  display: none;
}

/* The track (background of the toggle) */
.toggle-track {
  width: 2.75rem; /* 11 * 0.25rem */
  height: 1.5rem; /* 6 * 0.25rem */
  background-color: #e5e7eb; /* bg-gray-200 */
  border-radius: 9999px; /* rounded-full */
  position: relative;
  outline: none;
  transition: background-color 0.3s ease;
}

/* Focus ring */
.toggle-input:focus + .toggle-track {
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.3); /* ring-4 + ring-blue-300 */
}

/* The thumb (the circular knob) */
.toggle-thumb {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 1.25rem; /* 5 * 0.25rem */
  height: 1.25rem; /* 5 * 0.25rem */
  background-color: #fff; /* bg-white */
  border: 1px solid #d1d5db; /* border-gray-300 */
  border-radius: 9999px; /* rounded-full */
  transition: all 0.3s ease; /* after:transition-all */
}

/* Checked state styles */
.toggle-input:checked + .toggle-track {
  background-color: #2563eb; /* bg-blue-600 */
}

.toggle-input:checked + .toggle-track .toggle-thumb {
  transform: translateX(100%);
  border-color: #fff; /* after:border-white */
}


.entreprise-block {
  min-height: 15.5em;
}

.time-block {
  min-height: 13em;
}

.license-block {
  min-height: 30.3em;
}

.cfa-page {}

.cfa-block {
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222, 234, 250, 0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}

.ticket-list tr {
  border-color: transparent !important;
}

.codeStyle {
  padding: 0.5em;
  background: #f5eded;
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 400;
  display: inline-block;
  cursor: copy;
}

.gFolder-container {
  background-color: #cef1f3;
  border-radius: 0.5em;
  height: 4.3em;
  width: 4.6em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gBlock {
  align-items: center;
  justify-content: start;
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 8em !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-image: url('https://laravel.pixelstrap.com/cuba/build/assets/widget-bg-a2f1cbe6.png');
  background-size: cover;
}

.gBlock2 {
  align-items: center;
  justify-content: start;
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 18.5em !important;
  box-shadow: none;
  background-image: none !important;
  background-color: #cef1f3 !important;
}

.gBlock div:last-of-type p {
  margin: 0;
}

.gBlock div:last-of-type p:first-of-type {
  font-weight: 400;
  font-size: 1.4em;
}

.welcome-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #132B4D;
}

.card {
  margin-bottom: 20px;
  border: none;
  transition: all .3s ease;
  letter-spacing: .5px;
  border-radius: 15px;
  box-shadow: 0 9px 20px #2e235e12;
}

.o-hidden {
  overflow: hidden;
}

.card .card-body {
  padding: 20px;
  background-color: transparent;
}

.welcome-card .welcome-img {
  height: 100%;
  position: absolute;
  right: 0;
  bottom: -5px;
}

@media (max-width: 1700px) {
  .welcome-card .welcome-img {
    bottom: -5px;
  }
}

@media (max-width: 1780px) {
  .welcome-card .welcome-img {
    height: 100%;
    bottom: -5px;
  }
}

.course-box {
  overflow: hidden;
  min-height: 170px !important;
}

.square-group {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(-1);
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.square-group .warning {
  background: #c8fcff;
}

.square-group .square-1 {
  width: 12px;
  height: 18px;
}

.square-group .primary {
  background: var(--primary-color);
}

.square-group .warning1 {
  background: #FFD497;
}

.square-group .danger {
  background: #f54132;
}

.square-group .light {
  background: #E6E8EF;
}

.square-group .warning {
  background: #c8fcff;
}

.square-group .success {
  background: #54BA4A;
}

.square-group .square-6,
.square-group .square-7 {
  position: absolute;
  width: 4px;
  height: 4px;
}

.square-group .square-6,
.square-group .square-7 {
  position: absolute;
  width: 4px;
  height: 4px;
}

.square-group .success {
  background: #54BA4A;
}

.course-widget {
  display: flex;
  gap: 8px;
}

.course-widget .course-icon {
  position: relative;
  width: 54px;
  height: 54px;
  clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);
  background: linear-gradient(310.31deg, #FF3364 14.71%, #FF7F9E 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-light {
  color: #52526c;
  opacity: .8;
}

.no-wrap {
  white-space: nowrap;
}

.course-widget .btn-light {
  background: #f2f3f7 !important;
  border: none;
}

.course-widget a {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  margin-top: 5px;
  top: 8px;
}

.card {
  margin-bottom: 20px;
  border: none;
  transition: all .3s ease;
  letter-spacing: .5px;
  border-radius: 7px;
  box-shadow: 0 9px 20px #2e235e12;

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  min-height: 130px;
}

.card .card-header.card-no-border {
  border-bottom: none !important;
}

.get-card .card-header {
  padding-left: 20px;
  padding-right: 20px;
}

.card .card-header {
  background-color: #fff;
  padding: 30px;
  border-bottom: 1px solid #ecf3fa;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.card .card-body {
  padding: 30px;
  background-color: transparent;
}

.btn-outline-white {
  border: 1px solid #132B4D;
  color: #132B4D;
}
</style>