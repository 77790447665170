<template>
    <div class="loader-container">
        
        <!-- <div class="loader">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div> -->
        <div class="demo-container">
            <div class="loader-pulse">
                <div class="logo-placeholder">
                    <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                    <span class="loader-text">{{ text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
    name: 'ScreenLoader',
    props: {
        text: {
        type: String,
        default: 'Chargement'
        }
    },
    computed : {
    //...mapGetters(["getPageLoad"])
    },
}
</script>

<style>
.loader-container {
position: fixed;
inset: 0;
display: grid;
place-items: center;
background: white;
z-index: 9999;
}

.loader {
position: relative;
width: 100px;
height: 100px;
}

.circle {
position: absolute;
width: 100%;
height: 100%;
border-radius: 50%;
border: 4px solid transparent;
border-top-color: var(--primary-color);
animation: spin 1s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
}

.circle:nth-child(1) {
animation-delay: 0s;
}

.circle:nth-child(2) {
width: 80%;
height: 80%;
top: 10%;
left: 10%;
/* border-top-color: #2563eb; */
border-top-color: rgba(var(--primary-color-r), var(--primary-color-g), var(--primary-color-b), 0.7);
animation-delay: 0.1s;
animation-direction: reverse;
}

.circle:nth-child(3) {
width: 60%;
height: 60%;
top: 20%;
left: 20%;
/* border-top-color: #3b82f6; */
border-top-color: rgba(var(--primary-color-r), var(--primary-color-g), var(--primary-color-b), 0.5);
animation-delay: 0.2s;
}

.loader-text {
position: absolute;
width: 100%;
text-align: center;
top: 120%;
font-weight: 500;
font-size: 0.875rem;
color: var(--primary-color);
letter-spacing: 0.2em;
text-transform: uppercase;
animation: pulse 2s ease-in-out infinite;
}

@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

@keyframes pulse {
0%, 100% {
    opacity: 0.5;
}
50% {
    opacity: 1;
}
}

.demo-container {
    
    background: white;
    padding: 2rem;
    border-radius: 12px;
    display: grid;
    place-items: center;
    height: 300px;
    /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1); */
}

/* Style 1: Pulse with Ring */
.loader-pulse {
    position: relative;
    width: 100px;
    height: 100px;
}

.loader-pulse::after {
    content: '';
    position: absolute;
    inset: -15px;
    border: 4px solid var(--primary-color);
    border-radius: 50%;
    animation: pulse-new 1.5s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
}
 /* Placeholder logo */
.logo-placeholder {
    width: 100%;
    height: 100%;
    /* background: var(--primary-color); */
    border-radius: 12px;
    display: flex;
    flex-grow: column;
    justify-content: center;
    align-items: center;
}
.logo-placeholder img {
    margin-left: 15px;
}

@keyframes pulse-new {
    0% { transform: scale(0.95); opacity: 1; }
    100% { transform: scale(1.2); opacity: 0; }
}
</style>