/*
import axios from "axios";
import router from '@/router';

const BaseURL = process.env.VUE_APP_BASE_URL

//const BaseURL =
  //process.env.NODE_ENV === "development"
    //? "https://api-dev-cfa.cyberspector.xyz/api/"
    //: "https://api-dev-cfa.cyberspector.xyz/api/";

//Guest
const guestAPI = axios.create({
  baseURL: BaseURL,
});

//Authorise
const getAPI = axios.create({
  baseURL: BaseURL,
  headers:
    localStorage.getItem("token") !== undefined
      ? { Authorization: `Bearer ${localStorage.getItem("token")}` }
      : {},
});

//Interceptor
getAPI.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // console.log(config);
    // console.log(config.headers);
    // console.log(config.method);
    // console.log(config.url);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function promptTokenExpire() {
         //localStorage.clear();
        //const currentPath = window.location.pathname;
        localStorage.setItem('currentPath', window.location.pathname); // Save current path
        router.push('/login'); // Redirect to login
  
  let a = document.createElement("a");
  a.setAttribute("href", "/login");
  a.click();
  a.remove();
}

getAPI.interceptors.response.use(
  function (response) {
    console.log(response)
    //return response;
  },
  function (error) {
    console.log(error)
    if (
      error.response.data.code === "token_not_valid" ||
      error.response.data.detail === "Given token not valid for any token type"
    ) {
      getAPI
        .post("accounts/login/refresh/", {
          refresh: localStorage.getItem("refresh_token"),
        })
        .then((response) => {
          // try get new access token
          if (response.status === 200) {
            const token = response.data.access;
            localStorage.setItem("token", token);
          } else {
            // prompt if refresh token expire
            promptTokenExpire();
          }
        })
        .catch(() => {
          // prompt if refresh token expire
          promptTokenExpire();
        });
    } else {
      return Promise.reject(error);
    }
  }
);

export { getAPI, guestAPI, BaseURL };
*/

import axios from "axios";
//eslint-disable-next-line
import router from "@/router";
import store from "@/store/store"; // Import Vuex store
const BaseURL = process.env.VUE_APP_BASE_URL;

// Create API instances
const guestAPI = axios.create({ baseURL: BaseURL });
const getAPI = axios.create({ baseURL: BaseURL });

// Function to refresh token
let isRefreshing = false;
let refreshSubscribers = [];

async function refreshToken() {
  if (!localStorage.getItem("refresh_token")) {
    console.log("no refresh token")
    return Promise.reject("No refresh token available.");
  }

  if (isRefreshing) {
    return new Promise((resolve) => {
      refreshSubscribers.push(resolve);
    });
  }

  isRefreshing = true;

  try {
    const response = await axios.post(`${BaseURL}accounts/login/refresh/`, {
      refresh: localStorage.getItem("refresh_token"),
    });
    console.log(response)
    if (response.status === 200) {
      const newAccessToken = response.data.access;
      localStorage.setItem("token", newAccessToken);

      // Retry all pending requests
      refreshSubscribers.forEach((callback) => callback(newAccessToken));
      refreshSubscribers = [];
      
      return newAccessToken;
    }
  } catch (error) {
    promptTokenExpire();
    return Promise.reject(error);
  } finally {
    isRefreshing = false;
  }
}

// Handle expired token
function promptTokenExpire() {
  localStorage.clear();
  localStorage.setItem("currentPath", window.location.pathname);
  router.push("/login");
}

// Attach token to requests
getAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for token expiration handling
getAPI.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log("error here intercept", error)
    if (!error.response) return Promise.reject(error);
    
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      (error.response.data.code === "token_not_valid" ||
        error.response.data.detail === "Given token not valid for any token type")
    ) {
      try {
        const newToken = await refreshToken();
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest); // Retry the failed request
        }
      } catch {
        store.dispatch("loader/triggerLoading", false);
        promptTokenExpire();
      }
    }
    if (error.response.status == 403) {
      //console.log("response from axios apiiiiiiii")
      //Upgrade your plan to perform this action.
      if (error.response.data?.message?.startsWith("You do not have permission") || error.response.data?.detail?.startsWith("You do not have permission")) {
        store.dispatch("error/triggerError", "Vous n'avez pas la permission d'effectuer cette action");
      } else if (error.response.data?.detail?.startsWith("Upgrade your plan")) {
        store.dispatch("error/triggerError", "Changer votre formule pour beneficier de cette fonctionnalite");
      }
    }
    return Promise.reject(error);
  }
);

export { getAPI, guestAPI, BaseURL };
