<template>
    <div class="cfa-root">
      <c-f-a-nav  :alert="alertString">
        <template v-slot:title>
          <div class="w-100 pt-2 text-start mt-0 mb-0 camp-detail-head" >
              <h1 class="cfa-section-title" style="color:white;font-weight:300;margin-top:20px;margin-bottom: 50px;margin-left:40px">Micro-apprentisage</h1>
              <h2 style="color:white;margin-left:40px;">Rapport de la campagne: <span class="">{{allModules.name}}</span> </h2>
                      <div style="display:flex;justify-content:space-between;margin-left:30px;">
              <button style="background-color:#E7EAED;margin-left:30px;margin-top:20px;color:#06BBC4;width:211px;border:1px solid #06BBC4; height:40px;border-radius:5px;">Campagne d’email</button>
              <button class="btn cfa-btn-primary mx-1 ml-2 " :disabled="allModules.status == 'In Progress' || allModules.status == 'Unavailable' || allModules.status== 'created' " @click="getCampaignReport()"><i class="fa fa-download white-color" ></i> <span class="ml-3">Générez le rapport</span></button></div>
          </div>
        </template>
        <template v-slot:content>
          <div v-if="!waitingAPIResponse" class="cfa-page">
              
             
              <div class="tab mt-1">
                
                  
                  <div class="tab-body" style="margin-bottom: 1em;border: none;">
                    <div class="row   pt-3   mt-2" style="margin:auto;display:flex;justify-content:space-evenly;">
                <div class="col-md-3 details">
                    
                   <h6 style="font-size:15px;font-weight:50;margin-left:30px;margin-top:20px;">Email envoyés</h6>
                   <h5 style="color:#06BBC4;font-size:24px;margin-left:30px;margin-top:30px;">{{templateData.total_sent}}</h5>
                </div>
                <div class="col-md-3 details">
                    <div style="display:flex;justify-content:space-between;">
                        <h6 style="font-size:15px;font-weight:50;margin-left:30px;margin-top:20px;">Email ouverts</h6>
                        <h5 style="font-size:15px;font-weight:600;">...</h5>
                    </div>
                    <h5 style="color:#F7A92B;font-size:24px;margin-left:30px;margin-top:30px;">{{templateData.total_opened}}</h5>
                </div>
                <div class="col-md-3 details">
                    <div style="display:flex;justify-content:space-between;">
                        <h6 style="font-size:15px;font-weight:50;margin-left:30px;margin-top:20px;">Visualisation</h6>
                        <h5 style="font-size:15px; font-weight:600;">...</h5>
                    </div>
                    <h5 style="color:#FF2836;font-size:24px;margin-left:30px;margin-top:30px;">{{templateData.total_viewed}}</h5>
                </div>
              </div>
              <div class="row pt-3  mt-4" style="background-color:#fff;display:flex;justify-content:space-evenly;max-width:1200px;  margin:auto;"> 
                 
                 <div class="col-md-3 details text-center">
                     <DoughnutChart
                     :classValue="'chartTextStyle3'"
                     :width="100"
                     :height="100"
                     :valueCountUp="true"
                     :percent=templateData?.sent_rate?.toString().substring(0,3)
                     :percent-to-display="templateData?.sent_rate?.toString().substring(0,3)"
                     :visibleValue="true"
                     foregroundColor="#06BBC4"
                     backgroundColor="#E6F8F9"
                     :strokeWidth="30"
                   />
                     <h6 style="font-size:15px;font-weight:50;margin-left:50px;margin-top:20px;">Email envoyés</h6>
                     
                  </div>
                  <div class="col-md-3 details text-center">
                     <DoughnutChart
                     :classValue="'chartTextStyle3'"
                     :width="100"
                     :height="100"
                     :valueCountUp="true"
                     :percent=templateData?.open_rate?.toString().substring(0,3)
                     :percent-to-display="templateData?.open_rate?.toString().substring(0,3)"
                     :visibleValue="true"
                     foregroundColor="#F7A92B"
                     backgroundColor="#FEF6EA"
                     :strokeWidth="30"
                   />
                   <h6 style="font-size:15px;font-weight:50;margin-left:50px;margin-top:20px;">Email ouverts</h6>
                  </div>
                  <div class="col-md-3 text-center ">
                     <DoughnutChart
                     :classValue="'chartTextStyle3'"
                     :width="100"
                     :height="100"
                     :valueCountUp="true"
                     :percent=templateData?.view_rate?.toString().substring(0,3)
                     :percent-to-display="templateData?.view_rate?.toString().substring(0,3)"
                     :visibleValue="true"
                     foregroundColor="#FF2836"
                     backgroundColor="#FFEAEB"
                     :strokeWidth="30"
                   />
                   <h6 style="font-size:15px;font-weight:50;margin-left:50px;margin-top:20px;">Visualisation</h6>
                  </div>
               </div>

              <div class="row pt-3 mt-5" style="background-color:#fff;border-radius:6px;margin:auto;max-width:1200px;">
                <div>
                          <Table :hide-control="(campaignsAll.length == 0) ? true :  false" :i="4" :columns="users" :data="campaignsAll"   :customized-default-image="true" :last-cell-width="0">
                              <template v-slot:defaultImage>
                                  <img src="@/assets/img/gif/campagne.gif" alt="empty content" style="width: 11em;"/>
                                  <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune uenregistrements !</span>
                              </template>
                          </Table>
                      </div>
              </div>
                      <!-- <div v-if="currentTab === 'campaign_activate'">
                          <Table :hide-control="(campaignsAll.length == 0) ? true :  false" :i="4" :columns="campaignsAllColumns" :data="campaignsAll" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" :last-cell-width="0">
                              <template v-slot:defaultImage>
                                  <img src="@/assets/img/gif/campagne.gif" alt="empty content" style="width: 11em;"/>
                                  <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune campagne !</span>
                              </template>
                          </Table>
                      </div> -->
                      
                      
                      
                  </div>
              </div>
         
          </div>
          
              <div v-if="waitingAPIResponse" class="cfa-page">
              <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                  <!-- <div class="preloader_center d-flex flex-column">
                      <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                      <p class="pl-3 fw-bold">chargement...</p>
                  </div> -->
                  <div class="loader-pulse">
                    <div class="logo-placeholder">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <span class="loader-text">{{ $t('loading') }}</span>
                    </div>
                </div>
              </div>
          </div>
          <!-- Modal zone-->
          <!-- deletion -->
          
          <scalable-modal @callback="confirmDeletion2" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-campaign-deletion-modal'" @close="openDeletionCampaignBox=false" :open="openDeletionCampaignBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                <template v-slot:content>
                    <p>{{$t('deleteCampaignMessage') }}<span style="color: red;">{{$t('irrevesibleMessage')}}</span></p>
                    <div class="col-12 pl-0">
                        <div class="form-group py-3 pe-0 pe-md-2">
                            <p class="mb-2"><strong>{{$t('deletecampaignconfirmation')}}</strong></p>
                            <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
                        </div>
                    </div>
                </template>
            </scalable-modal>
          <!-- create -->
          
          <!-- update -->
          
          <!-- create campaign -->
          <scalable-modal :disable-control="true" @callback="confirmCreate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-create" @close="newCampaignForm=false" :open="newCampaignForm"  :advancedControl="true" validationButtonText="Nouvelle campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptions"
                    :backButton="prevButtonOptions"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardCompleted"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>{{$t('names')}}</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="newCampaignName">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="newCampaignTitle">
                                          <option  selected disabled>Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group">{{ group.title }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignType" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="newCampaignSmsSenderName">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="newCampaignEmailSenderName">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="newCampaignEmailSignature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="newCampaignSmsSignature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="newCampaignGroupe">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="newCampaignService">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="newCampaignDateStart" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="newCampaigndefaultmsg">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
          <scalable-modal :disable-control="true" @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-update" @close="newcampaignUpdate=false" :open="newcampaignUpdate"  :advancedControl="true" validationButtonText="Nouvelle campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptionss"
                    :backButton="prevButtonOptionss"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardUpdated"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="campaignByid.name">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.micro_learning_id">
                                          <option  selected disabled>Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group">{{ group.title }}</option>
                                      </select>
                                  </div>
                                  
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="campaignByid.campaign_type" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="campaignByid.sms_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="campaignByid.sms_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.group">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.service">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="campaignByid.planification_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="campaignByid.default_message">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
        </template>
      </c-f-a-nav>
      <c-f-a-footer></c-f-a-footer>
    </div>
  
  </template>
  
  <script>
  
  import CFANav from '@/components/nav/CFANav.vue'
  import CFAFooter from '@/components/nav/CFAFooter.vue'
import Table from "@/components/shared/table/Table.vue";
  import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
  import 'form-wizard-vue3/dist/form-wizard-vue3.css'
  import Wizard from 'form-wizard-vue3'
  import DoughnutChart from '@/components/shared/chart/DoughnutChart.vue'
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  //import CourseCard from '@/components/shared/course/CourseCard3.vue'
  import {getAPI} from "@/axios-api";
  import { addHours } from 'date-fns';
  // import SsrCarousel from 'vue-ssr-carousel'
  // import ssrCarouselCss from 'vue-ssr-carousel/index.css'
  
  export default {
      name: 'PhishingView',
      components: {
          ScalableModal,
          DoughnutChart,
          Table,
          CFAFooter,
          //CourseCard,
          CFANav,
          Wizard,
          VueDatePicker,
          // SsrCarousel,
          // ssrCarouselCss,
      },
      data () {
          return {
              minDate: new Date(),
              minDateEnd: new Date(),
              waitingAPIResponse: true,
              waitingAPIResponse2: true,
              serviceAllData:[],
              newCampaignTitle:'',
              newcampaignUpdate:false,
              newCampaignSmsSenderName:'',
              newCampaignEmailSenderName:'',
              isConnected: true,
              alertString: null,
              currentTab: localStorage.tab_phish_active||'campaign_activate',
              newCampaigndefaultmsg:'',
              campaignsAll: [],
              campaignsAllOk: [],
              campaignsAllColumns: ['n°', 'Nom', 'Campaign Type', 'Date création', 'Actions'],
              campaignsAllToListen: ['Actions'],
              campaignByid:[],                
              groupAll: [],
              groupAllColumns: ['n°', 'Nom', 'Utilisateurs', 'Denière modification', 'Actions'],
              groupAllToListen: ['Actions'],
              microlearning:[],
              allModules: [],
              reportsAll: [],
              users:['Nom et Prénoms','Email','Status d’envoi','Date d’envoi','Date d’ouverture','Utilisateur Sensibilisè'],
              reportsAllColumns: ['n°', 'Campagne', 'Utilisateurs', 'Actions'],
              reportsAllToListen: ['Actions'],
              newCampaignEmailSignature:'',
              userGroupAll: [],
              userGroupAllColumns: ['Nom', 'Prénoms', 'Email', 'Position', 'Actions'],
              userGroupAllToListen: ['Actions'],
              newCampaignSmsSignature:'',
              openDeletionBox: false,
              tempDeleteID: null,
              tempDeleteName: null,
              updateGroupPayload: null,
              openUpdateGroup: false,
              name_check: null,
              callbackPayload: null,
              exitOrgModalDel: null,
              exitOrgUpdateModal: null,
              newFirstname: null,
              newLastname: null,
              newEmail: null,
              newPosition: null,
              newCampaignService:'',
              openDeletionCampaignBox: false,
              newCampaignForm: false,
  
              newCampaignName: null,
              newCampaignType: "default",
              newCampaignDateStart: null,
              newCampaignDateEnd: null,
              newCampaignGroupe: null,
              newCampaignEmailTemplate: null,
              newCampaignLandingTemplate: null,
              newCampaignTarget:'',
              currentTabIndex: 0,
              userList: [],
              selectedUser: [],
              credit_mail: '•••',
              credit_sms: '•••',
              total_credit_mail: '•••',
              total_credit_sms: '•••',
              openNewGroup: false,
              exitNewGroupModal: null,
              updateGroupData: {},
              updateGroupStatusRequest: true,
              flattenedIds: [],
              exitGroupUpdateModal: null,
              groupAllData: [],
              totalModule:0,
              groupeApiWaiting: false,
              groupApiWaitingData: [],
              groupeApiTemplateWaiting: false,
              groupApiTemplateWaitingData: [],
              defaultImage: " /images/defaultImage.png",
              waitingAPITemplate: true,
              waitingAPITemplate2: true,
              email1:'',
              email2:'',
              templateData: [],
              currentTemplateIndex: 0,
              currentTemplate: null,
  
              templateData1: [],
              currentTemplateIndex1: 0,
              currentTemplate1: null,
  
              templateChoosed: "",
              pageChoosed: "",
              privacyCheck: null,
              campaignWaiting: [],
  
              subscriptionData: [],
          }
      },
      methods: {
          retrieveUserSubscription () {
              this.waitingAPIResponse = true
              getAPI.get(`subscriptions/find-subscription`).then(response => {
                if(response.status == 200) {
                  this.subscriptionData = response.data.data
  
                  if(this.subscriptionData != null){
                  console.log("Infos: ", this.subscriptionData)
                  }
                  
                  this.remain_days = this.subscriptionData.remain_days
  
                  const created_at = new Date(this.subscriptionData.created_at)
                  created_at.setDate(created_at.getDate() + 30);
                  
                  const expirationDate = created_at.toISOString();
  
                  this.expiration_date = expirationDate
                }
                else {
                  console.log("Subslength: ", this.subscriptionData.length)
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false
                }, 2000);
              }).catch(error => {
                  if (error.response) {
                      //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                  }
                  else if (error.request) {
                      //let msg = 'The request was made but no response was received. Please check your network.'
                      //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  this.waitingAPIResponse = false
              })
        },
          updatePrivacyCheck() {
              this.privacyCheck = !this.privacyCheck;
              
              console.log("State: ", this.privacyCheck);
              console.log("Coosed: ", this.templateChoosed);
              //setTimeout(() => {
               //   ""
              //}, 0);
          },
          selectAll() {
              this.selectedUser = this.userList.map(user => user.id);
          },
          selectTab (section) {
              localStorage.setItem('tab_phish_active', section)
              this.currentTab = section
          },
          convertDate(dateString, dateOnly=false) {
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString();
              const formattedTime = date.toLocaleTimeString();
              if(!dateOnly) {
                  return `<b>${formattedDate} à ${formattedTime}</b>`;
              }
              return `<b>${formattedDate}</b>`;
          },
          getAllModule () {
        this.waitingAPIResponse = true
        getAPI.get(`micro_learning/campaigns/${this.$route.params.id}/`)
        .then(response => {
          if(response.status == 200) {
            let modulesData = response.data
            this.tempDeleteID = modulesData.id;
            this.allModules = modulesData
            this.totalModule = modulesData.count
            // TODO: make this dynamic
            this.totalInProgress = 1
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      },
      getCampaignReport() {
        this.waitingAPIResponse = true
         getAPI.get(`micro_learning/campaign/report/${this.tempDeleteID}/`, {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob' 
})
.then(response => {
    if (response.status == 200) {
        try {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error creating or downloading file:', error);
            let msg = 'Error downloading file. Please try again.';
            this.alertString = `${msg}|alert-no|5000::${Date.now()}`;
        }
    } if (response.status === 403) {
            // Alert for 403 status code
            let msg = 'Le rapport ne peut être généré que pour les campagnes terminées';
            this.alertString = `${msg}|alert-warning|10000::${Date.now()}`;
        }
    setTimeout(() => {
        //this.waitingAPIResponse = false;
    }, 2000);
})
.catch(error => {
    if (error.response) {
        if (error.response.status === 403) {
            // Alert for 403 status code
            let msg = 'Le rapport ne peut être généré que pour les campagnes terminées';
            this.alertString = `${msg}|alert-warning|10000::${Date.now()}`;
        } else {
            let firstError = Object.values(error.response.data)[0][0];
            this.alertString = `${firstError}|alert-no|10000::${Date.now()}`;
        }
    } else if (error.request) {
        let msg = 'The request was made but no response was received. Please check your network.';
        this.alertString = `${msg}|alert-no|8000::${Date.now()}`;
    } else {
        let msg = 'Oups ! something went wrong.';
        this.alertString = `${msg}|alert-no|5000::${Date.now()}`;
    }
    //this.waitingAPIResponse = false;
});
      },
          getAllGroup () {
              this.waitingAPIResponse = true
              getAPI.get(`groupes/`)
                  .then(response => {
                    console.log(response)
                      if(response.status == 200) {
                          let groups = response.data.data;
                          this.groupAllData = response.data.data;
                          this.groupAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let users_count = `
                                  <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                              `;
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.groupAll.push({'n°': (i+1), 'Nom': `<span class="selectable" style="color: #132B4D">${row.name}</span>`, 'Utilisateurs':users_count, 'Denière modification':this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id})
                          }
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getAllService () {
              this.waitingAPIResponse = true
              getAPI.get(`services`)
                  .then(response => {
                    //console.log(response)
                      if(response.status == 200) {
                          
                          this.serviceAllData = response.data.data;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getAllMicroLearning () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/email-campaign-records/`,{params:{campaign:this.$route.params.id}})
                  .then(response => {
                      if(response.status == 200) {
                          this.campaignsAll=[];
                        let data =response.data.results;
                          this.microlearning = response.data.results;
                          for(let i=0; i<data.length; i++) {
                              let row = data[i];
                   
                              this.campaignsAll.push({
                                  'Nom et Prénoms':`<span class="selectable">${row.user_first_name}   ${row.user_last_name}</span>`,
                                  'Email':`<span class="selectable" style="text-transform: lowercase;" >${row.user_email}</span>`,
                                  'Status d’envoi':`${row.status == "failed" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">échoué</span>` : (row.status == "success" ?  `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">succès</span>`:'')}`,
                                  'Date d’envoi': this.convertDate(row.created_at, true),
                                  'Date d’ouverture': `${row.opening_date == null ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">En attente</span>`:(row.opening_date != null ? this.convertDate(row.opening_date, true):`hello`)}`,
                                  'Utilisateur Sensibilisè':`${row.opened == false ? `<span class="badge rounded-pill badge-danger  mw-status" style="background-color:#FEEAEB;color:#FF3947;">Non</span>` : (row.opened == true ?  `<span class="badge rounded-pill badge-warning mw-status" style="background-color:#E7F9F1;color:#2ECD77;">Oui</span>`:`hello`)}`,
                                
                              });
                          }
                          console.log(this.campaignAll);
                        //   this.email1=
                        //   this.email2=
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getMembers () {
              this.waitingAPIResponse2 = true
              let org = localStorage.getItem('organisation')
              getAPI.get(`accounts/users/organisation/${org}`)
                  .then(response => {
                      if(response.status == 200) {
                          this.userList = response.data.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse2 = false
                  })
          },
          templateSelect () {
              this.templateChoosed = this.currentTemplate.id;
              console.log("Template choisi: ", this.templateChoosed);
          },
          pageSelect () {
              this.pageChoosed = this.currentTemplate1.id;
              console.log("Page choisi: ", this.pageChoosed);
          },
          getTemplate () {
              this.waitingAPITemplate2 = true
              getAPI.get(`micro_learning/campaigns/email/campaign-analytics/${this.$route.params.id}/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData = response.data;
                          this.currentTemplate = this.templateData[this.currentTemplateIndex];
                          this.templateChoosed = this.currentTemplate.id;
                          console.log("Etat: ", this.temSelected)
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate2 = false
                  })
          },
          getLanding () {
              this.waitingAPITemplate = true
              getAPI.get(`phishing/landing-page/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData1 = response.data.data;
                          this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
                          console.log(this.currentTemplate1)
                          this.pageChoosed = this.currentTemplate1.id;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate = false
                  })
          },
          updateGroup (pos) {
              this.updateGroupPayload = pos
              this.openNewGroup = true
          },
          newGroup () {
              this.userGroupAll = []
              this.updateGroup({name: ''});
              this.getMembers();
          },
          confirmNewGroup () {
              console.log(this.selectedUser)
              console.log(this.updateGroupPayload.name)
              if(this.updateGroupPayload.name != '' && this.selectedUser.length > 0) {
                  getAPI.post(`groupes/`, {
                      name: this.updateGroupPayload.name,
                      organisation: localStorage.organisation,
                      users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 201) {
                          this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitNewGroupModal = Date.now()
                  })
                  .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now()
                      })
              }
              else {
                  this.exitNewGroupModal = Date.now()
                  //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
          },
          
         
          
          getAllCampaign () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/campaigns/`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.results;
                          console.log(groups);
                          //let group;
                          //this.campaignsAll = []
                        //   let users =[
                        //     {'name':'john doe','email':'cel@gmail.com','status':'canceled','created':'14/05/2024','updated':'23/06/2024','user':'yes'},
                        //     {'name':'john doe','email':'cel@gmail.com','status':'pending','created':'14/05/2024','updated':'23/06/2024','user':'no'}
                        //   ]
                        //   for(let i=0; i<users.length; i++) {
                        //       let row = users[i];
                   
                        //     //   let btn = `<div class="d-flex align-items-center">`;
                        //     //   btn += `<a href="/campaign-details/${row.id}"><button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}"  class="ms-2  btn-action"><i class="fa fa-eye style="color:#001861;"></i></button></a>`;
                        //     //   btn += `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="download" class="ms-2  btn-action"><i class="fa fa-download " style="color:#90ee90;" ></i></button>`;
                        //     //   btn += `<button style="background-color:white; border: 1px solid #fff;" btn-id="${row.id}" btn-action="delete" class="ms-2  btn-action"><i class="fa fa-trash " style="color:#ff0000;"></i></button>`;
                        //     //   btn += `</div>`;
                              
                        //       this.campaignsAll.push({
                        //           'Nom':`<span class="selectable">${row.name}</span>`,
                        //           'Email':`<span class="selectable">${row.email}</span>`,
                        //           'Status d’envoi':`${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Envoyé</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">En cours</span>`)}`,
                        //           'Date d’envoi': `<span class="selectable">${row.created}</span>`,
                        //           'Date d’ouverture ': `<span class="selectable">${row.updated}</span>`,
                        //           'Utilisateur sensibilisé?':`${row.user == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.user == "no" ? `<span class="badge rounded-pill badge-success cfa-bg-success mw-status" style="background-color:##E7F9F1;color:#14C766;">Oui</span>` : `<span class="badge rounded-pill badge-warning cfa-bg-warning mw-status" style="background-color:#FEF6EA;color:#F8B13D;">Non</span>`)}`,
                                  
                        //       });
                        //   }
                        //   console.log(this.campaignAll);
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
             
          },
          newCampaign () {
              this.newCampaignForm=true;
          },
          prevTemplate() {
              if (this.currentTemplateIndex > 0) {
                  this.currentTemplateIndex--;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          nextTemplate() {
              if (this.currentTemplateIndex < this.templateData1 - 1) {
                  this.currentTemplateIndex++;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          prevLanding() {
              if (this.currentTemplateIndex1 > 0) {
                  this.currentTemplateIndex1--;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
          nextLanding() {
              if (this.currentTemplateIndex1 < this.templateData1.length - 1) {
                  this.currentTemplateIndex1++;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
  
          getCallToActionForCampaigns (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionCampaignBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            
            }
            if(payload.action_type == 'view') {
               this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                        this.campaignByid = response.data;
                         
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
            }
          },
          
          confirmDeletion2 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
                this.openDeletionCampaignBox = false
            }
            else{
                 getAPI.delete(`micro_learning/campaigns/${this.tempDeleteID}/`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Csmpaign supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllCampaign()
                            }, 1500)
                            this.openDeletionCampaignBox = false
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgModalDel = Date.now()
                            this.openDeletionCampaignBox = false
                        }
                    })
                    .catch(() => {
                        //this.exitOrgModalDel = Date.now()
                        this.openDeletionCampaignBox = false
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
            this.openDeletionCampaignBox = false
          },
          
  
          // for stepper logic
          onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
          wizardCompleted() {
              
                  const groups = [];
                  const service = [];
                //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
                //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
                  service.push(this.newCampaignService)
                  groups.push(this.newCampaignGroupe);
                  console.log(groups);
                  let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                  newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                  
                  getAPI.post(`micro_learning/campaigns/`, {
                      name: this.newCampaignName,
                      micro_learning_id: this.newCampaignTitle,
                      campaign_type: this.newCampaignType,
                      sms_send_name: this.newCampaignSmsSenderName || null,
                      group: this.newCampaignGroupe || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                      email_send_name: this.newCampaignEmailSenderName || null,
                      sms_send_signature: this.newCampaignSmsSignature || null,
                      email_send_signature: this.newCampaignEmailSignature || null,
                      planification_date: newCampaignDateStartF,
                      default_message:this.newCampaigndefaultmsg,
                      is_validated_by_admin: false,
                      service:this.newCampaignService || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                      status:"unavailable"
                      
                  })
                      .then(response => {
                          if(response.status === 201) {
                              this.getAllGroup()
                              this.getAllCampaign()
                              //let msg = 'Campagne crééé avec succès!'
                              this.campaignWaiting = response.data;
                              console.log("Camp: ", this.campaignWaiting)
                              //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          }
                          else {
                              
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                          this.$swal({
                              title: 'Campagne créée avec succès',
                              text: 'Campagne créée avec succès. En attente de validation.',
                              type: 'warning',
                              showCancelButton: false,
                              confirmButtonText: "D'accord !"
                          });
                      })
                      .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                      });
              
              
          },
          wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              let newCampaignDateStartF = new Date(this.campaignByid.planification_date);
              newCampaignDateStartF = addHours(this.campaignByid.planification_date, 3);
              
              getAPI.put(`micro_learning/campaigns/${this.tempDeleteID}/`, {
                  name: this.campaignByid.name,
                  micro_learning_id: this.campaignByid.micro_learning_id,
                  campaign_type: this.campaignByid.campaign_type,
                  sms_send_name: this.campaignByid.sms_send_name || null,
                  group: this.campaignByid.group || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                  email_send_name: this.campaignByid.email_send_name || null,
                  sms_send_signature: this.campaignByid.sms_send_signature || null,
                  email_send_signature: this.campaignByid.email_send_signature || null,
                  planification_date: newCampaignDateStartF,
                  default_message:this.campaignByid.default_message,
                  is_validated_by_admin: false,
                  service:this.campaignByid.service || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                  status:"unavailable"
                  
              })
                  .then(response => {
                      if(response.status === 200) {
                          this.getAllGroup()
                          this.getAllCampaign()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                      this.$swal({
                          title: 'Campagne créée avec succès',
                          text: 'Campagne créée avec succès. En attente de validation.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
          },
          confirmCreate() {

          },
          confirmUpdate() {

          }
      },
      mounted () {
        this.getAllModule ();
          //console.log("Templates: ", this.templateData)
          this.getAllGroup();
          this.getAllService ();
          this.getAllCampaign();
          this.retrieveUserSubscription();
          this.getAllMicroLearning ();
          this.getTemplate();
      },
      computed: {
          nextButtonOptions() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.newCampaignType === 'default' || this.newCampaignName == null  )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          nextButtonOptionss() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.campaignByid.name == null || this.campaignByid.campaign_type === 'default' )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          prevButtonOptions() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignTarget == 'default') ? {
                  disabled: true,
                  text: "Terminé"
              }: {
                  disabled: false,
                  text: "Terminé"
              };
          },
      },
      watch: {
          newCampaignGroupe: function (value) {
              this.groupeApiWaiting = true;
              getAPI.get(`groupes/${value}`)
                  .then(response => {
                      if(response.status == 200) {
                          this.groupApiWaitingData = response.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.groupeApiWaiting = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.groupeApiWaiting = false
                  })
          },
        //   currentTabIndex: function (value) {
        //       if(value === 2) {
        //           if(this.templateData.length === 0) {
        //               this.getTemplate();
        //               console.log("Tab templates: ", this.templateData)
        //           }
        //       }
        //       if(value === 4) {
        //           if(this.templateData1.length === 0) {
        //               this.getLanding();
        //           }
        //       }
        //   },
      }
  }
  </script>
  
  <style scoped>
  .details{
    width:303px;
    height:113px;
    background-color:#fff;
    border-radius:8px;
    /* margin-right:20px; */
  }
  
  details h6{
    color:#1E1E1E;
    font-weight:50;
    margin-top:20px;
  }
  .details h5{
    color:#DADADA;
    font-size:8px;
  }
  .cfa-page{}
  .cfa-block{
      background-color: #fff;
      border-radius: 0.5em;
      padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      /* border: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  }
  
  /* TAB STYLE */
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    /*border-bottom: 1px solid #ccc;*/
  }
  
  .tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  
  .tab-header ul li.active {
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-header ul li:hover{
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-body {
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
  }
  .gFolder-container{
      background-color: var(--primary-light-color-2);
      border-radius: 0.5em;
      height: 4.3em;
      width: 4.6em;
      margin-right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .gBlock{
      align-items: center;
      justify-content: start;
      border-radius: 0.6em;
      padding-left: 2em;
      padding-right: 2em;
      height: 8em !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .gBlock div:last-of-type p{
      margin: 0;
  }
  .gBlock div:last-of-type p:first-of-type{
      font-weight: 400;
      font-size: 2em;
  }
  .tab_font{
      font-size: 13px;
  }
  .cfa-welcome{
    /* background-color:#e9f4f5; */
  }
  .user-row{
      background: #fff;
      padding-left: 1em;
      margin-bottom: 0.2em;
  }
  .row-user{
      transition: .2s all ease-in;
  }
  .row-user:hover{
      background: #f5f5f5;
      cursor: pointer;
  }
  
  .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .left-button,
  .right-button {
      padding: 10px;
      cursor: pointer;
      background-color: lightgray;
  }
  
  .middle-div {
      flex: 1;
      margin: 0 10px;
  }
  
  .content {
      width: 100%;
      height: 461px;
      border: 1px solid #e0e1e8;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
  }
  .cfa-section-title{
      color: #fff;
  }
  .cfa-btn-primary{
    height:40px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  </style>