<template>
    <div class="cfa-root">
      <c-f-a-nav  :alert="alertString">
        <template v-slot:title>
          <div class="w-100 pt-2 text-start mt-0 mb-0" >
              <h1 class="cfa-section-title" style="color:white;font-weight:300;margin-top:20px;margin-bottom: 50px;margin-left:40px">{{$t('menu_micro_awareness')}}</h1>
                <div style="display:flex;justify-content:space-between">
              <h2 style="color:white;margin-left:40px;">{{$t('micro_awareness_campaign_creation_title')}}</h2>
              </div>
          </div>
        </template>
        <template v-slot:content>
            <div v-if="!waitingAPIResponse" class="cfa-page">
            
                <div class="tab mt-1">
                
                    <div class="tab-body" style="margin-bottom: 1em;border: none;">
                        <div class="row pt-3 cfa-welcome mt-4 justify-content-center">
                            <h6 style="color:#132B4D;font-size:20px;margin-bottom:30px;text-align:center;">
                                {{$t('micro_awareness_new_campaign_title')}}
                            </h6>

                            <!-- Stepper Progress -->
                            <div class="wrapper-stepper text-center">
                                <div class="stepper">
                                    <div class="stepper-progress">
                                        <div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
                                    </div>

                                    <div class="stepper-item" v-for="(item, index) in steps" :key="index" :class="{ 'current': step == index + 1, 'success': step > index + 1 }">
                                        <div class="stepper-item-counter">
                                            <button class="icon-success" style="border:none;background-color:#5dc2d7;color:#fff;font-size:20px;">
                                                <i class="fa fa-check"></i>
                                            </button>
                                            <span class="number">{{index + 1}}</span>
                                        </div>
                                        <span class="stepper-item-title">{{item}}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- Form Content for Step 1 -->
                            <div class="stepper-pane" v-if="step == 1">
                                <div class="row w-100 m-inherit justify-content-center">
                                    <div class="row mt-3 d-flex justify-content-center">
                                        <p class="mb-2"><strong>{{$t('micro_awareness_type_text')}}</strong></p>
                                        <div class="col-md-6 d-flex align-items-center account-type-block">
                                            <input type="radio" id="email" value="email" class="input-select-adj" v-model="newCampaignType" />
                                            <label for="email" class="ml-2" style="margin-left:10px;">{{$t('micro_awareness_by_email')}}</label>
                                        </div>
                                        <div class="col-md-6 d-flex align-items-center account-type-block">
                                            <input type="radio" id="sms" value="sms" class="input-select-adj" v-model="newCampaignType" />
                                            <label for="sms" class="ml-2" style="margin-left:10px;">{{$t('micro_awareness_by_sms')}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="newCampaignType == 'email' || newCampaignType == 'sms' " class="row w-100 m-inherit">
                                    <div class="form-group py-2 px-0 mt-3">
                                        <p class="mb-2">{{$t('micro_awareness_campaign_name')}}</p>
                                        <div class="d-flex flex-row form-group">
                                        <input class="form-control" type="text" placeholder="Saisissez ici le nom de la campagne" v-model="newCampaignName" required>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                                    <div class="form-group py-2 px-0 mt-3">
                                        <p class="mb-2">{{$t('micro_awareness_manager_name')}}</p>
                                        <div class="d-flex flex-row">
                                        <input class="form-control" type="text" placeholder="Saisissez ici votre Nom et Prénoms" v-model="newCampaignEmailSenderName" required>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                                    <div class="form-group py-2 px-0 mt-3">
                                        <p class="mb-2">{{$t('micro_awareness_signature')}}</p>
                                        <div class="d-flex flex-row">
                                        <input class="form-control" type="text" placeholder="Saisissez ici votre signature" v-model="newCampaignEmailSignature" required>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                                    <div class="form-group py-2 px-0 mt-3">
                                        <p class="mb-2">Nom de l'expéditeur du SMS</p>
                                        <div class="d-flex flex-row">
                                        <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="newCampaignSmsSenderName" required>
                                        </div>
                                    </div>
                                </div>
                                
                                <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                                    <div class="form-group py-2 px-0 ">
                                        <p class="mb-2">Signature de l'expéditeur du SMS</p>
                                        <div class="d-flex flex-row">
                                        <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="newCampaignSmsSignature" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->

                            <!-- Form Content for Step 2 -->
                            <div class="stepper-pane" v-if="step == 2">
                                <div class="row w-100 m-inherit justify-content-center">
                                    <div class="row mt-3 d-flex justify-content-center">
                                        <p class="mb-2"><strong>{{$t('micro_awareness_campaign_target')}}</strong></p>
                                        <div class="col-md-6 d-flex align-items-center account-type-block ">
                                            <input type="radio" id="one" value="GROUP" class="input-select-adj" v-model="newCampaignTarget" />
                                            <label for="GROUP" class="ml-2"  style="margin-top:5px;margin-left:10px;" >{{$t('group')}}</label>
                                        </div>
                                        <div class="col-md-6 d-flex align-items-center account-type-block">
                                            <input type="radio" id="one" value="SERVICE" class="input-select-adj" v-model="newCampaignTarget" />
                                            <label for="SERVICE" class="ml-2"  style="margin-top:5px;margin-left:10px;" >{{$t('departement')}}</label>
                                        </div>
                                    </div>
                                    <div v-if="newCampaignTarget =='GROUP'" class="row w-100 m-inherit">
                                                    <div class="form-group py-2 px-0 mt-5 ">
                                                        <p class="mb-2">{{$t('micro_awareness_campaign_groupe_text')}}</p>
                                                        <div class="d-flex flex-row">
                                                            <select class="form-control" v-model="newCampaignGroupe">
                                                                <option :value="null" selected disabled>{{$t('micro_awareness_campaign_groupe_select_text')}}</option>
                                                                <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="newCampaignTarget =='SERVICE'" class="row w-100 m-inherit">
                                                    <div class="form-group py-2 px-0 mt-5 ">
                                                        <p class="mb-2">{{$t('micro_awareness_campaign_departement_text')}}</p>
                                                        <div class="d-flex flex-row">
                                                            <select class="form-control" v-model="newCampaignService">
                                                                <option :value="null" selected disabled>{{$t('micro_awareness_campaign_departement_select_text')}}</option>
                                                                <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>

                            <!-- Form Content for Step 3 -->
                            <div class="stepper-pane" v-if="step == 3">
                                <div class="row w-100 m-inherit justify-content-center">
                                    <div class="row mt-3 d-flex justify-content-center">
                                        <div class="form-group py-2 px-0">
                                            <p class="mb-2">{{$t('micro_awareness_schedule_text')}} <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></p>
                                            <div class="d-flex flex-row">
                                                <VueDatePicker placeholder="Sélectionner la date" v-model="newCampaignDateStart" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-inherit justify-content-center">
                                    <div class="row mt-3 d-flex justify-content-center">
                                        
                                        <div class="form-group py-2 px-0">
                                            <p class="mb-2">
                                                {{$t('micro_awareness_schedule_text_end')}} 
                                                <span class="error-text">(un minimum d'une journee apres le debut de la campagne)</span>
                                            </p>
                                            <div class="d-flex flex-row">
                                                <VueDatePicker placeholder="Sélectionner la date" v-model="newCampaignDateEnd" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDateEnd"></VueDatePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Form Content for Step 4 -->
                            <div class="stepper-pane" v-if="step == 4">
                                <div class="row w-100 m-inherit justify-content-center">
                                    <div class="row mt-3 d-flex justify-content-center">
                                        <div v-if="newCampaignType == 'email'" class=" d-flex justify-content-center m-inherit" style="">
                                            <div class="ml-5 mt-5" style="background-color:white;width:520px;height:auto;border-radius:12px;">
                                                
                                                <!-- Header section (Title and Button) -->
                                                <div class="mt" style="background-color:#132B4D;width:520px;height:60px;display:flex;justify-content:space-between;border-radius:12px 12px 0 0;transition: border 0.3s ease;border: 2px solid transparent;">
                                                    <h5 style="color:white;margin-left:20px;font-size:15px;font-weight:200px;margin-top:15px;">Nouveau message</h5>
                                                    <button style="background-color:#132B4D;color:white;border:none;margin-right:20px;"><i class="fa fa-external-link-alt"></i></button>
                                                </div>

                                                <!-- Message Content -->
                                                <div class="mt-3">
                                                    <p class="mt-3" style="margin-left:15px;"><b>Objet</b>: {{allModules.title}}</p>
                                                    <p style="text-align:justify;padding:10px;line-height:1.6;margin-top:30px;">Bonjour , <br>{{allModules.top_email_message}}<br><br>{{allModules.bottom_email_message}}</p>
                                                </div>

                                                <!-- Cover Image -->
                                                <div>
                                                    <img width="520" height="auto" :src="allModules.cover_image" />
                                                </div>

                                                <!-- Signature -->
                                                <h6 style="color:#1E1E1E;font-weight:300;margin-left:15px;margin-top:30px;">Cordialement,<br> Votre RSSI </h6>

                                                <!-- Logo Section -->
                                                <div style="margin-left:270px;width:50px;height:50px;margin-top:10px;">
                                                    <img 
                                                        v-if="userList.logo != null" 
                                                        :src="userList.logo" 
                                                        alt="cfa module course image" 
                                                        style="max-width:200px;height:auto;" 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="d-flex justify-content-center m-inherit">
                                    <div v-if="newCampaignType == 'sms'" class="iphone" style="margin-left:80px;">
                                    <div class="side-buttons"></div>
                                    <div class="volume-buttons"></div>
                                <div class="silent-switch"></div>
                                <div class="screen">
                                <div class="header">SPECTORLY</div>
                                <div class="message-container">
                                <div class="message">
                                    Bonjour {{subscriptionData.firstname}},<br><br>
                                {{allModules.sms_message}}
                                <br><br>
                                participation ici: 
                                <a href="https://spector.ly/Abc2P" style="color: #06BBC4; text-decoration: none;">https://spector.ly/Abc2P</a>
                                <br><br>
                                Merci et bonne journée !!!<br><br>
                                --{{userList.name}}--
                                
                                </div>
                            </div>
                            
                            </div>
                        </div>
                                </div>

                                
                            </div>
                            <!-- </div> -->
                            <div class="controls" v-if="step <= 3">
                                    <button class=" btn cfa-btn-primary mx-3" style="color:white;background-color:#06BBC4;width:200px;height:50px;" @click="step--" :disabled="step == 1">
                                        Prècèdent
                                    </button>
                                    <button class="btn cfa-btn-primary mx-3" style="color:white;background-color:#06BBC4;width:200px;height:50px;" :disabled="!canProceed"  @click="step++" >
                                        Continuer
                                    </button>
                            </div>
                            <div class="controls" v-if="step == 4">
                                <button class=" btn cfa-btn-primary mx-3" style="color:white;background-color:#06BBC4;width:200px;height:50px;" @click="openUpdateBox = true" >
                                    Testez
                                </button>
                                <button class="btn cfa-btn-primary mx-3" style="color:white;background-color:#06BBC4;width:200px;height:50px;" @click="wizardCompleted" >
                                    Validez
                                </button>
                            </div>
                            <!-- </div></div> -->
                        </div>
                    </div>
                </div>
            </div>

            <scalable-modal1 @callback="updateThisModule" :break="exitUpdateModal" :uid="uid + '-update-modal'" @close="openUpdateBox=false" :open="openUpdateBox" :advancedControl="true" validationButtonText="Testez" exitButtonText="Annuler" displayMode="flex" :style="'width: 36em; height: 25em;'">
            <template v-slot:content>
                <div class="row" style="display:flex;margin-bottom:70px;justify-content:space-between;">
                    <h6 style="color:#000000; font-size:20px;margin-left:3px;margin-top:5px;margin-bottom:20px;">Testez une campagne</h6>
                    <p style="color:#808080; font-size:13px;">Par quel moyen souhaitez vous tester la campagne?</p>
                <div class="col-md-6 d-flex align-items-center account-type-block" >
                    <input type="radio" value="email" class="input-select-adj" v-model="picked" style="background-color:#b5e5e7;" />
                    
                    <label class="form-label" style="margin-top:10px;margin-left:10px;">
                
                    {{$t('micro_awareness_by_email')}}
            </label>
                </div>
                <div class="col-md-6 d-flex align-items-center account-type-block" >
                    <input type="radio" value="sms" class="input-select-adj" v-model="picked" style="background-color:#b5e5e7;" />
                    <label class="form-label" style="margin-top:10px;margin-left:10px;">
                
            {{$t('micro_awareness_by_sms')}}
            </label>
                </div>
                <div v-if="picked == 'email'" class="row w-100 m-inherit">
                                <div class="form-group py-2 px-0 ">
                                    <p class="mb-2" style="color:#808080;">{{$t('message_box_email_label_text')}}</p>
                                    <div class="d-flex flex-row">
                                        <input class="form-control" type="email" :placeholder="$t('message_box_enter_email_text')"  @blur="validateEmail" v-model="email">
                                        
                                    </div>
                                    <span v-if="emailError" class="error-message">{{ emailError }}</span>
                                </div>
                            </div>
                            <div v-if="picked == 'sms'" class="row w-100 m-inherit" style="margin-top:30px;">
                            <div class="form-group">
                                <phone-input @inputChange="catchPhoneInputOrg" :syncField="syncTime" :placeholder="$t('message_box_enter_phone_number_text')" errorText="Champ requis" uid="cfa-phone-org-bloc2" type="text"></phone-input>
                            </div>
                            </div>
                </div>
    
            </template>
            </scalable-modal1>
        </template>
      </c-f-a-nav>
      <c-f-a-footer></c-f-a-footer>
    </div>
   
  
  </template>
  
  <script>
  
  import CFANav from '@/components/nav/CFANav.vue'
//   import Table from "@/components/shared/table/Table.vue";
  

  import '@/assets/css/stepper.scss';
 //import RadioButton from 'primevue/radiobutton';

 import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  //import CourseCard from '@/components/shared/course/CourseCard3.vue'
  import {getAPI} from "@/axios-api";
  import { addHours } from 'date-fns';
  import PhoneInput from '@/components/shared/input/PhoneInput1.vue'
  import ScalableModal1 from "@/components/shared/modal/ScalableModal.vue";
  import CFAFooter from '@/components/nav/CFAFooter.vue';
  // import SsrCarousel from 'vue-ssr-carousel'
  // import ssrCarouselCss from 'vue-ssr-carousel/index.css'
  
  export default {
      name: 'PhishingView',
      components: {
          
          ScalableModal1,
          PhoneInput,
          VueDatePicker,
        //   RadioButton,
        CFAFooter ,
          //CourseCard,
          CFANav,
          
          // SsrCarousel,
          // ssrCarouselCss,
      },
      data () {
          return {
              minDate: new Date(),
              step: 1,
              //minDateEnd: new Date(),
              steps1: ["Type", "Cible", "Planification", "Validation"],
              waitingAPIResponse: true,
              waitingAPIResponse2: true,
              serviceAllData:[],
              showButton:false,
              openUpdateBox:false,
              newCampaignTitle:'',
              newcampaignUpdate:false,
              newCampaignSmsSenderName:'',
              newCampaignEmailSenderName:'',
              isConnected: true,
              alertString: null,
              email:'',
              phoneNumber:'',
              currentTab: localStorage.tab_phish_active||'campaign_activate',
              newCampaigndefaultmsg:'',
              campaignsAll: [],
              campaignsAllOk: [],
              campaignsAllColumns: ['n°', 'Nom', 'Campaign Type', 'Date création', 'Actions'],
              campaignsAllToListen: ['Actions'],
              campaignByid:[],                
              groupAll: [],
              groupAllColumns: ['n°', 'Nom', 'Utilisateurs', 'Denière modification', 'Actions'],
              groupAllToListen: ['Actions'],
              microlearning:[],
              allModules: [],
              reportsAll: [],
              reportsAllColumns: ['n°', 'Campagne', 'Utilisateurs', 'Actions'],
              reportsAllToListen: ['Actions'],
              newCampaignEmailSignature:'',
              userGroupAll: [],
              userGroupAllColumns: ['Nom', 'Prénoms', 'Email', 'Position', 'Actions'],
              userGroupAllToListen: ['Actions'],
              newCampaignSmsSignature:'',
              openDeletionBox: false,
              tempDeleteID: null,
              disabled:false,
              tempDeleteName: null,
              updateGroupPayload: null,
              openUpdateGroup: false,
              name_check: null,
              picked:'',
              callbackPayload: null,
              exitOrgModalDel: null,
              exitOrgUpdateModal: null,
              newFirstname: null,
              newLastname: null,
              newEmail: null,
              newPosition: null,
              newCampaignService:'',
              openDeletionCampaignBox: false,
              newCampaignForm: false,
  
              newCampaignName: null,
              newCampaignType: "default",
              newCampaignDateStart: null,
              newCampaignDateEnd: null,
              newCampaignGroupe: null,
              newCampaignEmailTemplate: null,
              newCampaignLandingTemplate: null,
              newCampaignTarget:'',
              currentTabIndex: 0,
              userList: [],
              exitUpdateModal:null,
              selectedUser: [],
              credit_mail: '•••',
              credit_sms: '•••',
              total_credit_mail: '•••',
              total_credit_sms: '•••',
              openNewGroup: false,
              exitNewGroupModal: null,
              updateGroupData: {},
              updateGroupStatusRequest: true,
              flattenedIds: [],
              exitGroupUpdateModal: null,
              groupAllData: [],
              totalModule:0,
              groupeApiWaiting: false,
              groupApiWaitingData: [],
              groupeApiTemplateWaiting: false,
              groupApiTemplateWaitingData: [],
              defaultImage: " /images/defaultImage.png",
              waitingAPITemplate: true,
              waitingAPITemplate2: true,
  
              templateData: [],
              currentTemplateIndex: 0,
              currentTemplate: null,
  
              templateData1: [],
              currentTemplateIndex1: 0,
              currentTemplate1: null,
              steps: ['Type', 'Cible', 'Planification', 'Validation'],
              templateChoosed: "",
              pageChoosed: "",
              privacyCheck: null,
              campaignWaiting: [],
  
              subscriptionData: [],
          }
      },
     
      methods: {
        updateThisModule() {
      if(this.picked == 'email') {
        getAPI.post(`micro_learning/test/email/${this.$route.params.id}/`,{email:this.email}).then((response) => {
          if(response.status == 200) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "L'e-mail a été envoyé avec succès.",
                          type: 'success',
                          icon:'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          });    

            // let msg = 'Micro Learning MAJ avec succès!'
            //   this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            if(this.module_image_cover != null) {
              console.log(response);
             
              let msg = 'Micro Learning MAJ avec succès!'
              this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            }
            else{
              //this.$emit('update');
              // let msg = 'Micro Learning MAJ avec succès!'
              // this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            }
          }
          
        }).catch((error) => {
          if (error.response) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "L'email n'est pas envoyé",
                          type: 'error',
                          icon:'error',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          }); 
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          else if (error.request) {
            this.alertString('The request was made but no response was received. Please check your network.', 'alert-no', 10000)
          }
          else {
            this.alertString('Oups ! something went wrong.', 'alert-no', 10000)
          }
          this.exitUpdateModal = Date.now()
        });
      }
      else{
        getAPI.post(`micro_learning/test/sms/${this.$route.params.id}/`,{phone:`+` + this.phoneNumber.countryCode + this.phoneNumber.phone.replaceAll('-', '')}).then((response) => {
          if(response.status == 200) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "Le sms a été envoyé avec succès.",
                          type: 'success',
                          icon:'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          });  
            // let msg = 'Micro Learning MAJ avec succès!'
            //   this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            if(this.module_image_cover != null) {
              console.log(response);
            }
            else{
              //this.$emit('update');
              // let msg = 'Micro Learning MAJ avec succès!'
              // this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            }
          }
          this.exitUpdateModal = Date.now()
        }).catch((error) => {
          if (error.response) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "Le sms n'est pas envoyé",
                          type: 'error',
                          icon:'error',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          }); 
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          else if (error.request) {
            this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 10000)
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          this.exitUpdateModal = Date.now()
        });
      }
    },
          
          updatePrivacyCheck() {
              this.privacyCheck = !this.privacyCheck;
              
              console.log("State: ", this.privacyCheck);
              console.log("Coosed: ", this.templateChoosed);
              //setTimeout(() => {
               //   ""
              //}, 0);
          },
          selectAll() {
              this.selectedUser = this.userList.map(user => user.id);
          },
          selectTab (section) {
              localStorage.setItem('tab_phish_active', section)
              this.currentTab = section
          },
          convertDate(dateString, dateOnly=false) {
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString();
              const formattedTime = date.toLocaleTimeString();
              if(!dateOnly) {
                  return `<b>${formattedDate} à ${formattedTime}</b>`;
              }
              return `<b>${formattedDate}</b>`;
          },
          getAllModule () {
        this.waitingAPIResponse = true
        getAPI.get(`micro_learning/courses/${this.$route.params.id}/`)
        .then(response => {
          if(response.status == 200) {
            let modulesData = response.data
            this.allModules = modulesData
            this.totalModule = modulesData.count
            // TODO: make this dynamic
            this.totalInProgress = 1
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      },
          getAllGroup () {
              this.waitingAPIResponse = true
              getAPI.get(`groupes`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.data;
                          this.groupAllData = response.data.data;
                          this.groupAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let users_count = `
                                  <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                              `;
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.groupAll.push({'n°': (i+1), 'Nom': `<span class="selectable" style="color: #132B4D">${row.name}</span>`, 'Utilisateurs':users_count, 'Denière modification':this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id})
                          }
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getAllService () {
              this.waitingAPIResponse = true
              let org = localStorage.getItem('organisation')
              getAPI.get(`services/?organisation=${org}`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.serviceAllData = response.data.data;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getAllMicroLearning () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.microlearning = response.data.results;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getMember() {
              this.waitingAPIResponse2 = true
            //   let org = localStorage.getItem('organisation')
              getAPI.get(`subscriptions/find-subscription`)
                  .then(response => {
                      if(response.status == 200) {
                          this.userList = response.data.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse2 = false
                  })
          },
          getMembers () {
              this.waitingAPIResponse2 = true
              //let org = localStorage.getItem('organisation')
              getAPI.get(`accounts/users/me`)
                  .then(response => {
                      if(response.status == 200) {
                          this.subscriptionData = response.data.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse2 = false
                  })
          },
          templateSelect () {
              this.templateChoosed = this.currentTemplate.id;
              console.log("Template choisi: ", this.templateChoosed);
          },
          pageSelect () {
              this.pageChoosed = this.currentTemplate1.id;
              console.log("Page choisi: ", this.pageChoosed);
          },
          getTemplate () {
              this.waitingAPITemplate2 = true
              getAPI.get(`phishing/templates/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData = response.data.data;
                          this.currentTemplate = this.templateData[this.currentTemplateIndex];
                          this.templateChoosed = this.currentTemplate.id;
                          console.log("Etat: ", this.temSelected)
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate2 = false
                  })
          },
          getLanding () {
              this.waitingAPITemplate = true
              getAPI.get(`phishing/landing-page/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData1 = response.data.data;
                          this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
                          console.log(this.currentTemplate1)
                          this.pageChoosed = this.currentTemplate1.id;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate = false
                  })
          },
          updateGroup (pos) {
              this.updateGroupPayload = pos
              this.openNewGroup = true
          },
          newGroup () {
              this.userGroupAll = []
              this.updateGroup({name: ''});
              this.getMembers();
          },
          confirmNewGroup () {
              console.log(this.selectedUser)
              console.log(this.updateGroupPayload.name)
              if(this.updateGroupPayload.name != '' && this.selectedUser.length > 0) {
                  getAPI.post(`groupes/`, {
                      name: this.updateGroupPayload.name,
                      organisation: localStorage.organisation,
                      users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 201) {
                          this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitNewGroupModal = Date.now()
                  })
                  .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now()
                      })
              }
              else {
                  this.exitNewGroupModal = Date.now()
                  //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
          },
          
         
          
          getAllCampaign () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/campaigns/`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.results;
                          this.campaignsAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.campaignsAll.push({
                                  'n°': (i+1),
                                  
                                  'Nom': `<span class="selectable">${row.name}</span>`,
                                  'Campaign Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span>` : `<span class="badge rounded-pill badge-success cfa-bg-danger mw-status">IN PROGRESS</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id
                              });
                          }
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
             
          },
          newCampaign () {
              this.newCampaignForm=true;
          },
          prevTemplate() {
              if (this.currentTemplateIndex > 0) {
                  this.currentTemplateIndex--;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          nextTemplate() {
              if (this.currentTemplateIndex < this.templateData1 - 1) {
                  this.currentTemplateIndex++;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          prevLanding() {
              if (this.currentTemplateIndex1 > 0) {
                  this.currentTemplateIndex1--;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
          nextLanding() {
              if (this.currentTemplateIndex1 < this.templateData1.length - 1) {
                  this.currentTemplateIndex1++;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
  
          getCallToActionForCampaigns (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionCampaignBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            
            }
            if(payload.action_type == 'view') {
               this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                        this.campaignByid = response.data;
                         
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
            }
          },
          
          
          confirmDeletion2 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
                this.openDeletionCampaignBox = false
            }
            else{
                 getAPI.delete(`micro_learning/campaigns/${this.tempDeleteID}/`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Csmpaign supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllCampaign()
                            }, 1500)
                            this.openDeletionCampaignBox = false
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgModalDel = Date.now()
                            this.openDeletionCampaignBox = false
                        }
                    })
                    .catch(() => {
                        //this.exitOrgModalDel = Date.now()
                        this.openDeletionCampaignBox = false
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
            this.openDeletionCampaignBox = false
          },
          
  
          // for stepper logic
          onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
          async wizardCompleted() {
              
                  const groups = [];
                  const service = [];
                //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
                //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
                service.push(this.newCampaignService)
                groups.push(this.newCampaignGroupe);
                  console.log(groups);
                  let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                  newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                  const payload = {
                      name: this.newCampaignName,
                      micro_learning: this.$route.params.id,
                      campaign_type: this.newCampaignType,
                      sms_send_name: this.newCampaignSmsSenderName || null,
                      group: this.newCampaignGroupe || null,
                      email_send_name: this.newCampaignEmailSenderName || null,
                      sms_send_signature: this.newCampaignSmsSignature || null,
                      email_send_signature: this.newCampaignEmailSignature || null,
                      planification_date: newCampaignDateStartF,
                      closing_date: this.newCampaignDateEnd,
                      sms_provider: null,
                      url_shortener: null,
                      default_message:"micro learning message",
                      is_validated_by_admin: false,
                      service:this.newCampaignService || null,  
                     
                      
                  }
                  console.log(payload)
                  //return
                  //eslint-disable-next-line
                  await getAPI.post(`micro_learning/campaigns/`, payload )
                      .then(response => {
                          if(response.status === 201) {
                              this.getAllGroup()
                              this.getAllCampaign()
                              //let msg = 'Campagne crééé avec succès!'
                              this.campaignWaiting = response.data;

                              this.$router.push({name:'MicroCampaign'});
                          this.$swal({
                          title: '',
                          text: 'Votre campagne a été créee avec succès.',
                          type: 'success',
                          icon:'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          });                              //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          }
                          else {
                              
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          //this.newCampaignForm = false;
                          this.$router.push({name:'MicroCampaign'});
                          this.$swal({
                          title: '',
                          text: 'Votre campagne a été créee avec succès.',
                          type: 'success',
                          icon:'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          });
                          
                          
                      })
                      .catch(error => {
                          if (error.response) {
                             this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              let msg = 'The request was made but no response was received. Please check your network.'
                              this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              let msg = 'Oups ! something went wrong.'
                              this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                      });
              
              
          },
          wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              let newCampaignDateStartF = new Date(this.campaignByid.planification_date);
              newCampaignDateStartF = addHours(this.campaignByid.planification_date, 3);
              
              getAPI.put(`micro_learning/campaigns/${this.tempDeleteID}/`, {
                  name: this.campaignByid.name,
                  micro_learning: this.$route.params.id,
                  campaign_type: this.campaignByid.campaign_type,
                  sms_send_name: this.campaignByid.sms_send_name || null,
                  group: this.campaignByid.group || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                  email_send_name: this.campaignByid.email_send_name || null,
                  sms_send_signature: this.campaignByid.sms_send_signature || null,
                  email_send_signature: this.campaignByid.email_send_signature || null,
                  planification_date: newCampaignDateStartF,
                  default_message:"micro learning message",
                  sms_provider: "3cdce6f1-ec4b-4540-96c3-13e09115cce1",
                  url_shortener: "e24a88b9-d1b2-4ba1-b635-292271b65d5c",
                  is_validated_by_admin: false,
                  service:this.campaignByid.service || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                  status:"unavailable"
                  
              })
                  .then(response => {
                      if(response.status === 200) {
                          this.getAllGroup()
                          this.getAllCampaign()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                      this.$swal({
                          title: '',
                          text: 'Votre campagne a été créee avec succès.',
                          type: 'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
      validateEmail() {
      if (!this.email) {
        this.emailError = this.$t('message_box_enter_email_text');
      } else if (!this.isValidEmail(this.email)) {
        this.emailError = this.$t('invalid_email_message');
      } else {
        this.emailError = '';
      }
    },
    isValidEmail(email) {
      // Basic email validation regex
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
        catchPhoneInputOrg (data) {
        this.phoneNumber = data;
        console.log(this.phoneNumber);
      },
      nextButtonOptionss() {
      
      // Step 0 is always enabled
     
      // Step 1 requires both campaign type and name
       if (this.step === 1) {
        if (this.newCampaignType === null || this.newCampaignName === null) {
            this.disabled= true;
        }
        
      }
      // Step 2 requires campaign type to be selected
      else if (this.step === 2) {
        if (this.newCampaignType == null) {
          this.disabled = true;
        }
      }
      // Step 3 requires campaign target to be selected
      else if (this.step === 3) {
        if (this.newCampaignTarget == null) {
          this.disabled = true;
        }
      }
      // Step 4 also requires campaign target to be selected
      else if (this.step === 4) {
        if (this.newCampaignTarget == null) {
          this.disabled = true;
        }
      }

      
    },
      },
     
      mounted () {
        this.nextButtonOptionss();
        this.getAllModule ();
        this.getMember();
        this.getMembers();
          //console.log("Templates: ", this.templateData)
          this.getAllGroup();
          this.getAllService ();
          this.getAllCampaign();
          
          this.getAllMicroLearning ();
      },
      computed: {
        canProceed() {
      if (this.step === 1) {
        // For step 1, we need `newCampaignType` and `newCampaignName`
        return this.newCampaignType && this.newCampaignName;
      } else if (this.step === 2) {
        // For step 2, we need `newCampaignTarget`
        return this.newCampaignTarget && (this.newCampaignGroupe || this.newCampaignService);
      } else if (this.step === 3) {
        // For step 3, we need `newCampaignDateStart`
        return this.newCampaignDateStart;
      }
      // For other steps, default to allowing proceeding
      return true;
    },
        
        stepperProgress() {
           return ( 100 / 3 ) * ( this.step - 1 ) + '%'
         },
          nextButtonOptions() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                     
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.newCampaignType === 'default' || this.newCampaignName == null  )) ?
                      {
                          disabled: true,
                          text: "Continuer",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Continuer",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Continuer",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Continuer",
                                  }
                                  : 
                                  {
                                          hideButton:true,
                                          disabled: false,
                                          text: "Continuer",
                                  })
                              )
                          )
                      );
          },
          
          prevButtonOptions() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignDateStart == null) ? {
                disabled: true,
                  text: "Terminé"
                  
              }: {
                 
                disabled: false,
                  text: "Terminé"
              } ;
          },
        minDateEnd() {
            if (this.newCampaignDateStart) {
                let currentDate =  new Date(this.newCampaignDateStart)
                return currentDate.setDate(currentDate.getDate() + 1)
            }
            return new Date()
        }
      },
      watch: {
        newCampaignGroupe: function (value) {
            if (value && value != "") {

                this.groupeApiWaiting = true;
                getAPI.get(`groupes/${value}`)
                    .then(response => {
                        if(response.status == 200) {
                            this.groupApiWaitingData = response.data;
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        setTimeout(() => {
                            this.groupeApiWaiting = false
                        }, 2000);
                    })
                    .catch(error => {
                        if (error.response) {
                            //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                        }
                        else if (error.request) {
                            //let msg = 'The request was made but no response was received. Please check your network.'
                            //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                        }
                        else {
                            //let msg = 'Oups ! something went wrong.'
                            //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                        }
                        this.groupeApiWaiting = false
                    })
            }
        },
        newCampaignTarget : function(value) {
            if (value  == "GROUP") {
                this.newCampaignService = ""
            } else if (value == "SERVICE") {
                this.newCampaignGroupe = ""
            }
        }
        
      }
  }
  </script>
  
  <style scoped>
  .cfa-page{}
  .input-select-adj{
    margin-right: .4em;
  }
  .iphone {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
  width: 400px;
  height: 550px;
  margin-left:140px;
  background-color: #17153B; /* Couleur de la bordure du téléphone */
  border-radius: 30px; /* Bordure légèrement réduite */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 15px; /* Réduction de l'épaisseur de la bordure */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left:120px;
}

.iphone::before {
  content: '';
  display: block;
  width: 80px;
  height: 20px;
  background-color: #17153B;
  border-radius: 15px 15px 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.iphone::after {
  content: '';
  display: block;
  width: 40px;
  height: 5px;
  background-color: #666;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.screen {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px; /* Réduction de la bordure de l'écran */
  padding: 25px 10px 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  font-size: 14px;
  font-weight: bold;
  color: #06BBC4;
  text-align: center;
  margin-bottom: 5px;
}

.message-container {
  display: flex;
  justify-content: center;
  margin-top:30px;
}

.message {
  font-size: 12px;
  color: #fff;
  background-color: #132B4D;
  padding: 10px 15px;
  border-radius: 20px;
  line-height: 1.5;
  flex-grow: 1;
  max-width: 80%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 10;
}

.footer {
  text-align: right;
  font-size: 10px;
  color: #777;
  margin-top: 10px;
}

.side-buttons {
  position: absolute;
  top: 90px;
  right: -8px;
  width: 3px;
  height: 90px;
  background-color: #666;
  border-radius: 8px;
}

.volume-buttons {
  position: absolute;
  top: 130px;
  left: -8px;
  width: 3px;
  height: 50px;
  background-color: #666;
  border-radius: 8px;
}

.silent-switch {
  position: absolute;
  top: 120px;
  left: -8px;
  width: 3px;
  height: 15px;
  background-color: #666;
  border-radius: 8px;
}
  .cfa-block{
      background-color: #fff;
      border-radius: 0.5em;
      padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      /* border: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  }
  
  /* TAB STYLE */
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    /*border-bottom: 1px solid #ccc;*/
  }
  
  .tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  
  .tab-header ul li.active {
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-header ul li:hover{
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 20px;
}
  /* .body-div{
    margin-top:30px;
margin-left:40px;
  box-shadow:  rgba(133, 130, 130, 0.2), 0 6px 20px 0 rgba(71, 70, 70, 0.19);
  
} */
  
  .tab-body {
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
  }
  .gFolder-container{
      background-color: var(--primary-light-color-2);
      border-radius: 0.5em;
      height: 4.3em;
      width: 4.6em;
      margin-right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .gBlock{
      align-items: center;
      justify-content: start;
      border-radius: 0.6em;
      padding-left: 2em;
      padding-right: 2em;
      height: 8em !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .gBlock div:last-of-type p{
      margin: 0;
  }
  .gBlock div:last-of-type p:first-of-type{
      font-weight: 400;
      font-size: 2em;
  }
  .tab_font{
      font-size: 13px;
  }
  .user-row{
      background: #fff;
      padding-left: 1em;
      margin-bottom: 0.2em;
  }
  .row-user{
      transition: .2s all ease-in;
  }
  .row-user:hover{
      background: #f5f5f5;
      cursor: pointer;
  }
  
  .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .left-button,
  .right-button {
      padding: 10px;
      cursor: pointer;
      background-color: lightgray;
  }
  
  .middle-div {
      flex: 1;
      margin: 0 10px;
  }
  
  .content {
      width: 100%;
      height: 461px;
      border: 1px solid #e0e1e8;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
  }
  .cfa-section-title{
      color: #fff;
  }
  .cfa-btn-primary{
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  
  </style>