<template>
    <div class="cfa-root">
      <c-f-a-nav  :alert="alertString">
        <template v-slot:title>
          <div class="w-100 pt-2 text-start mt-0 mb-0" >
              <h1 class="cfa-section-title" style="color:white;font-weight:300;margin-top:20px;margin-bottom: 50px;margin-left:40px">{{$t('menu_micro_awareness')}}</h1>
          </div>
        </template>
        <template v-slot:content>
          <div v-if="!waitingAPIResponse" class="cfa-page" >
              <div style="height:auto;">
             
              <div class="tab mt-1" >
                
                 
                      
                  <div class="tab-body" style="margin-bottom: 1em;border: none;">
                    <div class="page-container">
    <!-- Banner Section -->
    <div class="banner-section">
      <div class="banner-text">
        <h2>{{ allModules.title }}</h2>
        
      </div>
      <div class="banner-image">
        <img src="../../assets/Icons/menu_svg/icon.png" alt="Banner Image" />
      </div>
    </div>
    <div >
                <h4 style="color:#22007C;font-size:14px;" class="mt-3">{{$t('micro_awareness_duration')}} {{seconds}} Secondes</h4>
              </div>
              <div>
                <h4 style="color:#132B4D;font-size:20px;font-weight:600;" class="mt-3">{{allModules.title}}</h4>
              </div>
              
                <div style="height: 5em; " class="py-2 pb-3 text-justify" v-html="allModules.description"></div>
    <!-- Content Section -->
    <div class="content-section">
      <!-- Left: Email Message -->
      <div class="email-message">
        <div class="email-header">
          <h5>Nouveau message</h5>
          <button style="background-color:#132B4D;color:white;border:1px solid #132B4D;margin-right:20px;"><i class="fa fa-external-link-alt"></i></button>
        </div>
        <div class="email-body">
          <p><strong>Objet:  </strong> {{ allModules.title }}</p>
          <p style="text-align:justify;padding:10px;line-height:1.6;margin-top:30px;">Bonjour , <br>{{allModules.top_email_message}}<br></p>
          <img :src="allModules.cover_image" alt="Cover Image" />
          <p style="text-align:justify;padding:10px;line-height:1.6;margin-top:30px;">{{allModules.bottom_email_message}}</p>
        </div>
        <div style="width:50px;height:auto;margin:0 auto;">
          <img  v-if="userList.organisation && userList.organisation.favicon != null" :src="userList.organisation.favicon" alt="cfa module course image" style=" width: 100%; height: auto;" />
        </div>
      </div>

      <!-- Right: SMS Message -->
      <div class="sms-message">
            <div  class="iphone" style="margin-left:80px;">
               <div class="side-buttons"></div>
            <div class="volume-buttons"></div>
          <div class="silent-switch"></div>
          <div class="screen">
          <div class="header">SPECTORLY</div>
        <div class="message-container">
           <div class="message">
            Bonjour {{subscriptionData.firstname}},<br><br>
          {{allModules.sms_message}}
          <br><br>
          participation ici: 
          <a href="https://spector.ly/Abc2P" style="color: #06BBC4; text-decoration: none;">https://spector.ly/Abc2P</a>
          <br><br>
          Merci et bonne journée !!!<br><br>
          --{{userList.name}}--
        
          </div>
       </div>
      
     </div>
  </div>
</div>
    </div>
   
    <!-- Footer Section -->
    <div class="footer-section">
      <h4 class="test-buttons" style="background-color:none;">Message email</h4>
      <h4 class="use-buttons" style="background-color:none;">Message SMS</h4>
    </div>

    <!-- Action Buttons -->
    <div class="action-buttons">
      <button class="test-button mx-1" @click="openUpdateBox=true">{{ $t('micro_awareness_test_this_micro_awareness') }}</button>
      <button class="use-button mx-1" @click="$router.push(`/createCampaign/${this.$route.params.id}`)">{{ $t('micro_awareness_use_this_micro_awareness') }}</button>
    </div>
  </div>
</div>
</div>
</div>
</div>    
              <div v-if="waitingAPIResponse" class="cfa-page">
              <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                  <div class="preloader_center d-flex flex-column">
                      <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                      <p class="pl-3 fw-bold">chargement...</p>
                  </div>
              </div>
          </div>
          
          
          
          <!-- create -->
          
          <!-- update -->
          
          <!-- create campaign -->
          
         
          <scalable-modal1 @callback="updateThisModule" :break="exitUpdateModal" :uid="uid + '-update-modal'" @close="openUpdateBox=false" :open="openUpdateBox" :advancedControl="true" validationButtonText="Testez" exitButtonText="Annuler" displayMode="flex" :style="'width: 36em; height: 25em;'" class="micro-learning-test-modal" >
        <template v-slot:content>
              <div class="row" style="display:flex;margin-bottom:70px;justify-content:space-between;">
                <h6 style="color:#000000; font-size:20px;margin-left:3px;margin-top:5px;margin-bottom:20px;">Testez une campagne</h6>
                <p style="color:#808080; font-size:13px;">Par quel moyen souhaitez vous tester la campagne?</p>
              <div class="col-md-6 d-flex align-items-center account-type-block" >
                <input type="radio" value="email" class="input-select-adj" v-model="picked" style="background-color:#b5e5e7;" />
                
                <label class="form-label" style="margin-top:10px;margin-left:10px;">
            
                  {{$t('micro_awareness_by_email')}}
          </label>
              </div>
              <div class="col-md-6 d-flex align-items-center account-type-block" >
                <input type="radio" value="sms" class="input-select-adj" v-model="picked" style="background-color:#b5e5e7;" />
                <label class="form-label" style="margin-top:10px;margin-left:10px;">
            
           {{$t('micro_awareness_by_sms')}}
          </label>
            </div>
            <div v-if="picked == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2" style="color:#808080;">{{$t('message_box_email_label_text')}}</p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control email-picked-input" type="email" :placeholder="$t('message_box_enter_email_text')"  @blur="validateEmail" v-model="email">
                                    
                                  </div>
                                  <span v-if="emailError" class="error-message">{{ emailError }}</span>
                              </div>
                          </div>
                          <div v-if="picked == 'sms'" class="row w-100 m-inherit" style="margin-top:30px;">
                          <div class="form-group">
                            <!-- <phone-input @inputChange="catchPhoneInputOrg" :syncField="syncTime" :placeholder="$t('message_box_enter_phone_number_text')" errorText="Champ requis" uid="cfa-phone-org-bloc2" type="text"></phone-input> -->
                            <input-phone @updateNumber="updatePhoneNumber"  :textHolder="60000000" />

                          </div>
                          </div>
            </div>
  
        </template>
      </scalable-modal1>
        </template>
      </c-f-a-nav>
      <c-f-a-footer></c-f-a-footer>
    </div>
  
  </template>
  
  <script>
  
  import CFANav from '@/components/nav/CFANav.vue'
//   import Table from "@/components/shared/table/Table.vue";
//   import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
  import ScalableModal1 from "@/components/shared/modal/ScalableModal.vue";
  import 'form-wizard-vue3/dist/form-wizard-vue3.css'
//   import Wizard from 'form-wizard-vue3'
// //   import CFAVideo from '@/components/shared/video/CFAVideo.vue'
//   import VueDatePicker from '@vuepic/vue-datepicker';
  //import PhoneInput from '@/components/shared/input/PhoneInput1.vue'
  import CFAFooter from '@/components/nav/CFAFooter.vue'
  import '@vuepic/vue-datepicker/dist/main.css'
  //import CourseCard from '@/components/shared/course/CourseCard3.vue'
  import {getAPI} from "@/axios-api";
  import { addHours } from 'date-fns';
  // import SsrCarousel from 'vue-ssr-carousel'
  // import ssrCarouselCss from 'vue-ssr-carousel/index.css'
  import InputPhone from '@/components/shared/input/InputPhone.vue'

  
  export default {
      name: 'PhishingView',
      components: {
        //   ScalableModal,
        CFAFooter,
          ScalableModal1,
        //   CFAVideo,
          //PhoneInput,
         // CourseCard,
          CFANav,
        //   Wizard,
        //   VueDatePicker,
          // SsrCarousel,
          // ssrCarouselCss,
          InputPhone
      },
      data () {
          return {
              minDate: new Date(),
              minDateEnd: new Date(),
              waitingAPIResponse: true,
              waitingAPIResponse2: true,
              serviceAllData:[],
              openUpdateBox:false,
              newCampaignTitle:'',
              newcampaignUpdate:false,
              newCampaignSmsSenderName:'',
              newCampaignEmailSenderName:'',
              isConnected: true,
              picked:'',
              alertString: null,
              currentTab: localStorage.tab_phish_active||'campaign_activate',
              newCampaigndefaultmsg:'',
              campaignsAll: [],
              campaignsAllOk: [],
              campaignsAllColumns: ['n°', 'Nom', 'Campaign Type', 'Date création', 'Actions'],
              campaignsAllToListen: ['Actions'],
              campaignByid:[],   
              emailError: '',             
              groupAll: [],
              groupAllColumns: ['n°', 'Nom', 'Utilisateurs', 'Denière modification', 'Actions'],
              groupAllToListen: ['Actions'],
              microlearning:[],
              allModules: [],
              reportsAll: [],
              reportsAllColumns: ['n°', 'Campagne', 'Utilisateurs', 'Actions'],
              reportsAllToListen: ['Actions'],
              newCampaignEmailSignature:'',
              userGroupAll: [],
              userGroupAllColumns: ['Nom', 'Prénoms', 'Email', 'Position', 'Actions'],
              userGroupAllToListen: ['Actions'],
              newCampaignSmsSignature:'',
              openDeletionBox: false,
              tempDeleteID: null,
              tempDeleteName: null,
              updateGroupPayload: null,
              openUpdateGroup: false,
              name_check: null,
              callbackPayload: null,
              exitOrgModalDel: null,
              exitOrgUpdateModal: null,
              newFirstname: null,
              newLastname: null,
              newEmail: null,
              newPosition: null,
              newCampaignService:'',
              openDeletionCampaignBox: false,
              newCampaignForm: false,
  
              newCampaignName: null,
              newCampaignType: "default",
              newCampaignDateStart: null,
              newCampaignDateEnd: null,
              newCampaignGroupe: null,
              newCampaignEmailTemplate: null,
              newCampaignLandingTemplate: null,
              newCampaignTarget:'',
              currentTabIndex: 0,
              userList: [],
              exitUpdateModal:null,
              selectedUser: [],
              credit_mail: '•••',
              credit_sms: '•••',
              total_credit_mail: '•••',
              total_credit_sms: '•••',
              openNewGroup: false,
              exitNewGroupModal: null,
              updateGroupData: {},
              updateGroupStatusRequest: true,
              flattenedIds: [],
              exitGroupUpdateModal: null,
              groupAllData: [],
              totalModule:0,
              groupeApiWaiting: false,
              groupApiWaitingData: [],
              groupeApiTemplateWaiting: false,
              groupApiTemplateWaitingData: [],
              defaultImage: " /images/defaultImage.png",
              waitingAPITemplate: true,
              waitingAPITemplate2: true,
  
              templateData: [],
              currentTemplateIndex: 0,
              currentTemplate: null,
  
              templateData1: [],
              currentTemplateIndex1: 0,
              currentTemplate1: null,
              email:'',
              seconds:'',
              templateChoosed: "",
              pageChoosed: "",
              privacyCheck: null,
              campaignWaiting: [],
  
              subscriptionData: [],
          }
      },
      
      methods: {
        timeToSeconds(timeString) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Convert hours, minutes, and seconds to total seconds
    this.seconds = (hours * 3600) + (minutes * 60) + seconds;

    return this.seconds;
},
        validateEmail() {
      if (!this.email) {
        this.emailError = this.$t('message_box_enter_email_text');
      } else if (!this.isValidEmail(this.email)) {
        this.emailError = this.$t('invalid_email_message');
      } else {
        this.emailError = '';
      }
    },
    isValidEmail(email) {
      // Basic email validation regex
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
        catchPhoneInputOrg (data) {
        this.phoneNumber = data;
        console.log(this.phoneNumber);
      },
      updatePhoneNumber (number) {
        this.phoneNumber = number
      },
      updateThisModule() {
      if(this.picked == 'email') {
        getAPI.post(`micro_learning/test/email/${this.$route.params.id}/`,{email:this.email}).then((response) => {
          if(response.status == 200) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "L'e-mail a été envoyé avec succès.",
                          type: 'success',
                          icon:'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          });    

            // let msg = 'Micro Learning MAJ avec succès!'
            //   this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            if(this.module_image_cover != null) {
              console.log(response);
             
              let msg = 'Micro Learning MAJ avec succès!'
              this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            }
            else{
              //this.$emit('update');
              // let msg = 'Micro Learning MAJ avec succès!'
              // this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            }
          }
          
        }).catch((error) => {
          if (error.response) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "L'email n'est pas envoyé",
                          type: 'error',
                          icon:'error',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          }); 
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          else if (error.request) {
            this.alertString('The request was made but no response was received. Please check your network.', 'alert-no', 10000)
          }
          else {
            this.alertString('Oups ! something went wrong.', 'alert-no', 10000)
          }
          this.exitUpdateModal = Date.now()
        });
      }
      else{
        getAPI.post(`micro_learning/test/sms/${this.$route.params.id}/`,{
          //phone:`+` + this.phoneNumber.countryCode + this.phoneNumber.phone.replaceAll('-', '')
          phone: this.phoneNumber
        }).then((response) => {
          if(response.status == 200) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "Le sms a été envoyé avec succès.",
                          type: 'success',
                          icon:'success',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          });  
            // let msg = 'Micro Learning MAJ avec succès!'
            //   this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            if(this.module_image_cover != null) {
              console.log(response);
            }
            else{
              //this.$emit('update');
              // let msg = 'Micro Learning MAJ avec succès!'
              // this.alertString = `${msg}|alert-yes|8000::`+Date.now()
            }
          }
          this.exitUpdateModal = Date.now()
        }).catch((error) => {
          if (error.response) {
            this.exitUpdateModal = Date.now()
            this.$swal({
                          title: '',
                          text: "Le sms n'est pas envoyé",
                          type: 'error',
                          icon:'error',
                          showCancelButton: false,
                          confirmButtonText: "Continuez"
                          }); 
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          else if (error.request) {
            this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 10000)
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          this.exitUpdateModal = Date.now()
        });
      }
    },
          retrieveUserSubscription () {
              this.waitingAPIResponse = true
              getAPI.get(`accounts/users/me`).then(response => {
                if(response.status == 200) {
                  this.subscriptionData = response.data.data
  
                  if(this.subscriptionData != null){
                  console.log("Infos: ", this.subscriptionData)
                  }
                  
                  this.remain_days = this.subscriptionData.remain_days
  
                  const created_at = new Date(this.subscriptionData.created_at)
                  created_at.setDate(created_at.getDate() + 30);
                  
                  const expirationDate = created_at.toISOString();
  
                  this.expiration_date = expirationDate
                }
                else {
                  console.log("Subslength: ", this.subscriptionData.length)
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                setTimeout(() => {
                    this.waitingAPIResponse = false
                }, 2000);
              }).catch(error => {
                  if (error.response) {
                      //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                  }
                  else if (error.request) {
                      //let msg = 'The request was made but no response was received. Please check your network.'
                      //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                  }
                  else {
                      //let msg = 'Oups ! something went wrong.'
                      //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                  }
                  this.waitingAPIResponse = false
              })
        },
          updatePrivacyCheck() {
              this.privacyCheck = !this.privacyCheck;
              
              console.log("State: ", this.privacyCheck);
              console.log("Coosed: ", this.templateChoosed);
              //setTimeout(() => {
               //   ""
              //}, 0);
          },
          selectAll() {
              this.selectedUser = this.userList.map(user => user.id);
          },
          selectTab (section) {
              localStorage.setItem('tab_phish_active', section)
              this.currentTab = section
          },
          convertDate(dateString, dateOnly=false) {
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString();
              const formattedTime = date.toLocaleTimeString();
              if(!dateOnly) {
                  return `<b>${formattedDate} à ${formattedTime}</b>`;
              }
              return `<b>${formattedDate}</b>`;
          },
          getAllModule () {
        this.waitingAPIResponse = true
        getAPI.get(`micro_learning/courses/${this.$route.params.id}/`)
        .then(response => {
          if(response.status == 200) {
            let modulesData = response.data
            this.allModules = modulesData
            this.timeToSeconds(this.allModules.duration);
            this.totalModule = modulesData.count
            // TODO: make this dynamic
            this.totalInProgress = 1
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      },
          getAllGroup () {
              this.waitingAPIResponse = true
              getAPI.get(`groupes`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.data;
                          this.groupAllData = response.data.data;
                          this.groupAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let users_count = `
                                  <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                              `;
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.groupAll.push({'n°': (i+1), 'Nom': `<span class="selectable" style="color: #132B4D">${row.name}</span>`, 'Utilisateurs':users_count, 'Denière modification':this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id})
                          }
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getAllService () {
              this.waitingAPIResponse = true
              getAPI.get(`services`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.serviceAllData = response.data.data;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getAllMicroLearning () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/courses/`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.microlearning = response.data.results;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          getMembers () {
              this.waitingAPIResponse2 = true
              // let org = localStorage.getItem('organisation')
              getAPI.get(`subscriptions/find-subscription`)
                  .then(response => {
                    console.log(response, "microlearning details")
                      if(response.status == 200) {
                          this.userList = response.data.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      console.log(error, "microlearning details error")
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse2 = false
                  })
          },
          templateSelect () {
              this.templateChoosed = this.currentTemplate.id;
              console.log("Template choisi: ", this.templateChoosed);
          },
          pageSelect () {
              this.pageChoosed = this.currentTemplate1.id;
              console.log("Page choisi: ", this.pageChoosed);
          },
          getTemplate () {
              this.waitingAPITemplate2 = true
              getAPI.get(`phishing/templates/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData = response.data.data;
                          this.currentTemplate = this.templateData[this.currentTemplateIndex];
                          this.templateChoosed = this.currentTemplate.id;
                          console.log("Etat: ", this.temSelected)
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate2 = false
                  })
          },
          getLanding () {
              this.waitingAPITemplate = true
              getAPI.get(`phishing/landing-page/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData1 = response.data.data;
                          this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
                          console.log(this.currentTemplate1)
                          this.pageChoosed = this.currentTemplate1.id;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate = false
                  })
          },
          updateGroup (pos) {
              this.updateGroupPayload = pos
              this.openNewGroup = true
          },
          newGroup () {
              this.userGroupAll = []
              this.updateGroup({name: ''});
              this.getMembers();
          },
          confirmNewGroup () {
              console.log(this.selectedUser)
              console.log(this.updateGroupPayload.name)
              if(this.updateGroupPayload.name != '' && this.selectedUser.length > 0) {
                  getAPI.post(`groupes/`, {
                      name: this.updateGroupPayload.name,
                      organisation: localStorage.organisation,
                      users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 201) {
                          this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitNewGroupModal = Date.now()
                  })
                  .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now()
                      })
              }
              else {
                  this.exitNewGroupModal = Date.now()
                  //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
          },
          
         
          
          getAllCampaign () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/campaigns/`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.results;
                          this.campaignsAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.campaignsAll.push({
                                  'n°': (i+1),
                                  
                                  'Nom': `<span class="selectable">${row.name}</span>`,
                                  'Campaign Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span>` : `<span class="badge rounded-pill badge-success cfa-bg-danger mw-status">IN PROGRESS</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id
                              });
                          }
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
             
          },
          newCampaign () {
              this.newCampaignForm=true;
          },
          prevTemplate() {
              if (this.currentTemplateIndex > 0) {
                  this.currentTemplateIndex--;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          nextTemplate() {
              if (this.currentTemplateIndex < this.templateData1 - 1) {
                  this.currentTemplateIndex++;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          prevLanding() {
              if (this.currentTemplateIndex1 > 0) {
                  this.currentTemplateIndex1--;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
          nextLanding() {
              if (this.currentTemplateIndex1 < this.templateData1.length - 1) {
                  this.currentTemplateIndex1++;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
  
          getCallToActionForCampaigns (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionCampaignBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            
            }
            if(payload.action_type == 'view') {
               this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                        this.campaignByid = response.data;
                         
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
            }
          },
          
          confirmDeletion2 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
                this.openDeletionCampaignBox = false
            }
            else{
                 getAPI.delete(`micro_learning/campaigns/${this.tempDeleteID}/`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Csmpaign supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllCampaign()
                            }, 1500)
                            this.openDeletionCampaignBox = false
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgModalDel = Date.now()
                            this.openDeletionCampaignBox = false
                        }
                    })
                    .catch(() => {
                        //this.exitOrgModalDel = Date.now()
                        this.openDeletionCampaignBox = false
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
            this.openDeletionCampaignBox = false
          },
          
  
          // for stepper logic
          onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
          wizardCompleted() {
              
                  const groups = [];
                  const service = [];
                //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
                //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
                  service.push(this.newCampaignService)
                  groups.push(this.newCampaignGroupe);
                  console.log(groups);
                  let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                  newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                  
                  getAPI.post(`micro_learning/campaigns/`, {
                      name: this.newCampaignName,
                      micro_learning_id: this.newCampaignTitle,
                      campaign_type: this.newCampaignType,
                      sms_send_name: this.newCampaignSmsSenderName || null,
                      group: this.newCampaignGroupe || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                      email_send_name: this.newCampaignEmailSenderName || null,
                      sms_send_signature: this.newCampaignSmsSignature || null,
                      email_send_signature: this.newCampaignEmailSignature || null,
                      planification_date: newCampaignDateStartF,
                      default_message:this.newCampaigndefaultmsg,
                      is_validated_by_admin: false,
                      service:this.newCampaignService || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                      status:"unavailable"
                      
                  })
                      .then(response => {
                          if(response.status === 201) {
                              this.getAllGroup()
                              this.getAllCampaign()
                              //let msg = 'Campagne crééé avec succès!'
                              this.campaignWaiting = response.data;
                              console.log("Camp: ", this.campaignWaiting)
                              //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          }
                          else {
                              
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                          this.$swal({
                              title: 'Campagne créée avec succès',
                              text: 'Campagne créée avec succès. En attente de validation.',
                              type: 'warning',
                              showCancelButton: false,
                              confirmButtonText: "D'accord !"
                          });
                      })
                      .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                      });
              
              
          },
          wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              let newCampaignDateStartF = new Date(this.campaignByid.planification_date);
              newCampaignDateStartF = addHours(this.campaignByid.planification_date, 3);
              
              getAPI.put(`micro_learning/campaigns/${this.tempDeleteID}/`, {
                  name: this.campaignByid.name,
                  micro_learning_id: this.campaignByid.micro_learning_id,
                  campaign_type: this.campaignByid.campaign_type,
                  sms_send_name: this.campaignByid.sms_send_name || null,
                  group: this.campaignByid.group || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                  email_send_name: this.campaignByid.email_send_name || null,
                  sms_send_signature: this.campaignByid.sms_send_signature || null,
                  email_send_signature: this.campaignByid.email_send_signature || null,
                  planification_date: newCampaignDateStartF,
                  default_message:this.campaignByid.default_message,
                  is_validated_by_admin: false,
                  service:this.campaignByid.service || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                  status:"unavailable"
                  
              })
                  .then(response => {
                      if(response.status === 200) {
                          this.getAllGroup()
                          this.getAllCampaign()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                      this.$swal({
                          title: 'Campagne créée avec succès',
                          text: 'Campagne créée avec succès. En attente de validation.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
      },
      mounted () {
        this.getAllModule ();
        this.getMembers ();
          //console.log("Templates: ", this.templateData)
          this.getAllGroup();
          this.getAllService ();
          this.getAllCampaign();
          this.retrieveUserSubscription();
          this.getAllMicroLearning ();
      },
      computed: {
          nextButtonOptions() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.newCampaignType === 'default' || this.newCampaignName == null  )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          nextButtonOptionss() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.campaignByid.name == null || this.campaignByid.campaign_type === 'default' )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          prevButtonOptions() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignTarget == 'default') ? {
                  disabled: true,
                  text: "Terminé"
              }: {
                  disabled: false,
                  text: "Terminé"
              };
          },
      },
      watch: {
          newCampaignGroupe: function (value) {
              this.groupeApiWaiting = true;
              getAPI.get(`groupes/${value}`)
                  .then(response => {
                      if(response.status == 200) {
                          this.groupApiWaitingData = response.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.groupeApiWaiting = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.groupeApiWaiting = false
                  })
          },
        //   currentTabIndex: function (value) {
        //       if(value === 2) {
        //           if(this.templateData.length === 0) {
        //               this.getTemplate();
        //               console.log("Tab templates: ", this.templateData)
        //           }
        //       }
        //       if(value === 4) {
        //           if(this.templateData1.length === 0) {
        //               this.getLanding();
        //           }
        //       }
        //   },
      }
  }
  </script>
  
  <style scoped>
  .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-text p {
  font-size: 24px;
  font-weight: bold;
}

.banner-image img {
  max-width: 50px;
}

.info-section {
  text-align: center;
  margin-top: 20px;
}

.info-duration {
  color: #22007c;
  font-size: 14px;
}

.info-title {
  color: #132b4d;
  font-size: 20px;
  font-weight: 600;
}

.info-description {
  margin-top: 10px;
  padding: 10px;
  text-align: justify;
}

.content-box {
  margin-top: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(222, 234, 250, 0.87);
  padding: 20px;
}

.header-box {
  background-color: #132b4d;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 12px 12px 0 0;
}

.email-content {
  margin-top: 20px;
  text-align: left;
  line-height: 1.6;
}

.image-section {
  margin-top: 20px;
  overflow: hidden;
  width: 100%;
}

.image-section img {
  width: 100%;
  height: auto;
}

h6 {
  margin-top: 20px;
  color: #1e1e1e;
  font-weight: 300;
}

.logo-container {
  margin-top: 10px;
  text-align: center;
}

.logo-container img {
  max-width: 100px;
  height: auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-two,
.btn-primary {
  padding: 10px 20px;
  background-color: #132b4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #06bbc4;
}

@media (max-width: 768px) {
  .banner,
  .footer-content,
  .action-buttons {
    flex-direction: column;
    align-items: center;
  }

  .banner-text p {
    font-size: 18px;
  }

  .info-section {
    margin-top: 15px;
  }

  .content-box {
    width: 100%;
  }

  .btn-two,
  .btn-primary {
    width: 100%;
    margin-bottom: 15px;
  }
}
  .error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}
  @media screen and (min-width: 1200px) {
    .container {
        display: flex;
        justify-content: space-evenly;
        /* flex:1; */
        /* gap: 0; */
        
    }
    /* .hello-page, .iphone {
        width: 28%;
        flex: 1;
        
    } */
}
@media screen and (min-width: 1200px) {
    .hello-page,
    .iphone {
        width: 35%;
        
    }
}

@media screen and (max-width: 1199px) {
    .hello-page,
    .iphone {
        width: 48%;
        margin-left:50px;
         /* or reduce it to a desired value */
   
    }
}

  .cfa-page{}
  .cfa-block{
      background-color: #fff;
      border-radius: 0.5em;
      padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      /* border: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  }
  
  /* TAB STYLE */
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    /*border-bottom: 1px solid #ccc;*/
  }
  
  .tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  height:auto;
}

.banner-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #132b4d;
  padding: 20px;
  border-radius: 10px;
}

.banner-text {
  font-size: 20px;
  font-weight: bold;
}

.banner-text p {
  margin-top: 10px;
  font-size: 14px;
}

.banner-image img {
  width: 150px;
  height: auto;
}

.content-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.email-message,
.sms-message {
  width: 48%;
  background-color: white;
  border-radius: 12px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  padding: 20px;
}

.email-header {
  display: flex;
  justify-content: space-between;
  background-color: #132b4d;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.email-body {
  padding: 20px;
}

.email-body img {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.sms-message {
  padding: 20px;
  background-color: #f5faff;
  border-radius: 12px;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  text-align: center;
}

.footer-section h4 {
  font-size: 18px;
  color: #000;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.test-button,
.use-button {
  width: 48%;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.test-buttons,
.use-buttons {
  width: 38%;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.test-button {
  background-color: #00c4b3;
  color: white;
}

.use-button {
  background-color: #001f3f;
  color: white;
}.test-buttons {
    /* background-color: white; */
  color: #001f3f;
}

.use-buttons {
    /* background-color: white; */
  color: #001f3f;
}
  .iphone {
  width: 400px;
  height: 780px;
  background-color: #17153B; /* Couleur de la bordure du téléphone */
  border-radius: 30px; /* Bordure légèrement réduite */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 15px; /* Réduction de l'épaisseur de la bordure */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left:120px;
}

.iphone::before {
  content: '';
  display: block;
  width: 80px;
  height: 20px;
  background-color: #17153B;
  border-radius: 15px 15px 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.iphone::after {
  content: '';
  display: block;
  width: 40px;
  height: 5px;
  background-color: #666;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.screen {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px; /* Réduction de la bordure de l'écran */
  padding: 25px 10px 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header {
  font-size: 14px;
  font-weight: bold;
  color: #06BBC4;
  text-align: center;
  margin-bottom: 5px;
}

.message-container {
  display: flex;
  justify-content: center;
  margin-top:30px;
}

.message {
  font-size: 12px;
  color: #fff;
  background-color: #132B4D;
  padding: 10px 15px;
  border-radius: 20px;
  line-height: 1.5;
  flex-grow: 1;
  max-width: 80%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-top: 10;
}

.footer {
  text-align: right;
  font-size: 10px;
  color: #777;
  margin-top: 10px;
}

.side-buttons {
  position: absolute;
  top: 90px;
  right: -8px;
  width: 3px;
  height: 90px;
  background-color: #666;
  border-radius: 8px;
}

.volume-buttons {
  position: absolute;
  top: 130px;
  left: -8px;
  width: 3px;
  height: 50px;
  background-color: #666;
  border-radius: 8px;
}

.silent-switch {
  position: absolute;
  top: 120px;
  left: -8px;
  width: 3px;
  height: 15px;
  background-color: #666;
  border-radius: 8px;
}
  .tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  
  .tab-header ul li.active {
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-header ul li:hover{
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-body {
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    height: auto;
  }
  .gFolder-container{
      background-color: var(--primary-light-color-2);
      border-radius: 0.5em;
      height: 4.3em;
      width: 4.6em;
      margin-right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .gBlock{
      align-items: center;
      justify-content: start;
      border-radius: 0.6em;
      padding-left: 2em;
      padding-right: 2em;
      height: 8em !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .gBlock div:last-of-type p{
      margin: 0;
  }
  .gBlock div:last-of-type p:first-of-type{
      font-weight: 400;
      font-size: 2em;
  }
  .tab_font{
      font-size: 13px;
  }
  .user-row{
      background: #fff;
      padding-left: 1em;
      margin-bottom: 0.2em;
  }
  .row-user{
      transition: .2s all ease-in;
  }
  .row-user:hover{
      background: #f5f5f5;
      cursor: pointer;
  }
  
  .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .left-button,
  .right-button {
      padding: 10px;
      cursor: pointer;
      background-color: lightgray;
  }
  
  .middle-div {
      flex: 1;
      margin: 0 10px;
  }
  
  .content {
      width: 100%;
      height: 461px;
      border: 1px solid #e0e1e8;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
  }
  .cfa-section-title{
      color: #fff;
  }
  .cfa-btn-primary{
    width:286px;
    height:55px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  .btn-two{
    background-color:#06BBC4;
    border:1px  solid #06BBC4;
    border-radius:6px;
    color:#fff;
    width:286px;
    font-size:13px;
    height:55px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  .sidebar-img{
    background:url('../../assets/sidebarimg.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left:140px;
    width:605px;
  }
  .sidebar-img p{
   background-color:#E6F8F9;
   margin-top:127px;
   text-align:center;
   line-height:3;
   margin-left:35px;
   margin-right:40px;
   height:385px;
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1c2b4a; /* Dark Blue Background */
    padding: 20px;
    border-radius: 15px;
    width: 100%;
}

.banner-text {
    color: #58c3c1; /* Text color matching the blue theme */
    font-size: 1.5rem; /* Adjust font size */
    font-weight: bold;
    margin-left: 20px;
}

.banner-image img {
    max-width: 150px; /* Adjust the width as necessary */
    height: auto;
    border-radius: 50%;
    margin-right: 20px;
}

  </style>