<template>
    <div class="cfa-root">
      <c-f-a-nav  :alert="alertString">
        <template v-slot:title>
          <div class="w-100 pt-2 text-start mt-5 mb-0" >
            <div class="d-flex justify-content-between">
                <h1 class="cfa-section-title" style="color:white;font-weight:300;margin-top:20px;margin-bottom: 50px;margin-left:40px">
                    {{$t('menu_micro_awareness')}} 
                </h1>
                <div class="bg-white d-flex py-3 gBlock">
                    <div class="p-3 gFolder-container">
                        <img width="30" src="@/assets/img/png/phishing.png" alt="dashboard state image"/>
                    </div>
                    <div>
                        
                        <p>{{ subscriptionData?.remain_credit_microlearning || 0 }}</p>
                        <p>{{ $t('microlearn_credit_title') }}</p>
                    </div>
                </div>
            </div>
            <div style="display:flex;justify-content:space-between">
              <h4 style="color:white;margin-left:40px;">{{$t('micro_awareness_ressources')}}</h4>
              <button class="btn cfa-btn-primary mx-1" @click="$router.push('/micro-campaign')">{{$t('list_of_micro_awareness')}}</button></div>
          </div>
        </template>
        <template v-slot:content>
          <div v-if="!waitingAPIResponse" class="cfa-page">
              
             
              <div class="tab mt-1">
                
                  <!-- <div class="tab-header" style="margin-bottom: 1em;">
                      <ul class="tab-ul">
                          <li :class="{ 'active': currentTab === 'campaign_activate' }" @click="selectTab('campaign_activate')" class="d-md-flex d-none badge rounded-pill cfa-tab-btn tab_font"> Campagnes&nbsp;active</li>
                          
                      </ul>
                      <div class="d-flex align-items-center justify-content-end p-1">
                      <div v-if="subscriptionData?.remain_credit_phishing > 0">
                          <button v-if="currentTab == 'campaign_activate' || currentTab == 'campaign_archived' || currentTab == 'reports'" @click="newCampaign" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.5em;">
                              <span class="d-md-flex d-none">NOUVELLE&nbsp;</span>
                              <span>CAMPAGNE</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                          </button>
                      </div>
                      <div v-if="subscriptionData?.remain_credit_phishing <= 0">
                          <button disabled v-if="currentTab == 'campaign_activate' || currentTab == 'campaign_archived' || currentTab == 'reports'" @click="newCampaign" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.5em;">
                              <span class="d-md-flex d-none">NOUVELLE&nbsp;</span>
                              <span>CAMPAGNE</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                          </button>
                      </div>
                          <button v-if="currentTab == 'groups'" @click="newGroup" class="btn cfa-btn-primary d-flex align-items-center" style="height: 2.5em;">
                              <span class="d-md-flex d-none">NOUVEAU&nbsp;</span>
                              <span>GROUPE</span>&nbsp;<i class="fa fa-plus">&nbsp;</i>
                          </button>
                      </div>
                  </div> -->
                  <div class="tab-body" style="margin-bottom: 1em;border: none;">
                    <div class="row pt-3  mt-4">
                        <course-card  v-for="module, i in this.allModules" v-bind:key="i" @update="getAllModule" :data="module"  :id="module.id" :type="module.title"  :preview="module.cover_image"   :title="module.title" :description="module.description" :max__horaire="timeToSeconds(module.duration)" :viewMode='displayMode' :filterMode="filterMode"   @sync="getAllModule" :uid="'cfa-crud-module-'+i" class="micro-learning-card" ></course-card>
                    </div>
                      <!-- <div v-if="currentTab === 'campaign_activate'">
                          <Table :hide-control="(campaignsAll.length == 0) ? true :  false" :i="4" :columns="campaignsAllColumns" :data="campaignsAll" :columns-action="campaignsAllToListen" @action="getCallToActionForCampaigns" :customized-default-image="true" :last-cell-width="0">
                              <template v-slot:defaultImage>
                                  <img src="@/assets/img/gif/campagne.gif" alt="empty content" style="width: 11em;"/>
                                  <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune campagne !</span>
                              </template>
                          </Table>
                      </div> -->
                      
                      
                      
                  </div>
              </div>
          
          </div>
          
              <div v-if="waitingAPIResponse" class="cfa-page">
              <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
                  <!-- <div class="preloader_center d-flex flex-column">
                      <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                      <p class="pl-3 fw-bold">chargement...</p>
                  </div> -->
                  <div class="loader-pulse">
                    <div class="logo-placeholder">
                        <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
                        <span class="loader-text">{{ $t('loading') }}</span>
                    </div>
                </div>
              </div>
          </div>
          <!-- Modal zone-->
          <!-- deletion -->
          
          <scalable-modal @callback="confirmDeletion2" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'phish-campaign-deletion-modal'" @close="openDeletionCampaignBox=false" :open="openDeletionCampaignBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
                <template v-slot:content>
                    <p>{{$t('deleteCampaignMessage') }}<span style="color: red;">{{$t('irrevesibleMessage')}}</span></p>
                    <div class="col-12 pl-0">
                        <div class="form-group py-3 pe-0 pe-md-2">
                            <p class="mb-2"><strong>{{$t('deletecampaignconfirmation')}}</strong></p>
                            <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
                        </div>
                    </div>
                </template>
            </scalable-modal>
          <!-- create -->
          
          <!-- update -->
          
          <!-- create campaign -->
          <scalable-modal :disable-control="true" @callback="confirmCreate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-create" @close="newCampaignForm=false" :open="newCampaignForm"  :advancedControl="true" validationButtonText="Nouvelle campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptions"
                    :backButton="prevButtonOptions"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardCompleted"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>{{$t('names')}}</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="newCampaignName">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="newCampaignTitle">
                                          <option  selected disabled>Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group">{{ group.title }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignType" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="newCampaignSmsSenderName">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="newCampaignEmailSenderName">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="newCampaignEmailSignature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="newCampaignSmsSignature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="newCampaignGroupe">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="newCampaignService">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="newCampaignDateStart" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="newCampaigndefaultmsg">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
          <scalable-modal :disable-control="true" @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-update" @close="newcampaignUpdate=false" :open="newcampaignUpdate"  :advancedControl="true" validationButtonText="Nouvelle campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptionss"
                    :backButton="prevButtonOptionss"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardUpdated"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="campaignByid.name">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.micro_learning_id">
                                          <option  selected disabled>Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group">{{ group.title }}</option>
                                      </select>
                                  </div>
                                  
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="campaignByid.campaign_type" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="campaignByid.sms_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignType == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="campaignByid.sms_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.group">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.service">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="campaignByid.planification_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="campaignByid.default_message">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
        </template>
      </c-f-a-nav>
      <c-f-a-footer></c-f-a-footer>
    </div>
  
  </template>
  
  <script>
  
  import CFANav from '@/components/nav/CFANav.vue'
//   import Table from "@/components/shared/table/Table.vue";
  import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
  import CFAFooter from '@/components/nav/CFAFooter.vue';
  import 'form-wizard-vue3/dist/form-wizard-vue3.css'
  import Wizard from 'form-wizard-vue3'
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import CourseCard from '@/components/shared/course/CourseCard3.vue'
  import {getAPI} from "@/axios-api";
  import { addHours } from 'date-fns';
  // import SsrCarousel from 'vue-ssr-carousel'
  // import ssrCarouselCss from 'vue-ssr-carousel/index.css'
  import utils from "@/composable/utils"

  
  export default {
      name: 'PhishingView',
      components: {
          ScalableModal,
          CFAFooter,
          CourseCard,
          CFANav,
          Wizard,
          VueDatePicker,
          // SsrCarousel,
          // ssrCarouselCss,
      },
      data () {
          return {
              minDate: new Date(),
              minDateEnd: new Date(),
              waitingAPIResponse: true,
              waitingAPIResponse2: true,
              serviceAllData:[],
              newCampaignTitle:'',
              newcampaignUpdate:false,
              newCampaignSmsSenderName:'',
              newCampaignEmailSenderName:'',
              isConnected: true,
              alertString: null,
              currentTab: localStorage.tab_phish_active||'campaign_activate',
              newCampaigndefaultmsg:'',
              campaignsAll: [],
              campaignsAllOk: [],
              campaignsAllColumns: ['n°', 'Nom', 'Campaign Type', 'Date création', 'Actions'],
              campaignsAllToListen: ['Actions'],
              campaignByid:[],                
              groupAll: [],
              groupAllColumns: ['n°', 'Nom', 'Utilisateurs', 'Denière modification', 'Actions'],
              groupAllToListen: ['Actions'],
              microlearning:[],
              allModules: [],
              reportsAll: [],
              reportsAllColumns: ['n°', 'Campagne', 'Utilisateurs', 'Actions'],
              reportsAllToListen: ['Actions'],
              newCampaignEmailSignature:'',
              userGroupAll: [],
              userGroupAllColumns: ['Nom', 'Prénoms', 'Email', 'Position', 'Actions'],
              userGroupAllToListen: ['Actions'],
              newCampaignSmsSignature:'',
              openDeletionBox: false,
              tempDeleteID: null,
              tempDeleteName: null,
              updateGroupPayload: null,
              openUpdateGroup: false,
              name_check: null,
              callbackPayload: null,
              exitOrgModalDel: null,
              exitOrgUpdateModal: null,
              newFirstname: null,
              newLastname: null,
              newEmail: null,
              newPosition: null,
              newCampaignService:'',
              openDeletionCampaignBox: false,
              newCampaignForm: false,
  
              newCampaignName: null,
              newCampaignType: "default",
              newCampaignDateStart: null,
              newCampaignDateEnd: null,
              newCampaignGroupe: null,
              newCampaignEmailTemplate: null,
              newCampaignLandingTemplate: null,
              newCampaignTarget:'',
              currentTabIndex: 0,
              userList: [],
              selectedUser: [],
              credit_mail: '•••',
              credit_sms: '•••',
              total_credit_mail: '•••',
              total_credit_sms: '•••',
              openNewGroup: false,
              exitNewGroupModal: null,
              updateGroupData: {},
              updateGroupStatusRequest: true,
              flattenedIds: [],
              exitGroupUpdateModal: null,
              groupAllData: [],
              totalModule:0,
              groupeApiWaiting: false,
              groupApiWaitingData: [],
              groupeApiTemplateWaiting: false,
              groupApiTemplateWaitingData: [],
              defaultImage: " /images/defaultImage.png",
              waitingAPITemplate: true,
              waitingAPITemplate2: true,
  
              templateData: [],
              currentTemplateIndex: 0,
              currentTemplate: null,
  
              templateData1: [],
              currentTemplateIndex1: 0,
              currentTemplate1: null,
  
              templateChoosed: "",
              pageChoosed: "",
              privacyCheck: null,
              campaignWaiting: [],
              seconds:'',
              subscriptionData: [],
          }
      },
      methods: {
        timeToSeconds(timeString) {
            // Split the time string into hours, minutes, and seconds
            const [hours, minutes, seconds] = timeString.split(':').map(Number);

            // Convert hours, minutes, and seconds to total seconds
            const second = (hours * 3600) + (minutes * 60) + seconds;

            return second;
        },
        async retrieveUserSubscription () {
            this.waitingAPIResponse = true
            await getAPI.get(`subscriptions/find-subscription`).then(response => {
                console.log(response)
            if(response.status == 200) {
                this.subscriptionData = response.data.data

                if(this.subscriptionData != null){
                console.log("Infos: ", this.subscriptionData)
                }
                
            }
            else {
                console.log("Subslength: ", this.subscriptionData.length)
                //let msg = 'Oups ! something went wrong.'
                //this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
                this.waitingAPIResponse = false
            }, 2000);
            }).catch(error => {
                if (error.response) {
                    //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
                }
                else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                }
                else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                }
                //this.waitingAPIResponse = false
            })
        },
          
          updatePrivacyCheck() {
              this.privacyCheck = !this.privacyCheck;
              
              console.log("State: ", this.privacyCheck);
              console.log("Coosed: ", this.templateChoosed);
              //setTimeout(() => {
               //   ""
              //}, 0);
          },
          selectAll() {
              this.selectedUser = this.userList.map(user => user.id);
          },
          selectTab (section) {
              localStorage.setItem('tab_phish_active', section)
              this.currentTab = section
          },
          convertDate(dateString, dateOnly=false) {
              const date = new Date(dateString);
              const formattedDate = date.toLocaleDateString();
              const formattedTime = date.toLocaleTimeString();
              if(!dateOnly) {
                  return `<b>${formattedDate} à ${formattedTime}</b>`;
              }
              return `<b>${formattedDate}</b>`;
          },
          async getAllModule () {
        this.waitingAPIResponse = true
        await getAPI.get('micro_learning/courses/')
        .then(response => {
          if(response.status == 200) {
            console.log(response.data)
            let modulesData = response.data
            this.allModules = modulesData.results
            this.totalModule = modulesData.count
            //this.timeToSeconds(this.allModules.duration);
            // TODO: make this dynamic
            this.totalInProgress = 1
          }
          else {
            let msg = response?.data?.message || "Une erreur s'est produite"
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
            console.log(error)
            
          if (error.response?.status == 403) {
            this.alertString = `vous n'avez pas la permission d'effectuer cette action |alert-no|10000::`+Date.now()
            return
          }  
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong. 1'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse = false
        })
      },
          async getAllGroup () {
              this.waitingAPIResponse = true
              await getAPI.get(`groupes`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.data;
                          this.groupAllData = response.data.data;
                          this.groupAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let users_count = `
                                  <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                              `;
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.groupAll.push({'n°': (i+1), 'Nom': `<span class="selectable" style="color: #132B4D">${row.name}</span>`, 'Utilisateurs':users_count, 'Denière modification':this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id})
                          }
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                    console.log(error)
                    if (error.response?.status == 403) {
                        this.alertString = `vous n'avez pas la permission d'effectuer cette action |alert-no|10000::`+Date.now()
                        return
                    }
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                          if (error.response?.data) {
                            this.alertString = `${utils.toStringOrDefault(error.response?.data)}`+Date.now()
                          }
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          async getAllService () {
              this.waitingAPIResponse = true
              await getAPI.get(`services`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.serviceAllData = response.data.data;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                          if (error.response?.data) {
                            this.alertString = `${utils.toStringOrDefault(error.response?.data)}`+Date.now()
                          }
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          async getAllMicroLearning () {
              this.waitingAPIResponse = true
              await getAPI.get(`micro_learning/courses/`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.microlearning = response.data.results;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                          if (error.response?.data) {
                            this.alertString = `${utils.toStringOrDefault(error.response?.data)}`+Date.now()
                          }
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
          },
          async getMembers () {
              this.waitingAPIResponse2 = true
              let org = localStorage.getItem('organisation')
             await getAPI.get(`organisation/${org}/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.userList = response.data.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                          if (error.response?.data) {
                            this.alertString = `${utils.toStringOrDefault(error.response?.data)}`+Date.now()
                          }
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse2 = false
                  })
          },
          templateSelect () {
              this.templateChoosed = this.currentTemplate.id;
              console.log("Template choisi: ", this.templateChoosed);
          },
          pageSelect () {
              this.pageChoosed = this.currentTemplate1.id;
              console.log("Page choisi: ", this.pageChoosed);
          },
          getTemplate () {
              this.waitingAPITemplate2 = true
              getAPI.get(`phishing/templates/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData = response.data.data;
                          this.currentTemplate = this.templateData[this.currentTemplateIndex];
                          this.templateChoosed = this.currentTemplate.id;
                          console.log("Etat: ", this.temSelected)
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate2 = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Aucun template disponible |alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate2 = false
                  })
          },
          getLanding () {
              this.waitingAPITemplate = true
              getAPI.get(`phishing/landing-page/`)
                  .then(response => {
                      if(response.status == 200) {
                          this.templateData1 = response.data.data;
                          this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
                          console.log(this.currentTemplate1)
                          this.pageChoosed = this.currentTemplate1.id;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPITemplate = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPITemplate = false
                  })
          },
          updateGroup (pos) {
              this.updateGroupPayload = pos
              this.openNewGroup = true
          },
          newGroup () {
              this.userGroupAll = []
              this.updateGroup({name: ''});
              this.getMembers();
          },
          confirmNewGroup () {
              console.log(this.selectedUser)
              console.log(this.updateGroupPayload.name)
              if(this.updateGroupPayload.name != '' && this.selectedUser.length > 0) {
                  getAPI.post(`groupes/`, {
                      name: this.updateGroupPayload.name,
                      organisation: localStorage.organisation,
                      users: this.selectedUser
                  })
                  .then(response => {
                      if(response.status == 201) {
                          this.getAllGroup()
                          //let msg = 'Groupe créé avec succès!'
                          //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          this.exitNewGroupModal = Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitNewGroupModal = Date.now()
                  })
                  .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now()
                      })
              }
              else {
                  this.exitNewGroupModal = Date.now()
                  //let msg = 'Oups ! Veuillez remplir le champ convenablement.'
                  //this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
          },
          
         
          
          getAllCampaign () {
              this.waitingAPIResponse = true
              getAPI.get(`micro_learning/campaigns/`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.results;
                          this.campaignsAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.campaignsAll.push({
                                  'n°': (i+1),
                                  
                                  'Nom': `<span class="selectable">${row.name}</span>`,
                                  'Campaign Type':`<span class="selectable">${row.campaign_type}</span>`,
                                  'Statut':`${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span>` : `<span class="badge rounded-pill badge-success cfa-bg-danger mw-status">IN PROGRESS</span>`)}`,
                                  'Date création': this.convertDate(row.created_at, true),
                                  'Actions': btn,
                                  name: row.name,
                                  id: row.id
                              });
                          }
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                    console.log(error)
                      if (error.response) {
                          this.alertString = `Cette fonctionnalite n'est pas disponible sur votre formule|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong. 2'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
             
          },
          newCampaign () {
              this.newCampaignForm=true;
          },
          prevTemplate() {
              if (this.currentTemplateIndex > 0) {
                  this.currentTemplateIndex--;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          nextTemplate() {
              if (this.currentTemplateIndex < this.templateData1 - 1) {
                  this.currentTemplateIndex++;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          prevLanding() {
              if (this.currentTemplateIndex1 > 0) {
                  this.currentTemplateIndex1--;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
          nextLanding() {
              if (this.currentTemplateIndex1 < this.templateData1.length - 1) {
                  this.currentTemplateIndex1++;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
  
          getCallToActionForCampaigns (payload) {
            if(payload.action_type == 'delete') {
                this.openDeletionCampaignBox = true;
                this.tempDeleteID = payload.id;
                this.tempDeleteName = payload.name;
            
            }
            if(payload.action_type == 'view') {
               this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                        this.campaignByid = response.data;
                         
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.waitingAPIResponse = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.waitingAPIResponse = false
                  })
            }
          },
          
          confirmDeletion2 () {
            if(this.name_check.toUpperCase() != this.tempDeleteName.toUpperCase()) {
                this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
                this.openDeletionCampaignBox = false
            }
            else{
                 getAPI.delete(`micro_learning/campaigns/${this.tempDeleteID}/`)
                    .then(response => {
                        if(response.status == 204) {
                            this.callbackPayload = 'Csmpaign supprimé avec succès' + '::' + Date.now();
                            setTimeout(() => {
                                this.getAllCampaign()
                            }, 1500)
                            this.openDeletionCampaignBox = false
                        }
                        else {
                            let msg = 'Oups ! something went wrong.'
                            this.alertString = `${msg}|alert-no|5000::`+Date.now()
                            this.exitOrgModalDel = Date.now()
                            this.openDeletionCampaignBox = false
                        }
                    })
                    .catch(() => {
                        //this.exitOrgModalDel = Date.now()
                        this.openDeletionCampaignBox = false
                        this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
                    })
            }
            this.openDeletionCampaignBox = false
          },
          
  
          // for stepper logic
          onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
          wizardCompleted() {
              
                  const groups = [];
                  const service = [];
                //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
                //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
                  service.push(this.newCampaignService)
                  groups.push(this.newCampaignGroupe);
                  console.log(groups);
                  let newCampaignDateStartF = new Date(this.newCampaignDateStart);
                  newCampaignDateStartF = addHours(this.newCampaignDateStart, 3);
                  
                  getAPI.post(`micro_learning/campaigns/`, {
                      name: this.newCampaignName,
                      micro_learning_id: this.newCampaignTitle,
                      campaign_type: this.newCampaignType,
                      sms_send_name: this.newCampaignSmsSenderName || null,
                      group: this.newCampaignGroupe || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                      email_send_name: this.newCampaignEmailSenderName || null,
                      sms_send_signature: this.newCampaignSmsSignature || null,
                      email_send_signature: this.newCampaignEmailSignature || null,
                      planification_date: newCampaignDateStartF,
                      default_message:this.newCampaigndefaultmsg,
                      is_validated_by_admin: false,
                      service:this.newCampaignService || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                      status:"unavailable"
                      
                  })
                      .then(response => {
                          if(response.status === 201) {
                              this.getAllGroup()
                              this.getAllCampaign()
                              //let msg = 'Campagne crééé avec succès!'
                              this.campaignWaiting = response.data;
                              console.log("Camp: ", this.campaignWaiting)
                              //this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                          }
                          else {
                              
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                          this.$swal({
                              title: 'Campagne créée avec succès',
                              text: 'Campagne créée avec succès. En attente de validation.',
                              type: 'warning',
                              showCancelButton: false,
                              confirmButtonText: "D'accord !"
                          });
                      })
                      .catch(error => {
                          if (error.response) {
                              //this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                          }
                          else if (error.request) {
                              //let msg = 'The request was made but no response was received. Please check your network.'
                              //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                          }
                          else {
                              //let msg = 'Oups ! something went wrong.'
                              //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                          }
                          this.exitOrgUpdateModal = Date.now();
                          this.newCampaignForm = false;
                      });
              
              
          },
          wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              let newCampaignDateStartF = new Date(this.campaignByid.planification_date);
              newCampaignDateStartF = addHours(this.campaignByid.planification_date, 3);
              
              getAPI.put(`micro_learning/campaigns/${this.tempDeleteID}/`, {
                  name: this.campaignByid.name,
                  micro_learning_id: this.campaignByid.micro_learning_id,
                  campaign_type: this.campaignByid.campaign_type,
                  sms_send_name: this.campaignByid.sms_send_name || null,
                  group: this.campaignByid.group || "3421b42e-55f8-46fb-ae95-32c3a48bc19e",
                  email_send_name: this.campaignByid.email_send_name || null,
                  sms_send_signature: this.campaignByid.sms_send_signature || null,
                  email_send_signature: this.campaignByid.email_send_signature || null,
                  planification_date: newCampaignDateStartF,
                  default_message:this.campaignByid.default_message,
                  is_validated_by_admin: false,
                  service:this.campaignByid.service || "93c1afcb-5838-4ae3-905c-80e6196f4204",
                  status:"unavailable"
                  
              })
                  .then(response => {
                      if(response.status === 200) {
                          this.getAllGroup()
                          this.getAllCampaign()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                      this.$swal({
                          title: 'Campagne créée avec succès',
                          text: 'Campagne créée avec succès. En attente de validation.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
      },
      async mounted () {
        await this.getAllModule ();
          //console.log("Templates: ", this.templateData)
        this.getAllGroup();
        this.getAllService ();
        //this.getAllCampaign();
        await this.retrieveUserSubscription()
        this.getAllMicroLearning ();
      },
      computed: {
          nextButtonOptions() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.newCampaignType === 'default' || this.newCampaignName == null  )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          nextButtonOptionss() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.campaignByid.name == null || this.campaignByid.campaign_type === 'default' )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          prevButtonOptions() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignTarget == 'default') ? {
                  disabled: true,
                  text: "Terminé"
              }: {
                  disabled: false,
                  text: "Terminé"
              };
          },
      },
      watch: {
          newCampaignGroupe: function (value) {
              this.groupeApiWaiting = true;
              getAPI.get(`groupes/${value}`)
                  .then(response => {
                      if(response.status == 200) {
                          this.groupApiWaitingData = response.data;
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      setTimeout(() => {
                          this.groupeApiWaiting = false
                      }, 2000);
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.groupeApiWaiting = false
                  })
          },
        //   currentTabIndex: function (value) {
        //       if(value === 2) {
        //           if(this.templateData.length === 0) {
        //               this.getTemplate();
        //               console.log("Tab templates: ", this.templateData)
        //           }
        //       }
        //       if(value === 4) {
        //           if(this.templateData1.length === 0) {
        //               this.getLanding();
        //           }
        //       }
        //   },
      }
  }
  </script>
  
  <style scoped>
  .cfa-page{}
  .cfa-block{
      background-color: #fff;
      border-radius: 0.5em;
      padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      /* border: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  }
  
  /* TAB STYLE */
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    /*border-bottom: 1px solid #ccc;*/
  }
  
  .tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  
  .tab-header ul li.active {
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-header ul li:hover{
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-body {
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
  }
  .gFolder-container{
      background-color: var(--primary-light-color-2);
      border-radius: 0.5em;
      height: 4.3em;
      width: 4.6em;
      margin-right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .gBlock{
      align-items: center;
      justify-content: start;
      border-radius: 0.6em;
      padding-left: 2em;
      padding-right: 2em;
      height: 8em !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .gBlock div:last-of-type p{
      margin: 0;
  }
  .gBlock div:last-of-type p:first-of-type{
      font-weight: 400;
      font-size: 2em;
  }
  .tab_font{
      font-size: 13px;
  }
  .user-row{
      background: #fff;
      padding-left: 1em;
      margin-bottom: 0.2em;
  }
  .row-user{
      transition: .2s all ease-in;
  }
  .row-user:hover{
      background: #f5f5f5;
      cursor: pointer;
  }
  
  .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .left-button,
  .right-button {
      padding: 10px;
      cursor: pointer;
      background-color: lightgray;
  }
  
  .middle-div {
      flex: 1;
      margin: 0 10px;
  }
  
  .content {
      width: 100%;
      height: 461px;
      border: 1px solid #e0e1e8;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
  }
  .cfa-section-title{
      color: #fff;
  }
  .cfa-btn-primary{
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  </style>