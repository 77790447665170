
export default {
    formatDateToLocal(dateTime, locale = 'default') {
        const date = new Date(dateTime);
        return date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
    toStringOrDefault(value, defaultText = "N/A") {
        if (value === null || value === undefined) {
            return defaultText;
        }
        return String(value);
    },
    delObjectProps (obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
                delete obj[key];
            }
        });
    }
}